// import { type } from "jquery";
import * as types from "./taskTypes";




let taskobj = {
    tasklist: [],
    addtask: false,
    taskdetails: false,
    updatetask: false,
    taskid: '',
    singletask: {
        _id: "",
        projectid: "",
        projectname:"",
        taskname: "",
        taskdescription: "",
        taskdetails: "",
        taskdeadline: Date,
        asignedto: [],
        asignedby: [],
        files: [],
        comments: [],
        status: ""
    },
    comment: {
        comment: "",
        commentby: "",
        commentdate: "",
        files: []
    },
    singleusertasktimer : []

};


export default function taskReducer(state = taskobj, { type, payload }) {

    switch (type) {
        case types.ADD_SINGLE_TASK:
            taskobj.singletask._id = payload._id;
            taskobj.singletask.taskname = payload.taskname;
            taskobj.singletask.taskdescription = payload.taskdescription;
            taskobj.singletask.taskdetails = payload.taskdetails;
            taskobj.singletask.taskdeadline = payload.taskdeadline;
            taskobj.singletask.asignedto = payload.asignedto;
            taskobj.singletask.asignedby = payload.asignedby;
            taskobj.singletask.projectid = payload.projectid;
            taskobj.singletask.projectname = payload.projectname;
            taskobj.singletask.files = payload.files;
            taskobj.singletask.status = payload.status;
            taskobj.singletask.comments = payload.comments;



            return {
                ...state,
                loaded: true,
                loading: false,
                singletask: taskobj.singletask,
            }
        case types.ADD_TASK_LIST_SUCCESS:
            
            return {
                ...state,
                loaded: true,
                loading: false,
                tasklist: payload,
            }
        case types.GET_TASK_DETAILS_SUCCESS:
            return {
                ...state,
                loaded: true,
                loading: false,
                taskdetails: payload,
            }
        case types.GET_SINGLE_TASK_ID:
            return {
                ...state,
                loaded: true,
                loading: false,
                taskid: payload,
            }

        case types.UPDATE_SINGLE_TASK:
            return {
                ...state,
                loaded: true,
                loading: false,
                updatetask: payload,
            }

        case types.ADD_COMMENT_SUCCESS:
            taskobj.comment.comment = payload.comment;
            taskobj.comment.commentby = payload.commentby;
            taskobj.comment.commentdate = payload.commentdate;
            taskobj.comment.files = payload.files;
            return {
                ...state,
                loaded: true,
                loading: false,
                comment: taskobj.comment,
            }
            case types.ADD_STATUS_SUCCESS:
              
                return {
                    ...state,
                    loaded: true,
                    loading: false,
                    status: payload,
                }
                case types.GET_SINGLE_USER_TASK_TIMER_DETAILS_SUCCESS:
              
                return {
                    ...state,
                    loaded: true,
                    loading: false,
                    singleusertasktimer: payload,
                }
        default:
            return state;
    }

}

