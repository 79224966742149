import * as types from "./accessTypes";

let accessobj = {
  accesslist: [],
  addaccess: false,
  accessdetails: false,
  searchaccess: false,
  searchaccesslist: [],
  singleaccess: {
    grouptype: [],
    users: [],
    screens : [],
    groups: []

  },
  singleaccessdetails: [],
  Is_created : false,
  useraccess : [],
  usersidebaraccess : []


};



export default function accessReducer(state = accessobj, { type, payload }) {
  switch (type) {


    case types.ADD_SINGLE_ACCESS:
      accessobj.singleaccess.grouptype = payload.grouptype;
      accessobj.singleaccess.users = payload.users;
      accessobj.singleaccess.screens = payload.screens;
      accessobj.singleaccess.groups = payload.groups;


      return {
        ...state,
        loaded: true,
        loading: false,
        singleaccess: accessobj.singleaccess,
      };


    case types.GET_ACCESS_LIST_SUCCESS:
      
      return {
        ...state,
        loaded: true,
        loading: false,
        accesslist: payload,
      }

    case types.VIEW_ACCESS_ID_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        selectedaccessid: payload,
      }
    case types.IS_ACCESS_CREATE_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        Is_created: payload,
      }

    case types.ADD_ACCESS_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        addaccess: payload,
      }

    case types.GET_ACCESS_DETAILS_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        accessdetails: payload,
      }

    case types.GET_SINGLE_ACCESS_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        singleaccessdetails: payload,
      }

    case types.SEARCH_ACCESS_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        searchaccess: payload,
      }
    case types.SEARCH_ACCESS_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        searchaccesslist: payload,
      }
      case types.USER_ACCESS_GET_SUCCESS:
        
      return {
        ...state,
        loaded: true,
        loading: false,
        useraccess: payload,
      }

      case types.USER_SIDEBAR_ACCESS_GET_SUCCESS:
        
      return {
        ...state,
        loaded: true,
        loading: false,
        usersidebaraccess: payload,
      }





    default:
      return state;
  }

}
