export const GET_GROUP_LIST_SUCCESS = 'GET_GROUP_LIST_SUCCESS';
export const CREATE_GROUP_LIST_SUCCESS = 'CREATE_GROUP_LIST_SUCCESS';
export const ADD_GROUP_LIST_SUCCESS = 'ADD_GROUP_LIST_SUCCESS';
export const GET_GROUP_DETAILS_SUCCESS = 'GET_GROUP_DETAILS_SUCCESS';
export const ADD_SINGLE_GROUP = 'ADD_SINGLE_GROUP';
export const GET_SINGLE_GROUP_SUCCESS = 'GET_SINGLE_GROUP_SUCCESS';
export const VIEW_GROUP_ID_SUCCESS = 'VIEW_GROUP_ID_SUCCESS'; 
export const SEARCH_GROUP_SUCCESS = 'SEARCH_GROUP_SUCCESS';
export const SEARCH_GROUP_LIST_SUCCESS = 'SEARCH_GROUP_LIST_SUCCESS'; 
export const IS_GROUP_CREATE_SUCCESS = 'IS_GROUP_CREATE_SUCCESS'; 

