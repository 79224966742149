
/* End Here */
import { SideBar } from "./sidebar/index";
import { ProjectList } from "./projectlist/index";
import { GroupList } from "./grouplist/index";
import { AccessList } from "./accesslist/index";
import { Createproject } from "./createproject/index";
import { Createtask } from "./createtask/index";
import {Updatetask} from "./updatetask/index"
import { Projectdetails } from "./projectdetails/index";
import { Groupdetails } from "./groupdetails/index";
import { Accessdetails } from "./accessdetails/index";
import { Createaccess } from "./createaccess/index";
import { Creategroups } from "./creategroups/index";
import { DropdownMenu } from "./dropdown/index";
import { WebSocketComponent } from "./websocket/index"
import { CommonNavItems } from "./commonsidebar/index";
import { AdminNavItems } from "./Adminsidebar/index";



export {
    DropdownMenu,
    SideBar,
    ProjectList,
    Createproject,
    Projectdetails,
    Createtask,
    Updatetask,
    Createaccess,
    Creategroups,
    GroupList,
    AccessList,
    Groupdetails,
    Accessdetails,
    WebSocketComponent,
    CommonNavItems,
    AdminNavItems,
    

    
}