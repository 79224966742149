import React from 'react';

const AdminNavItems = () => {
    return (
        <>
            <div className="flex before:border">
                <a href={"/projects"} className="dashboard-nav-item">
                    <img src="/images/sidebar/project.svg" alt="" /> Projects
                </a>
            </div>
            <div className="flex before:border">
                <a href={"https://admin.ticket.wrapup.live/"} className="dashboard-nav-item">
                    <img src="/images/sidebar/book.svg" alt="" /> Tickets Admin
                </a>
            </div>
            <div className="flex before:border">
                <a href={"https://admin.salary.wrapup.live/"} className="dashboard-nav-item">
                    <img src="/images/sidebar/dashboard.svg" alt="" /> Salary Admin
                </a>
            </div>
            <div className="flex hover:before:border">
                <a href={"https://admin.lms.wrapup.live/"} className="dashboard-nav-item">
                    <img src="/images/sidebar/dashboard.svg" alt="" /> Lms Admin
                </a>
            </div>
            <div className="flex hover:before:border">
                <a href={"https://admin.leave.wrapup.live/"} className="dashboard-nav-item">
                    <img src="/images/sidebar/dashboard.svg" alt="" /> Leave Admin
                </a>
            </div>
            <div className="flex hover:before:border">
                <a href="https://invoice.wrapup.live/" className="dashboard-nav-item">
                    <img src="/images/sidebar/generate-invoice.svg" alt="" /> Generate Invoice
                </a>
            </div>
            <div className="flex before:border">
                <a href="https://invoice.wrapup.live/invoice-list" className="dashboard-nav-item">
                    <img src="/images/sidebar/generate-invoice.svg" alt="" /> Invoice List
                </a>
            </div>
            <div className="flex hover:before:border">
                <a href={"/users"} className="dashboard-nav-item">
                    <img src="/images/sidebar/users.svg" alt="" /> Users
                </a>
            </div>
            <div className="flex hover:before:border">
                <a href={"/groups"} className="dashboard-nav-item">
                    <img src="/images/sidebar/groups.svg" alt="" /> Groups
                </a>
            </div>
        </>
    );
}

export { AdminNavItems };
