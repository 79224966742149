
import { Login } from "./Login";
import { Signup } from "./Signup";
import { Projects } from "./projects";
import { Users } from "./Users";
import { Managers } from "./Managers";
import { Tasks } from "./Tasks";
import { Settings } from "./Settings";
import { Forgotpassword } from "./Forgotpassward";
import { Newpassward } from "./Newpassward";
import { Verification } from "./Verification";
import { Notification } from "./Notification";
import { Timer } from "./Timer";
import { Access } from "./Access";
import { Group } from "./Groups";
import { Onboarding1, Onboarding2, Onboarding3, Onboarding4, Onboarding5 } from "./Onboarding";


export {
    Login,
    Signup,
    Projects,
    Users,
    Managers,
    Tasks,
    Settings,
    Forgotpassword,
    Newpassward,
    Verification,
    Notification,
    Timer,
    Access,
    Group,
    Onboarding1,
    Onboarding2,
    Onboarding3,
    Onboarding4,
    Onboarding5
};