import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
// redux store and Redux Persist
import { composeWithDevTools } from "@redux-devtools/extension";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import {thunk} from "redux-thunk";
import reducers from "./store/reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Import the storage engine

import WebFont from 'webfontloader';
import App from "./App";

const middleware = [thunk];

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middleware))
);

WebFont.load({
  google: {
    families: ['Titillium Web:300,400,700', 'sans-serif', 'Pacifico', 'cursive']
  }
});

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
