import axios from "axios"
import { wrapupApi } from '../../config';
import {
  get_task_list_success,
  get_single_user_task_timer_details_success


} from "./taskActions";


export const create_task =
  (Token, payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/task/add-task`, payload, config)
      .then(res => {

        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

export const create_event =
  (Token, payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/calender/createevent`, payload, config)
      .then(res => {

        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };


export const update_task =
  (Token, payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/task/update-task`, payload, config)
      .then(res => {
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };
export const get_task_list_pagination =
  (Token, payload, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/task/get/paginate/task`, payload, config)
      .then(res => {
        const value = res.data
        dispatch(get_task_list_success(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

export const get_single_task =
  (Token, payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/task/get/task-details`, payload, config)
      .then(res => {

        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

// export const add_comment_for_task =
//   (Token, payload, callback) => async (dispatch) => {
//     const config = {
//       headers: { Authorization: `${Token}` }
//     };
//     axios.post(`${wrapupApi}/api/task/get/add/task/comment`, payload, config)
//       .then(res => {
//         callback({ success: true, data: res.data });
//       })
//       .catch(err => {
//         console.log(err);
//       })
//   };


export const add_status_for_task =
  (Token, payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/task/get/add/task/status`, payload, config)
      .then(res => {
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };
export const add_time_for_task =
  (Token, payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/tasktimer/add-task-timer`, payload, config)
      .then(res => {
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

export const get_single_userwise_time_for_task =
  (Token, payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/tasktimer/get-task-timer`, payload, config)
      .then(res => {
        const value = res.data
        dispatch(get_single_user_task_timer_details_success(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };
  export const delete_time_for_task =
  (Token, payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/tasktimer/delete-task-timer`, payload, config)
      .then(res => {
        const value = res.data
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };
