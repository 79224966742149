import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { get_single_userwise_time_for_task, add_time_for_task, delete_time_for_task } from "store/task/api";
import { SideBar, WebSocketComponent } from 'components';
import { uploadfile, get_user_profile } from "store/account/api";
import { get_single_user_task_timer_details_success } from "../../store/task/taskActions"
import { get_project_details_success } from "../../store/projects/projectActions"
import { Admin, Manager, Task_Full, Task_Read, User } from '../../config';
import Swal from 'sweetalert2'


const Timer = () => {
    const [time, setTime] = useState(0)
    const [start, setStart] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    let query = useQuery()
    const Token = localStorage.getItem("Token")
    const state_account = useSelector(state => state.account);
    const state_project = useSelector(state => state.project);
    const state_task = useSelector(state => state.task);
    const tasktimerdata = state_task.singleusertasktimer;
    const UserEmail = localStorage.getItem("UserEmail")

    const Getdata = localStorage.getItem("Access")

    const [sec, setSec] = useState(localStorage.getItem("finalSec") !== null ? parseInt(localStorage.getItem("finalSec")) : 0);
    const [min, setMin] = useState(localStorage.getItem("finalMin") !== null ? parseInt(localStorage.getItem("finalMin")) : 0);
    const [hr, setHr] = useState(localStorage.getItem("finalHr") !== null ? parseInt(localStorage.getItem("finalHr")) : 0)
    let TodayWeekday = new Date().toLocaleDateString('en-US', { weekday: 'long' })
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.toLocaleDateString('en-US', { month: 'short' });
    const year = currentDate.getFullYear();
    const formattedDate = `${day} ${month} ${year}`;

    useEffect(() => {
        
            if (Token != null) {
                dispatch(get_user_profile(Token))
            }
        dispatch(get_project_details_success(true))
        const payload = {
            email: UserEmail,
            taskid: id,
            token: Token
        }
        dispatch(get_single_userwise_time_for_task(Token, payload))
    }, [])

    const startTimer = () => { }
    const stopTimer = () => { }
    let countTimer;

    useEffect(() => {
        if (start) {
            countTimer = setInterval(() => {
                setSec(sec + 1)
                if (sec === 59) {
                    setSec(0)
                    setMin(min + 1);
                }
                else if (sec === 0 && min === 60) {
                    setSec(1)
                    setMin(0)
                    setHr(hr + 1)
                }
            }, 1000)
            return () => clearInterval(countTimer)
        }
    })

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let project_name = query.get("project_name")
    let task_name = query.get("task_name")
    let id = query.get("id");
    let project_id = query.get("project")

    console.log(tasktimerdata)
    async function savetimefortask() {
        Swal.fire({
            title: 'Do you want to stop timer and save?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let startdate = new Date();
                startdate.setHours(startdate.getHours() - hr);
                startdate.setMinutes(startdate.getMinutes() - min);
                startdate.setSeconds(startdate.getSeconds() - sec);
                let enddate = new Date()
                const payload = {
                    projectname: project_name,
                    projectid: project_id,
                    user: UserEmail,
                    taskid: id,
                    taskname: task_name,
                    starttime: startdate,
                    endtime: enddate,
                    totaltime: enddate - startdate
                }
                dispatch(add_time_for_task(Token, payload,))
                Swal.fire({
                    title: 'Task data have been saved successfully!',
                    confirmButtonText: 'Ok',
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {

                        const payload = {
                            email: UserEmail,
                            taskid: id
                        }
                        dispatch(get_single_userwise_time_for_task(Token, payload))
                        setTime(0)
                        localStorage.setItem("finalSec", 0)
                        localStorage.setItem("finalMin", 0)
                        localStorage.setItem("finalHr", 0)
                        navigate("/projects")
                        // window.reload()
                    }
                })
            }
        })

    }
    if (sec !== 0 || min !== 0 || hr !== 0) {
        localStorage.setItem("finalSec", sec)
        localStorage.setItem("finalMin", min)
        localStorage.setItem("finalHr", hr)
    }


    console.log(tasktimerdata)
    function formatTime(time) {
        const date = new Date(time);
        const month = date.getUTCMonth() + 1;
        const day = date.getUTCDate();
        const year = date.getUTCFullYear();
        let hours = date.getUTCHours();
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const seconds = date.getUTCSeconds().toString().padStart(2, '0');
        const amOrPm = hours >= 12 ? 'PM' : 'AM';
        hours %= 12;
        hours = hours || 12;
        return `${month}/${day}/${year} ${hours}:${minutes}:${seconds} ${amOrPm}`;
    }

    function formatTotalTime(time) {
        const date = new Date(time);
        let hours = date.getUTCHours();
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const seconds = date.getUTCSeconds().toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    }



    async function handleDeleteTime(data) {

        Swal.fire({
            title: `Do you want to delete the time for "${data.taskname}"?`,
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const payload = {
                    taskid: data._id
                };
                dispatch(delete_time_for_task(Token, payload, (res) => {
                    if (res.success === true) {
                        Swal.fire({
                            position: 'middle',
                            icon: 'success',
                            title: 'Your time for this task has been successfully removed.',
                            showConfirmButton: false,
                            timer: 1500
                        });
                        const payload = {
                            email: UserEmail,
                            taskid: id
                        }
                        dispatch(get_single_userwise_time_for_task(Token, payload))
                        // const payload = {
                        //     email: UserEmail,
                        //     taskid: data.taskid
                        // }
                        // dispatch(get_single_userwise_time_for_task(Token, payload))
                        
                        // navigate("/projects")
                    }
                }));
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info');
            }
        });
    }
    return (

        <div>
            <div className='dashboard'>
                <div className="dashboard-nav">
                    <SideBar />
                    <WebSocketComponent/>
                </div>
                <div className='dashboard-app' id="main_section_toggle">
                    {/* <p className='dashboard-toolbar'><a href="#!" className="menu-toggle"><i style={{ "fontSize": "24px" }}
                        className="fa">&#xf0c9;</i></a></p> */}

                    <div className='dashboard-content'>
                        <div>
                            <div className="timmer_wrapp">
                                <h5>Today: {TodayWeekday} {formattedDate}</h5>
                                <div className="col-md-12" style={{ "padding": "0px" }}>
                                    <div className="project-manager-section-box">
                                        <div className="project-row">
                                            <div>
                                                <h5>{project_name}</h5>
                                                <p>Task name: <span>{task_name}</span></p>
                                            </div>

                                            <div className="project-row" style={{ "gap": "20px" }}>
                                                <h4>
                                                    {(hr < 10 ? "0" + hr : hr)}:
                                                    {(min < 10 ? "0" + min : min)}:
                                                    {(sec < 10 ? "0" + sec : sec)}
                                                </h4>
                                                {start == false && <button className='upload-own-image-button project-row'
                                                    onClick={() => { startTimer(setStart(true)) }}><img src="/images/icons/timer.svg" style={{ "marginRight": "5px" }} />Start</button>}

                                                {start == true && <button className='upload-own-image-button project-row' style={{"background":"#60626E"}}
                                                    onClick={() => { stopTimer(setStart(false)) }}><img src="/images/icons/pause.svg" style={{ "marginRight": "5px"}} />Pause</button>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="add-new">
                                        <button className='upload-own-image-button Submit_btn' onClick={() => savetimefortask()}>Submit</button>
                                    </div>
                                    {tasktimerdata.filter(data => data.taskid === id).length > 0 && <h6>Submitted times</h6>}
                                    {tasktimerdata.filter(data => data.taskid === id).map((data, index) => (
                                        <div className="project-manager-section-box" key={index}>
                                            <div className="project-row">
                                                <div>
                                                    <h3>{formatTime(data.starttime)}</h3>
                                                </div>
                                                <h4>{formatTotalTime(data.totaltime)}</h4>
                                                <div><img src="/images/icons/delete.svg" width={"20px"} className="cursor"  onClick={()=>handleDeleteTime(data)}/></div>
                                            </div>
                                        </div>

                                    ))}


                                    {/* <div className="add-new" style={{ "marginTop": "10px" }}>
                                        <h4><span>Total:</span>  18:36:00</h4>
                                    </div> */}

                                </div>





                            </div>



                            {/* <div className="row">
                                <div className="col-md-5">
                                    <div className="manager-wrap">
                                        <div className="manager-title-project">
                                            <p>Project Name: {project_name} </p>
                                            <p>Task Name: {task_name} </p>
                                            {/* <h6>Number of ongoing tasks:</h6> */}
                            {/* </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="padding-section">
                                <div className="app">
                                    <h1>Start timer before start task</h1>
                                    <h1>
                                        {(hr < 10 ? "0" + hr : hr)} :
                                        {(min < 10 ? "0" + min : min)} :
                                        {(sec < 10 ? "0" + sec : sec)}

                                    </h1>
                                    <div className='timeline-btn'>
                                        <button className='upload-own-image-button'
                                            onClick={() => { startTimer(setStart(true)) }}>Start</button>
                                        <button className='upload-own-image-button' onClick={() => { stopTimer(setStart(false)) }}>Pause</button>
                                    </div>
                                    {tasktimerdata.length > 0 && (
                                        <div className="full-page">
                                            <div className="project-Assigned-title">
                                                <h6>Work Time Progress:</h6>
                                            </div>
                                            <ul className="Assigned-list">
                                                {tasktimerdata.filter(data => data.taskid === id).map((data, index) => (
                                                    <li className="Assigned-item" key={index}>
                                                        <p>Starting time: {formatTime(data.starttime)}</p>
                                                        <p>Ending time: {formatTime(data.endtime)}</p>
                                                        <p>Total time: {formatTotalTime(data.totaltime)}</p>
                                                        <p>Delete time: <span className="delete-icon" onClick={() => handleDeleteTime(data)}>🗑️</span></p>
                                                    </li>
                                                ))}

                                            </ul>
                                        </div>
                                    )}





                                </div>
                            </div> */}

                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

export {
    Timer
}