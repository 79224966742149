import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { get_user_profile, get_users } from "../../store/account/api";
import { add_single_group, add_group_success, get_group_details_success, view_group_id, get_group_list_success, create_group_success } from "store/groups/groupActions"
import { create_group, update_group, get_group_details, get_group_list_pagination } from "../../store/groups/api"




const Creategroups = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const state_account = useSelector(state => state.account);
    const state_project = useSelector(state => state.project);
    const state_group = useSelector(state => state.group);
    const state_task = useSelector(state => state.task);
    const Token = localStorage.getItem("Token")
    const [showNotification, setShowNotification] = useState(false);
    const [showNotificationMessage, setShowNotificationMessage] = useState(false);
    const [creategroupsuccess, setcreategroupsuccess] = useState(false)
    const singlegroupdata = state_group.singlegroupdetails;
    // state_group.singlegroupdetails.groupadmins = [];
    const groupdata = state_group.Is_created;
    const singletaskdata = state_task.singletask;
    const [selectmail, setselectmail] = useState("null")
    const [cost, setCost] = useState("")
    const [selectemail, setselectEmail] = useState("")
    let mail = singlegroupdata.users
    const [email, setEmail] = useState([])
    const [file, setFile] = useState([])
    const [pageno, setpageno] = useState(0)
    let groups = [];
    const [count, setcount] = useState(20)
    const [currentStatus, setCurrentStatus] = useState("")
    const [name, setName] = useState("");
    const [openModal, setopenModal] = useState(false)


    const changeStatus = (newStatus) => {
        setCurrentStatus(newStatus)

    }



    useEffect(() => {

        if (Token != null) {
            dispatch(get_user_profile(Token, (res => {
                if (res.success == true) {
                    dispatch(get_users(Token))
                }
            })))
        }


    }, []);



    async function getgrouplistpagination(data) {
        const payload = {
            pageno: data,
            count: count,
            user: state_account.profile
        }
        dispatch(get_group_list_pagination(Token, payload, (res) => {
            if (res.success == true) {
                let value = res.data
                value.forEach(i => {
                    groups.push(i)
                    // setgroups(current => [...current, i])
                })
                get_group_list_success(groups)
            }
        }))
    }


    async function handleChangegroup(e) {
        // setShowNotification(false)
        setName(e.target.value);
        singlegroupdata.groupname = (e.target.value)
        dispatch(add_single_group(singlegroupdata))

    };

    async function creategroup() {
        if (groupdata == false) {
            Swal.fire({
                title: 'Do you want to create the group In Wrap-up?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    dispatch(add_single_group(singlegroupdata))
                    dispatch(create_group(Token, singlegroupdata, (res => {

                        if (res.success == true) {
                            // Swal.fire({
                            //     title: 'Do you want to create the group In Wrap-up?',
                            //     showCancelButton: true,
                            //     confirmButtonText: 'Yes',
                            // }).then((result) => {
                            //     /* Read more about isConfirmed, isDenied below */
                            //     if (result.isConfirmed) {
                            // Swal.fire('confirmed!', '', 'success')
                            Swal.fire({
                                position: 'middle',
                                icon: 'success',
                                title: 'Your group has been successfully created in Wrap-up',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            dispatch(get_group_details(Token))
                            // window.location.reload()
                            // getgrouplistpagination(pageno)
                            dispatch(add_group_success(false))
                            // dispatch(get_group_details_success(true))
                            dispatch(add_group_success(false))
                            dispatch(create_group_success(false))
                            // dispatch(view_group_id(singlegroupdata.group_id))
                            //     }
                            // })
                        }
                        else if(res.success==false) {
                            Swal.fire(`${res.data.response.data.message}`, '', 'info')
                            // console.log("Invalid response from API");
                        }
                    })))
                }
                else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
                }
            })
        } else {
            Swal.fire({
                title: 'Do you want to update the group data?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    singlegroupdata.groupid = state_group.singlegroupdetails._id
                    dispatch(add_single_group(singlegroupdata))


                    dispatch(update_group(Token, singlegroupdata, (res => {
                        if (res.success == true) {
                            // window.location.reload();
                            Swal.fire({
                                position: 'middle',
                                icon: 'success',
                                title: 'Your group details successfully updated in Wrap-up',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            // getgrouplistpagination(pageno)
                            dispatch(add_group_success(false))
                            // dispatch(get_group_details_success(true))
                            dispatch(get_group_details(Token))
                            dispatch(create_group_success(false))
                            // dispatch(view_group_id(singlegroupdata.group_id))
                        }
                        else if(res.success==false) {
                            Swal.fire(`${res.data.response.data.message}`, '', 'info')
                            // console.log("Invalid response from API");
                        }
                    })))
                }
                else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
                }
            })
        }

    }
    async function dropdownselectManager(e) {

        setselectmail(e.target.value)

    };
    async function managerSelection() {
        console.log(selectmail)
        if (selectmail != "null" && selectmail != "" && selectmail != null) {
            if (mail.includes(selectmail)) {

            } else {
                mail.push(selectmail)

            }
            console.log(mail)
            singlegroupdata.users = mail
            dispatch(add_single_group(singlegroupdata))
        }
    }

    async function deleteManager(e) {
        const m = mail.filter((data, index) => index !== e)
        mail = m
        // console.log(m);
        singlegroupdata.users = mail
        dispatch(add_single_group(singlegroupdata))

    }

    async function addGroupAdmin(e) {
        let Admin = singlegroupdata.users[e]
        let groupAdmin = singlegroupdata.groupadmins
        if (groupAdmin.includes(Admin)) {
            const newgroupAdmin = groupAdmin.filter(item => item !== Admin);
            groupAdmin = newgroupAdmin

        } else {
            groupAdmin.push(Admin)
        }
        singlegroupdata.groupadmins = groupAdmin
        dispatch(add_single_group(singlegroupdata))
    }
    async function showpopupforproject() {
        setopenModal(!openModal)
    }
    async function updateaccessvalue(name, e) {

        const updateaccess = singlegroupdata.access.map(access => {
            if (access.access === name) {
                return { ...access, type: e.target.value };
            } else {
                return access;
            }
        });
        const existingAccess = updateaccess.find(access => access.access === name);
        if (!existingAccess) {
            updateaccess.push({ access: name, type: e.target.value });
        }
        singlegroupdata.access = updateaccess
        dispatch(add_single_group(singlegroupdata))



    }

    return (
        <>
            <div className="col-md-7" style={{ "overflowY": "auto", "height": "95vh" }}>
                <div className="add-project-section">
                    <div className="project-name">
                        <p>Group name</p>
                        <div className="name-section">
                            <input type="text" placeholder="add group name "
                                className="project-form-control" name="groupname" onChange={(e) => handleChangegroup(e)} value={singlegroupdata.groupname} />
                        </div>
                    </div>

                    <div className="select-project-manager">
                        <div className="project-manager">

                            <div className="project-name" >
                                <p>Add Users</p>
                                {/* <div className="create-box-section-row">
                                </div> */}
                                <div className="name-section project-row" style={{ "marginBottom": "18px" }}>
                                    <select id="manager" input type="text" placeholder="user name or email addresss"
                                        className="project-form-padding project-form-control" name="manufacture_part_number" list="manager" onChange={(e) => dropdownselectManager(e)}>
                                        <option value="null" >--Select User--</option>
                                        {(state_account.users || []).map((data, index) => (
                                            <option multiple={true} value={data.email} key={`${index}useremail`}>{data.email}</option>
                                        ))}
                                    </select>
                                    <div className="" style={{ "marginLeft": "10px" }}>
                                        <div className="image-upload-box1">
                                            <button type="button" className="upload-own-image-button" onClick={() => { managerSelection(mail) }} >
                                                Add</button>
                                        </div>
                                    </div>


                                </div>



                                {(singlegroupdata.users || []).map((data, index) => (
                                    <div style={{ "marginBottom": "2px", "marginRight": "2px" }} className="badge badge-pill badge-primary" key={`${index}groupuser`}>

                                        <span style={{
                                            "cursor": "pointer",
                                            "color": state_group.singlegroupdetails.groupadmins.includes(data) ? '#1E1E1E' : '#409261'
                                        }} onClick={() => addGroupAdmin(index)} > {data}</span>
                                        <span style={{ "color": "#ffb0af" }} className="badge badge-light" />
                                        <button type="button" className="badge badge-pill badge-primary" onClick={() => deleteManager(index)}>X</button>
                                    </div>
                                ))}






                            </div>

                            <div className="project-name" >
                                <p>Module Access</p>
                                {/* <div className="create-box-section-row">
                                </div> */}


                                <table className="access_table">
                                    <tbody>
                                        <tr>
                                            <th>Modules</th>
                                            {/* <th>Read</th> */}
                                            <th>Allow Access</th>
                                            <th>No Access</th>
                                        </tr>


                                        <tr>
                                            <td>User</td>
                                            {/* <td>
                                                <input type="radio" value="read" name='users' onChange={(e) => updateaccessvalue("users", e)} checked={(singlegroupdata.access.some(access => access.access === 'users') && singlegroupdata.access.find(item => item.access === 'users').type == "read") ? true : false} />
                                            </td> */}
                                            <td>
                                                <input type="radio" value="both" name='users' onChange={(e) => updateaccessvalue("users", e)} checked={(singlegroupdata.access.some(access => access.access === 'users') && singlegroupdata.access.find(item => item.access === 'users').type == "both") ? true : false} />
                                            </td>
                                            <td>
                                                <input type="radio" value="noaccess" name='users' onChange={(e) => updateaccessvalue("users", e)} checked={(singlegroupdata.access.some(access => access.access === 'users') && singlegroupdata.access.find(item => item.access === 'users').type == "noaccess") ? true : false} />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Group</td>
                                            {/* <td>
                                                <input type="radio" value="read" name='groups' onChange={(e) => updateaccessvalue("groups", e)} checked={(singlegroupdata.access.some(access => access.access === 'groups') && singlegroupdata.access.find(item => item.access === 'groups').type == "read") ? true : false} />
                                            </td> */}
                                            <td>
                                                <input type="radio" value="both" name='groups' onChange={(e) => updateaccessvalue("groups", e)} checked={(singlegroupdata.access.some(access => access.access === 'groups') && singlegroupdata.access.find(item => item.access === 'groups').type == "both") ? true : false} />
                                            </td>
                                            <td>
                                                <input type="radio" value="noaccess" name='groups' onChange={(e) => updateaccessvalue("groups", e)} checked={(singlegroupdata.access.some(access => access.access === 'groups') && singlegroupdata.access.find(item => item.access === 'groups').type == "noaccess") ? true : false} />
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td>Task</td>
                                            <td>
                                                <input type="radio" value="read" name='task' onChange={(e) => updateaccessvalue("task", e)} checked={(singlegroupdata.access.some(access => access.access === 'task') && singlegroupdata.access.find(item => item.access === 'task').type == "read") ? true : false} />
                                            </td>
                                            <td>
                                                <input type="radio" value="both" name='task' onChange={(e) => updateaccessvalue("task", e)} checked={(singlegroupdata.access.some(access => access.access === 'task') && singlegroupdata.access.find(item => item.access === 'task').type == "both") ? true : false} />
                                            </td>
                                            <td>
                                                <input type="radio" value="noaccess" name='task' onChange={(e) => updateaccessvalue("task", e)} checked={(singlegroupdata.access.some(access => access.access === 'task') && singlegroupdata.access.find(item => item.access === 'task').type == "noaccess") ? true : false} />
                                            </td>
                                        </tr> */}

                                    </tbody>
                                </table>




                            </div>

                            <div className="project_access_main">
                                <div className="access_projct">
                                    <div><input type="checkbox" value="both" onChange={(e) => updateaccessvalue("projects", e)} checked={(singlegroupdata.access.some(access => access.access === "projects") && singlegroupdata.access.find(item => item.access === "projects").type == "both") ? true : false} /></div>
                                    <p style={{ "marginBottom": "0" }}>Select Project Access</p>
                                </div>

                                {(singlegroupdata.access.some(access => access.access === "projects") && singlegroupdata.access.find(item => item.access === "projects").type == "both") && <table className="access_table">
                                    <tbody>
                                        <tr>
                                            <th>Project Name</th>
                                            {/* <th>Read</th> */}
                                            <th>Allow Access</th>
                                            <th>No Access</th>
                                        </tr>

                                        {(state_project.projectlist || []).map((data, index) => (
                                            <tr key={`Project${index}`}>
                                                <td>{data.name}</td>
                                                {/* <td>
                                                    <input type="radio" value="read" name={data.name} onChange={(e) => updateaccessvalue(data.name, e)} checked={(singlegroupdata.access.some(access => access.access === data.name) && singlegroupdata.access.find(item => item.access === data.name).type == "read") ? true : false} />
                                                </td> */}
                                                <td>
                                                    <input type="radio" value="both" name={data.name} onChange={(e) => updateaccessvalue(data.name, e)} checked={(singlegroupdata.access.some(access => access.access === data.name) && singlegroupdata.access.find(item => item.access === data.name).type == "both") ? true : false} />
                                                </td>
                                                <td>
                                                    <input type="radio" value="noaccess" name={data.name} onChange={(e) => updateaccessvalue(data.name, e)} checked={(singlegroupdata.access.some(access => access.access === data.name) && singlegroupdata.access.find(item => item.access === data.name).type == "noaccess") ? true : false} />
                                                </td>
                                            </tr>))}
                                        {/* <tr>
                                                <td>Jesssu</td>
                                                <td>
                                                    <input type="radio" />
                                                </td>
                                                <td>
                                                    <input type="radio" />
                                                </td>
                                                <td>
                                                    <input type="radio" />
                                                </td>
                                            </tr> */}

                                    </tbody>
                                </table>}



                            </div>

                        </div>
                        <div className="create-box-section-row">
                        </div>
                        <div className="project-submit-section border_bottom pb">
                            <button type="button" onClick={() => creategroup()} className="btn btn-success submit-own-image-button">Save
                            </button>
                        </div>
                    </div>

                </div>

            </div>
        </>

    );
}
export { Creategroups };