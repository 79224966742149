import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import $ from "jquery";
import Swal from 'sweetalert2'
import { get_employees, get_managers, get_user_profile } from "../../store/account/api";
import { get_project_list_pagination, add_comment_for_issue, uploadfileonJira, assignissue, referenceAndEstimate, getJiraCredential, update_status_for_issue, getResourceForIssue, getEstimateTimeForIssue, detectEmotionForComment } from "store/projects/api";
import { create_event } from "store/task/api"
import { add_project_success, get_project_details_success, add_single_project, getjiraprojectwiseissue, getjiraprojectwiseissuedetails, setloading } from "store/projects/projectActions"
import { create_event_success, get_task_details_success, update_single_task } from "store/task/taskActions"
import { get_project_details } from "store/projects/api";
import { uploadfile } from "store/account/api";
import { add_single_task, add_single_event } from "store/task/taskActions";
import * as FormData from 'form-data'
import { async } from "@firebase/util";
import { Tasks } from "pages";
import { Admin, Manager, Project_Full, Task_Full, Task_Read, User } from '../../config';
import { Createtask } from "components/createtask";
import ReactMarkdown from 'react-markdown'
import { Buffer } from 'buffer';

const Projectdetails = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const state_account = useSelector(state => state.account);
    const [showEventmodal, setShowEventmodal] = useState(false);
    const state_project = useSelector(state => state.project);
    const state_task = useSelector(state => state.task);
    const singleeventdata = state_account.task
    const singletaskdata = state_task.singletaskdata;
    const [email, setEmail] = useState([])
    const [selectemail, setselectEmail] = useState("")
    const Token = localStorage.getItem("Token")
    const [pageno, setpageno] = useState(0)
    const [count, setcount] = useState(20)
    const Getdata = localStorage.getItem("Access")
    let Accessdata = []
    if (localStorage.getItem("Accessdata") != "undefined") {
        Accessdata = JSON.parse(localStorage.getItem("Accessdata"))
    }
    let GroupadminAccessdata = []
    if (localStorage.getItem("GroupadminAccessdata") != "undefined") {
        GroupadminAccessdata = JSON.parse(localStorage.getItem("GroupadminAccessdata"))
    }
    const UserEmail = localStorage.getItem("UserEmail")
    const [opendropdown, setopendropdown] = useState(false)
    let Email = ''
    let JiraToken = ''
    let JiraCredential = ''


    const addBlankComment = (issueKey, issueType) => {
        dispatch(setloading(true))
        const newComment = {
            body: '',
            author: `${state_account.profile.firstname} ${state_account.profile.lastname}`, // You can replace this with the actual author
            created: new Date().toISOString(),
            updated: new Date().toISOString(),
            id: 'new',
            commentemotion: null

        };

        let updatedIssueDetails = { ...state_project.issuedetails };

        if (issueType === 'sub-task') {
            const subTaskIndex = updatedIssueDetails.subTasks.findIndex(subTask => subTask.key === issueKey);
            if (subTaskIndex !== -1) {
                if (!updatedIssueDetails.subTasks[subTaskIndex].comments) {
                    updatedIssueDetails.subTasks[subTaskIndex].comments = [];
                }
                updatedIssueDetails.subTasks[subTaskIndex].comments.push(newComment);
            }
        } else {
            if (!updatedIssueDetails.comments) {
                updatedIssueDetails.comments = [];
            }
            updatedIssueDetails.comments.push(newComment);
        }

        // console.log(updatedIssueDetails)
        dispatch(getjiraprojectwiseissuedetails(updatedIssueDetails));
        dispatch(setloading(false))
    };

    const removeBlankComment = (issueKey, issueType) => {
        const updatedIssueDetails = { ...state_project.issuedetails };

        if (issueType === 'sub-task') {
            const subTaskIndex = updatedIssueDetails.subTasks.findIndex(subTask => subTask.key === issueKey);
            if (subTaskIndex !== -1 && updatedIssueDetails.subTasks[subTaskIndex].comments) {
                updatedIssueDetails.subTasks[subTaskIndex].comments = updatedIssueDetails.subTasks[subTaskIndex].comments.filter(comment => comment.id !== 'new');
            }
        } else {
            if (updatedIssueDetails.comments) {
                updatedIssueDetails.comments = updatedIssueDetails.comments.filter(comment => comment.id !== 'new');
            }
        }

        dispatch(getjiraprojectwiseissuedetails(updatedIssueDetails));
    };

    const handleTextareaChange = (e, issueType, issueKey) => {
        const updatedIssueDetails = { ...state_project.issuedetails };

        if (issueType === 'sub-task') {
            const subTaskIndex = updatedIssueDetails.subTasks.findIndex(subTask => subTask.key === issueKey);
            if (subTaskIndex !== -1) {
                const newCommentIndex = updatedIssueDetails.subTasks[subTaskIndex].comments.findIndex(comment => comment.id === 'new');
                if (newCommentIndex !== -1) {
                    updatedIssueDetails.subTasks[subTaskIndex].comments[newCommentIndex].body = e.target.value;
                }
            }
        } else {
            const newCommentIndex = updatedIssueDetails.comments.findIndex(comment => comment.id === 'new');
            if (newCommentIndex !== -1) {
                updatedIssueDetails.comments[newCommentIndex].body = e.target.value;
            }
        }
        dispatch(getjiraprojectwiseissuedetails(updatedIssueDetails));



    };




    useEffect(() => {
        scrollToTop()
        getJiraCredentials()
    }, []);

    useEffect(() => {
        if (Token != null) {
            dispatch(get_user_profile(Token, (res => {
            })))
        }
    }, []);

    async function scrollToTop() {
        $("#projectdetails").animate({ scrollTop: 0 }, "smooth");
    };

    const handleSaveClick = async (issuedata, issueType) => {
        dispatch(setloading(true))
        let updatedIssueDetails = { ...state_project.issuedetails };
        let commentBody = null;
        let commentemotion = null;
        if (issueType === 'sub-task') {
            const subTaskIndex = updatedIssueDetails.subTasks.findIndex(subTask => subTask.key === issuedata.key);
            if (subTaskIndex !== -1) {
                updatedIssueDetails.subTasks[subTaskIndex].comments.map(comment => {
                    if (comment.id === 'new') {
                        commentBody = comment.body;
                    }
                });
            }
        } else {
            updatedIssueDetails.comments.map(comment => {
                if (comment.id === 'new') {
                    commentBody = comment.body;

                }

            });
        }
        commentemotion = await getCommentEmotion(commentBody)
        if (issueType === 'sub-task') {
            const subTaskIndex = updatedIssueDetails.subTasks.findIndex(subTask => subTask.key === issuedata.key);
            if (subTaskIndex !== -1) {
                const updatedComments = updatedIssueDetails.subTasks[subTaskIndex].comments.map(comment => {
                    if (comment.id === 'new') {
                        commentBody = comment.body;
                        comment.commentemotion = commentemotion
                        return {
                            ...comment,
                            id: 'saved',
                        };
                    }
                    return comment;
                });

                updatedIssueDetails = {
                    ...updatedIssueDetails,
                    subTasks: updatedIssueDetails.subTasks.map((subTask, index) =>
                        index === subTaskIndex ? { ...subTask, comments: updatedComments } : subTask
                    ),
                };
            }
        } else {
            const updatedComments = updatedIssueDetails.comments.map(comment => {
                if (comment.id === 'new') {
                    commentBody = comment.body;
                    comment.commentemotion = commentemotion
                    return {
                        ...comment,
                        id: 'saved',
                    };
                }
                return comment;
            });

            updatedIssueDetails = {
                ...updatedIssueDetails,
                comments: updatedComments,
            };
        }

        if (commentBody) {
            const payload = {
                comment: commentBody,
                issueId: issuedata.key,
                jiracredentialid: issuedata.jiracredentialid,
                PROJECTNAME: issuedata.projectKey,
                author: `${state_account.profile.firstname} ${state_account.profile.lastname}`,
                commentemotion: commentemotion
            }
            dispatch(add_comment_for_issue(Token, payload, (res) => {
                if (res.success == true) {
                    dispatch(getjiraprojectwiseissuedetails(updatedIssueDetails));
                    const issuelist = [...state_project.issuelist]; // Create a shallow copy of the array
                    const updatedIssueList = issuelist.map(item => (item.key === updatedIssueDetails.key ? updatedIssueDetails : item));
                    dispatch(getjiraprojectwiseissue(updatedIssueList))
                    dispatch(setloading(false))
                } else {
                    dispatch(setloading(false))
                }
            }))
        }
    };

    const updateIssueStatus = (issuedata, issueType, status) => {
        dispatch(setloading(true))
        let updatedIssueDetails = { ...state_project.issuedetails };

        if (issueType === 'sub-task') {
            const subTaskIndex = updatedIssueDetails.subTasks.findIndex(subTask => subTask.key === issuedata.key);
            if (subTaskIndex !== -1) {
                updatedIssueDetails = {
                    ...updatedIssueDetails,
                    subTasks: updatedIssueDetails.subTasks.map((subTask, index) =>
                        index === subTaskIndex ? { ...subTask, status: status } : subTask
                    ),
                };
            }
        } else {
            updatedIssueDetails = {
                ...updatedIssueDetails,
                status: status,
            };
        }
        // setopendropdown(!opendropdown)
        const newData = JSON.parse(JSON.stringify(updatedIssueDetails));
        closeStatusbox(newData);
        const payload = {
            status: status,
            issueId: issuedata.key,
            jiracredentialid: issuedata.jiracredentialid,
            PROJECTNAME: issuedata.projectKey
        }
        dispatch(update_status_for_issue(Token, payload, (res) => {
            if (res.success == true) {
                dispatch(getjiraprojectwiseissuedetails(newData));
                // dispatch(getjiraprojectwiseissuedetails(newData));
                const issuelist = [...state_project.issuelist]; // Create a shallow copy of the array
                const updatedIssueList = issuelist.map(item => (item.key === newData.key ? newData : item));
                dispatch(getjiraprojectwiseissue(updatedIssueList))
                dispatch(setloading(false))

            } else {
                dispatch(setloading(false))
            }
        }))

    };

    function updateStatusboxByKey(data, key, issueType) {
        // Clone the data to avoid modifying the original object
        const newData = JSON.parse(JSON.stringify(data));

        // Helper function to recursively update statusbox properties
        function updateStatusbox(obj) {
            obj.statusbox = !obj.statusbox;
            if (obj.subTasks && Array.isArray(obj.subTasks)) {
                obj.subTasks.forEach((subtask) => {
                    subtask.statusbox = subtask.key === key ? !subtask.statusbox : false;
                });
            }
        }

        // Find the target object based on the key
        const targetObject = issueType === "Story"
            ? newData
            : newData.subTasks.find((subtask) => subtask.key === key);

        if (targetObject) {
            updateStatusbox(targetObject);
            // If issueType is "Subtask," also set the main object's statusbox to false
            if (issueType === "Subtask") {
                newData.statusbox = false;
                newData.subTasks.forEach((subtask) => {
                    if (subtask.key !== key) {
                        subtask.statusbox = false;
                    }
                });
            }
        }

        // Dispatch the modified data to Redux
        dispatch(getjiraprojectwiseissuedetails(newData));
    }


    function closeStatusbox(obj) {
        obj.statusbox = false;
        if (obj.subTasks && Array.isArray(obj.subTasks)) {
            obj.subTasks.forEach((subtask) => {
                closeStatusbox(subtask);
            });
        }
    }


    async function getJiraCredentials() {
        const payload = {
            id: state_project.issuedetails.jiracredentialid
        }
        try {
            await dispatch(getJiraCredential(Token, payload, async (res) => {
                if (res.success == true) {
                    Email = res.data.jiraemail
                    JiraToken = res.data.jiraapitoken
                    JiraCredential = Buffer.from(`${Email}:${Token}`).toString('base64');
                }
            }))
        }
        catch (e) {
            console.log(e)
        }
    }


    async function fetchAttachmentsFromJira(ImageURL) {
        try {
            const responsedata = await fetch(`${ImageURL}`, {
                headers: {
                    Authorization: `Basic ${JiraCredential}`,
                    Accept: 'application/json'
                },
            });
            return responsedata;
        }
        catch (e) {
            return ImageURL
        }
    }

    async function editproject() {
        if (Getdata.includes(`${Admin}`) || Getdata.includes(`${Project_Full}`)) {
            Swal.fire({
                title: 'Do you want to edit the Project Details?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {

                    Swal.fire('confirmed!', '', 'success')
                    let projectdetails = state_project.singleprojectdetails[0]

                    projectdetails.projectprice = (state_project.singleprojectdetails[0].projectcost).split(' ')[0]
                    projectdetails.projectcurrency = (state_project.singleprojectdetails[0].projectcost).split(' ')[1]
                    dispatch(add_single_project(projectdetails))
                    dispatch(add_project_success(true))
                    dispatch(get_project_details_success(false))
                } else if (result.dismiss === Swal.DismissReason.cancel) { // Check for cancel button dismissal
                    Swal.fire('Changes are not saved', '', 'info')
                }
            })

        } else {
            Swal.fire({ confirmButtonColor: "#365d5a", title: "You don't have access", type: 'info' })
        }
    }

    async function edittask(data) {

        if (Getdata.includes(`${Admin}`) || Getdata.includes(`${Manager}`) || Getdata.includes(`${Task_Full}`)) {
            Swal.fire({
                title: 'Do you want to edit the Task Details?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    Swal.fire('confirmed!', '', 'success')
                    dispatch(add_single_task(data))
                    dispatch(add_project_success(false))
                    dispatch(get_project_details_success(false))
                    dispatch(update_single_task(true))
                } else if (result.dismiss === Swal.DismissReason.cancel) { // Check for cancel button dismissal
                    Swal.fire('Changes are not saved', '', 'info')
                }
            })

        } else {
            Swal.fire({ confirmButtonColor: "#365d5a", title: "You don't have access", type: 'info' })
        }


    }
    async function gototaskcomment(data) {

        console.log(data)
        Swal.fire({
            title: 'Do you want to add comment on this task?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Swal.fire('confirmed!', '', 'success')
                dispatch(add_single_task(data))
                dispatch(add_single_project(state_project.singleprojectdetails[0]))
                dispatch(add_project_success(false))
                dispatch(get_project_details_success(false))
                dispatch(update_single_task(true))
                navigate("/tasks?id=" + data._id + "&project_name=" + data.projectname + "&task_name=" + data.taskname + "&task_description=" + data.taskdescription + "&date=" + data.updatedAt);


                // navigate(`/tasks/${data._id}`)
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })

    }


    async function gofortaskwork(data) {

        Swal.fire({
            title: 'Do you want to work on this task?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                // console.log(data)
                dispatch(add_single_task(data))
                navigate("/timer?id=" + data.key + "&project_name=" + data.projectKey + "&task_name=" + data.summary + "&project=" + data.projectKey);
            }
        })

    }

    async function dropdownselectEmployee(f) {
        setselectEmail(f.target.value)

    };
    async function employeeSelection() {
        if (selectemail != null || selectemail != "") {
            if (email.includes(selectemail)) {
            } else {
                setEmail(current => [...current, selectemail]);

            }
        }
    }

    async function deleatEmployee(e) {

        const s = email.filter((data, index) => index !== e)
        setEmail(s);

    }

    async function uploadimage(singlefile, key, credentialid, type) {
        debugger
        dispatch(setloading(true))
        const formData = new FormData();

        formData.append(
            "file",
            singlefile
        );

        dispatch(uploadfileonJira(Token, formData, key, credentialid, res => {
            // console.log(res);

            let data = {
                "filename": res.data.filename,
                "created": res.data.created,
                "size": '',
                "mimeType": '',
                "contentUrl": res.data.contentUrl,
                "author": `${state_account.profile.firstname} ${state_account.profile.lastname}`
            }


            let updatedIssueDetails = { ...state_project.issuedetails };
            if (type === 'sub-task') {
                const subTaskIndex = updatedIssueDetails.subTasks.findIndex(subTask => subTask.key === key);
                if (subTaskIndex !== -1) {
                    const updatedSubTasks = updatedIssueDetails.subTasks.map((subTask, index) => {
                        if (index === subTaskIndex) {
                            return {
                                ...subTask,
                                files: [...subTask.files, data] // Push the data into the files array of the sub-task
                            };
                        }
                        return subTask;
                    });

                    updatedIssueDetails = {
                        ...updatedIssueDetails,
                        subTasks: updatedSubTasks,
                    };
                }
            } else {
                updatedIssueDetails.files.push(data);
            }
            dispatch(getjiraprojectwiseissuedetails(updatedIssueDetails));
            const issuelist = [...state_project.issuelist]; // Create a shallow copy of the array
            const updatedIssueList = issuelist.map(item => (item.key === updatedIssueDetails.key ? updatedIssueDetails : item));
            dispatch(getjiraprojectwiseissue(updatedIssueList))
            dispatch(setloading(false))
        }
        ))
    }

    async function uploadFile(e, data, type) {
        if (e) {
            for (let i = 0; i < e.target.files.length; i++) {
                await uploadimage(e.target.files[i], data.key, data.jiraId, type)
            }
        }
    }

    async function dropdownselectUser(e, data, type) {
        let assignedTo = e.target.value
        // setselectmail(e.target.value)
        let payload = null
        let updatedIssueDetails = { ...state_project.issuedetails };
        if (type === 'sub-task') {
            const subTaskIndex = updatedIssueDetails.subTasks.findIndex(subTask => subTask.key === data.key);
            if (subTaskIndex !== -1) {
                updatedIssueDetails = {
                    ...updatedIssueDetails,
                    subTasks: updatedIssueDetails.subTasks.map((subTask, index) =>
                        index === subTaskIndex ? { ...subTask, assignedTo: e.target.value } : subTask
                    ),
                };
            }
            payload = {
                assignedTo: e.target.value,
                jiraId: data.key,
                dueDate: data.dueDate,
                "PROJECTID": data.key,
                "PROJECTNAME": data.projectKey,
                "ATTACHMENT": data.files,
                "NOTIFICATION": `New Task assinged BY ${state_account.profile.email}.Task id is ${data.key}, Project Name is ${data.projectKey} and Project due date is ${data.dueDate}.`,

            }
            dispatch(assignissue(Token, payload))

        }
        else {
            payload = {
                assignedTo: e.target.value,
                jiraId: data.key,
                dueDate: data.dueDate,
                "PROJECTID": data.key,
                "PROJECTNAME": data.projectKey,
                "ATTACHMENT": data.files,
                "NOTIFICATION": `New Task assinged BY ${state_account.profile.email}.Task id is ${data.key}, Project Name is ${data.projectKey} and Project due date is ${data.dueDate}.`,

            }
            dispatch(assignissue(Token, payload))
            updatedIssueDetails.assignedTo = e.target.value

        }


        dispatch(getjiraprojectwiseissuedetails(updatedIssueDetails));
        const issuelist = [...state_project.issuelist]; // Create a shallow copy of the array
        const updatedIssueList = issuelist.map(item => (item.key === updatedIssueDetails.key ? updatedIssueDetails : item));
        dispatch(getjiraprojectwiseissue(updatedIssueList))
        getEstimateandReferance(data, type, assignedTo)

    };

    async function handleChangetask(f, data) {
        let updatedIssueDetails = { ...state_project.issuedetails };

        if (f.target.name === 'sub-task') {

            const subTaskIndex = updatedIssueDetails.subTasks.findIndex(subTask => subTask.key === data.key);
            if (subTaskIndex !== -1) {
                updatedIssueDetails = {
                    ...updatedIssueDetails,
                    subTasks: updatedIssueDetails.subTasks.map((subTask, index) =>
                        index === subTaskIndex ? { ...subTask, dueDate: f.target.value } : subTask
                    ),
                };
            }
            let payload = {
                assignedTo: data.assignedTo,
                jiraId: data.key,
                dueDate: f.target.value,
                "PROJECTID": data.key,
                "PROJECTNAME": data.projectKey,
                "ATTACHMENT": data.files,
                "NOTIFICATION": `New Task assinged BY ${state_account.profile.email}.Task id is ${data.key}, Project Name is ${data.projectKey} and Project due date is ${f.target.value}.`,

            }
            dispatch(assignissue(Token, payload))

        }
        else {
            updatedIssueDetails.dueDate = f.target.value
            let payload = {
                assignedTo: data.assignedTo,
                jiraId: data.key,
                dueDate: f.target.value,
                "PROJECTID": data.key,
                "PROJECTNAME": data.projectKey,
                "ATTACHMENT": data.files,
                "NOTIFICATION": `New Task assinged BY ${state_account.profile.email}.Task id is ${data.key}, Project Name is ${data.projectKey} and Project due date is ${f.target.value}.`,

            }
            dispatch(assignissue(Token, payload))
        }
        dispatch(getjiraprojectwiseissuedetails(updatedIssueDetails));
        const issuelist = [...state_project.issuelist]; // Create a shallow copy of the array
        const updatedIssueList = issuelist.map(item => (item.key === updatedIssueDetails.key ? updatedIssueDetails : item));
        dispatch(getjiraprojectwiseissue(updatedIssueList))
    };

    async function downloadImage(contentUrl) {
        // let url = await fetchAttachmentsFromJira(contentUrl)
        window.open(contentUrl, '_blank')
    };

    async function getEstimateandReferance(data, type, assignedTo) {
        if (!data.reference && !data.estimatedtime) {
            dispatch(setloading(true))
            let response = {};
            const querypayload = {
                query: `Summary: ${data.summary ? data.summary : "None"}; Description: ${data.description ? data.description : "None"}`
            };

            // Wrap the dispatch calls in Promises
            const getResourcePromise = new Promise((resolve, reject) => {
                dispatch(getResourceForIssue(querypayload, (res) => {
                    if (res.success) {
                        response.reference = res.data;
                        resolve(response);
                    } else {
                        reject(res.data);
                    }
                }));
            });

            const getEstimatePromise = new Promise((resolve, reject) => {
                dispatch(getEstimateTimeForIssue(querypayload, (res) => {
                    if (res.success) {
                        response.estimate = res.data.result;
                        resolve(response);
                    } else {
                        reject(res.data);
                    }
                }));
            });

            // Wait for both promises to resolve
            try {
                await Promise.all([getResourcePromise, getEstimatePromise]);
                let payload = null
                let updatedIssueDetails = { ...state_project.issuedetails };
                if (type === 'sub-task') {
                    const subTaskIndex = updatedIssueDetails.subTasks.findIndex(subTask => subTask.key === data.key);
                    if (subTaskIndex !== -1) {
                        updatedIssueDetails = {
                            ...updatedIssueDetails,
                            subTasks: updatedIssueDetails.subTasks.map((subTask, index) =>
                                index === subTaskIndex ? { ...subTask, assignedTo: assignedTo, estimatedtime: response.estimate, reference: response.reference } : subTask
                            ),
                        };
                    }
                    payload = {
                        jiraId: data.key,
                        "PROJECTID": data.key,
                        "PROJECTNAME": data.projectKey,
                        estimatedtime: response.estimate,
                        reference: response.reference

                    }
                    dispatch(referenceAndEstimate(Token, payload))
                }
                else {
                    payload = {
                        jiraId: data.key,
                        "PROJECTID": data.key,
                        "PROJECTNAME": data.projectKey,
                        estimatedtime: response.estimate,
                        reference: response.reference
                    }
                    dispatch(referenceAndEstimate(Token, payload))
                    updatedIssueDetails.assignedTo = assignedTo
                    updatedIssueDetails.estimatedtime = response.estimate
                    updatedIssueDetails.reference = response.reference
                }
                dispatch(getjiraprojectwiseissuedetails(updatedIssueDetails));
                const issuelist = [...state_project.issuelist]; // Create a shallow copy of the array
                const updatedIssueList = issuelist.map(item => (item.key === updatedIssueDetails.key ? updatedIssueDetails : item));
                dispatch(getjiraprojectwiseissue(updatedIssueList))
                dispatch(setloading(false))
                Swal.fire('Reference generated successfully!', '', 'info')

            } catch (error) {
                if (response.estimate) {
                    let payload = null
                    let updatedIssueDetails = { ...state_project.issuedetails };
                    if (type === 'sub-task') {
                        const subTaskIndex = updatedIssueDetails.subTasks.findIndex(subTask => subTask.key === data.key);
                        if (subTaskIndex !== -1) {
                            updatedIssueDetails = {
                                ...updatedIssueDetails,
                                subTasks: updatedIssueDetails.subTasks.map((subTask, index) =>
                                    index === subTaskIndex ? { ...subTask, assignedTo: assignedTo, estimatedtime: response.estimate, reference: {} } : subTask
                                ),
                            };
                        }
                        payload = {
                            jiraId: data.key,
                            "PROJECTID": data.key,
                            "PROJECTNAME": data.projectKey,
                            estimatedtime: response.estimate,
                            reference: {}

                        }
                        dispatch(referenceAndEstimate(Token, payload))
                    }
                    else {
                        payload = {
                            jiraId: data.key,
                            "PROJECTID": data.key,
                            "PROJECTNAME": data.projectKey,
                            estimatedtime: response.estimate,
                            reference: {}
                        }
                        dispatch(referenceAndEstimate(Token, payload))
                        updatedIssueDetails.assignedTo = assignedTo
                        updatedIssueDetails.estimatedtime = response.estimate
                        updatedIssueDetails.reference = {}
                    }
                    dispatch(getjiraprojectwiseissuedetails(updatedIssueDetails));
                    const issuelist = [...state_project.issuelist]; // Create a shallow copy of the array
                    const updatedIssueList = issuelist.map(item => (item.key === updatedIssueDetails.key ? updatedIssueDetails : item));
                    dispatch(getjiraprojectwiseissue(updatedIssueList))
                    dispatch(setloading(false))
                    Swal.fire('Estimate time generated successfully!', '', 'info')
                }
                else if (response.reference) {
                    let payload = null
                    let updatedIssueDetails = { ...state_project.issuedetails };
                    if (type === 'sub-task') {
                        const subTaskIndex = updatedIssueDetails.subTasks.findIndex(subTask => subTask.key === data.key);
                        if (subTaskIndex !== -1) {
                            updatedIssueDetails = {
                                ...updatedIssueDetails,
                                subTasks: updatedIssueDetails.subTasks.map((subTask, index) =>
                                    index === subTaskIndex ? { ...subTask, assignedTo: assignedTo, estimatedtime: "", reference: response.reference } : subTask
                                ),
                            };
                        }
                        payload = {
                            jiraId: data.key,
                            "PROJECTID": data.key,
                            "PROJECTNAME": data.projectKey,
                            estimatedtime: '',
                            reference: response.reference

                        }
                        dispatch(referenceAndEstimate(Token, payload))
                    }
                    else {
                        payload = {
                            jiraId: data.key,
                            "PROJECTID": data.key,
                            "PROJECTNAME": data.projectKey,
                            estimatedtime: "",
                            reference: response.reference
                        }
                        dispatch(referenceAndEstimate(Token, payload))
                        updatedIssueDetails.assignedTo = assignedTo
                        updatedIssueDetails.estimatedtime = ''
                        updatedIssueDetails.reference = response.reference
                    }
                    dispatch(getjiraprojectwiseissuedetails(updatedIssueDetails));
                    const issuelist = [...state_project.issuelist]; // Create a shallow copy of the array
                    const updatedIssueList = issuelist.map(item => (item.key === updatedIssueDetails.key ? updatedIssueDetails : item));
                    dispatch(getjiraprojectwiseissue(updatedIssueList))
                    dispatch(setloading(false))
                    Swal.fire('Reference generated successfully!', '', 'info')
                } else {
                    Swal.fire('Received error!', '', 'info')
                    dispatch(setloading(false))
                    throw error;
                }
            }
        }
    }

    async function getCommentEmotion(comment) {
        let response = {};
        const payload = {
            text: comment
        };
        // Wrap the dispatch calls in Promises
        const getResourceEmotion = new Promise((resolve, reject) => {
            dispatch(detectEmotionForComment(payload, (res) => {
                if (res.success) {
                    response = res.data;
                    resolve(response);
                } else {
                    reject(res.data);
                }
            }));
        });

        // Wait for promise to resolve
        try {
            await Promise.all([getResourceEmotion]);
            return response;
        } catch (error) {
            throw error;
        }
    }


    return (

        <>{state_project.issuedetails != '' && (
            <div className="col-md-7 projectdetails-size" id="projectdetails" style={{ "overflowY": "auto", "height": "90vh", "padding": "0", "wordWrap": "break-word" }}>
                <div className="task-manager-title project-row">
                    {/* <h6>Story Details</h6> */}
                    {state_project.issuedetails?.summary && <h6>{state_project.issuedetails.summary}</h6>}
                    <div className="image-upload-box1">
                        {UserEmail === state_project.issuedetails.assignedTo && <button type="button" className="upload-own-image-button project-row" onClick={() => gofortaskwork(state_project.issuedetails)} style={{ "width": "11.5rem", "marginLeft": "3px" }}><img src="/images/icons/timer.svg" style={{ "marginRight": "5px" }} />Start Task</button>}
                    </div>
                </div>
                <div>
                    <div className="project-type-details">
                        <div className="project-manager-title">
                            <div className="Completed-section">
                                <p className={`project-taskstatus ${(state_project.issuedetails?.status).toLowerCase() === 'to do' ? 'status-to-do-text' :
                                    (state_project.issuedetails?.status).toLowerCase() === 'in progress' ? 'status-in-progress-text' :
                                        (state_project.issuedetails?.status).toLowerCase() === 'done' ? 'status-done-text' : ''}`}>
                                    {/* {data.issueType} */}
                                    {(state_project.issuedetails?.issueType).toLowerCase() === 'story' ? <img src="images/icons/Story.svg" /> : (state_project.issuedetails?.issueType).toLowerCase() === 'task' ? <img src="images/icons/Task.svg" /> : (state_project.issuedetails?.issueType).toLowerCase() === 'sub-task' ? <img src="images/icons/Sub-task.svg" /> : <img src="images/icons/Bug.svg" />} {state_project.issuedetails.key}</p>
                                {/* <div className={`redio_cercle ${(state_project.issuedetails?.status).toLowerCase() === 'to do' ? 'status-to-do-background-color' :
                                    (state_project.issuedetails?.status).toLowerCase() === 'in progress' ? 'status-in-progress-background-color' :
                                        (state_project.issuedetails?.status).toLowerCase() === 'done' ? 'status-done-background-color' : ''}`}></div>
                                <p className={`${(state_project.issuedetails?.status).toLowerCase() === 'to do' ? 'status-to-do-text' :
                                    (state_project.issuedetails?.status).toLowerCase() === 'in progress' ? 'status-in-progress-text' :
                                        (state_project.issuedetails?.status).toLowerCase() === 'done' ? 'status-done-text' : ''}`}>{state_project.issuedetails?.issueType} - {state_project.issuedetails.key}</p> */}
                            </div>

                            <div className="">
                                <div className="sec-center">
                                    <input className="dropdown" value="true" type="checkbox" id={`dropdown-${state_project.issuedetails.key}`} onClick={(e) => updateStatusboxByKey(state_project.issuedetails, state_project.issuedetails.key, "Story")} name={`status-dropdown-${state_project.issuedetails.key}`} />
                                    <label className="for-dropdown" htmlFor={`dropdown-${state_project.issuedetails.key}`}>{state_project.issuedetails?.status}</label>
                                    <div className={`section-dropdown ${state_project.issuedetails?.statusbox === true ? 'dropdown-show' : ''}`} id={`section-dropdown-${state_project.issuedetails.key}`}>
                                        <p className="status-to-do-text" value='To Do' onClick={() => updateIssueStatus(state_project.issuedetails, 'story', 'To Do')} >To Do</p>
                                        <p className="status-in-progress-text" value='In Progress' onClick={() => updateIssueStatus(state_project.issuedetails, 'story', 'In Progress')}>In Progress</p>
                                        <p className="status-done-text" value='Done' onClick={() => updateIssueStatus(state_project.issuedetails, 'story', 'Done')}>Done</p>
                                    </div>


                                    {/* <input className="dropdown" type="checkbox" id="dropdown" name="dropdown" />
                                    <label className="for-dropdown" for="dropdown">{state_project.issuedetails?.status}</label>
                                    <div className="section-dropdown">
                                        <p className="status-to-do-text" value='To Do' onClick={() => updateIssueStatus(state_project.issuedetails, 'story', 'To Do')} >To Do</p>
                                        <p className="status-in-progress-text" value='In Progress' onClick={() => updateIssueStatus(state_project.issuedetails, 'story', 'In Progress')}>In Progress</p>
                                        <p className="status-done-text" value='Done' onClick={() => updateIssueStatus(state_project.issuedetails, 'story', 'Done')}>Done</p>
                                    </div> */}
                                </div>
                            </div>


                            {/* <h6>Id:- <span>{state_project.issuedetails.key}</span></h6> */}
                            <div className="project-manager-title">
                                {state_project.issuedetails.relatedEpics[0]?.summary && <h6>Epic: <span>{state_project.issuedetails.relatedEpics[0].summary}</span></h6>}
                            </div>
                            {/* <div className="project-manager-title">
                                    {state_project.issuedetails.summary && <h6>summary: <span>{state_project.issuedetails.summary}</span></h6>}
                                </div> */}
                            <div className="project-manager-title">
                                {state_project.issuedetails?.description && <h6>Description: <span>{state_project.issuedetails.description}</span></h6>}
                            </div>

                            <div className="project-manager-title">
                                {state_project.issuedetails?.storyPoints && <h6>Story Point: <span>{state_project.issuedetails.storyPoints}</span></h6>}
                            </div>

                        </div>
                    </div>
                    <>
                        <div className="project-type-details project-row" style={{ "marginBottom": "10px", "borderBottom": "0" }}>
                            <div className="project-manager-title col-md-5">
                                <h6>Assign to</h6>
                                {/* {(Accessdata.some(item => item.access === "task" && item.type === 'both') || UserEmail === "admin@wrapup.com")  */}
                                {(state_project.issuedetails?.assignee === `${state_account.profile.firstname} ${state_account.profile.lastname}` || UserEmail === "admin@wrapup.com" || GroupadminAccessdata.find(item => item.access === state_project.selectedprojectdetails?.name && item.type !== "noaccess"))
                                    && <div className="name-section">

                                        <select id="manager" type="text" placeholder="user name or email addresss"
                                            className="project-form-padding project-form-control" name="manufacture_part_number" list="manager" onChange={(e) => dropdownselectUser(e, state_project.issuedetails, 'story')} value={state_project.issuedetails?.assignedTo != undefined ? state_project.issuedetails.assignedTo : "null"}>
                                            <option value="null" >--Select User--</option>
                                            {(state_project.projectwiseemaillist || []).map((data, index) => (
                                                <option value={data.user} key={`User-Email-${index}`}>{data.user}</option>
                                            ))}
                                        </select>
                                    </div>}
                            </div>
                            {/* </div>
                        <div className="project-type-details"> */}
                            <div className="project-manager-title col-md-5">
                                <h6>Due date: {state_project.issuedetails?.dueDate && <>{new Intl.DateTimeFormat('en-GB', {
                                    month: 'long',
                                    day: '2-digit',
                                    year: 'numeric',
                                }).format(new Date(state_project.issuedetails?.dueDate))}</>}</h6>
                                {/* {(Accessdata.some(item => item.access === "task" && item.type === 'both') || UserEmail === "admin@wrapup.com")  */}
                                {(state_project.issuedetails?.assignee === `${state_account.profile.firstname} ${state_account.profile.lastname}` || UserEmail === "admin@wrapup.com" || GroupadminAccessdata.find(item => item.access === state_project.selectedprojectdetails?.name && item.type !== "noaccess"))
                                    && <div className="name-section">
                                        <input
                                            type="date" data-date="" data-date-format="DD MMMM YYYY" name={`issue-file-${state_project.issuedetails.key}`}
                                            placeholder="Select your task deadline date"
                                            className="project-form-control"
                                            onChange={(f) => handleChangetask(f, state_project.issuedetails)}
                                            value={state_project.issuedetails?.dueDate ? state_project.issuedetails?.dueDate : ""}
                                        />
                                    </div>}
                            </div>
                        </div>
                        {state_project.issuedetails?.assignedTo && <a href="#" className="project_key_email">
                            {state_project.issuedetails.assignedTo}
                        </a>}
                        <hr />


                        <div>
                            {state_project.issuedetails?.estimatedtime && <div className="access_projct estimated-box">
                                <img src="/images/icons/clock-ico.svg" width={'25px'} />
                                <h5>Estimated time: <span>{state_project.issuedetails?.estimatedtime}</span></h5>
                            </div>}
                            {state_project.issuedetails?.reference &&
                                <><div className="related-link-row">
                                    {state_project.issuedetails?.reference["Google Links :"] && <div className="estimated-box related-link-box">
                                        <div className="access_projct"><img src="/images/icons/google-ico.svg" width={'25px'} />
                                            <h5>Related Google links</h5></div>
                                        <div className="related-box-links">


                                            {state_project.issuedetails.reference["Google Links :"][0].length > 0 ? (
                                                state_project.issuedetails.reference["Google Links :"][0]?.map((link, index) => (
                                                    <a key={`GoogleLinkStory${index}`} href={link} className="project_key_email">
                                                        {link}
                                                    </a>
                                                ))
                                            ) : (
                                                <h6 className="project_key_email" style={{ textAlign: "center" }}>
                                                    No links available
                                                </h6>
                                            )}



                                        </div>
                                    </div>}
                                    {state_project.issuedetails?.reference["YouTube Links : "] && <div className="estimated-box related-link-box">
                                        <div className="access_projct"><img src="/images/icons/youtube-ico.svg" width={'25px'} />
                                            <h5>Related YouTube links</h5></div>
                                        <div className="related-box-links">

                                            {state_project.issuedetails.reference["YouTube Links : "][0]?.length > 0 ? (
                                                state_project.issuedetails.reference["YouTube Links : "][0]?.map((link, index) => (
                                                    <a key={index} href={link} className="project_key_email">
                                                        {link}
                                                    </a>
                                                ))
                                            ) : (
                                                <h6 className="project_key_email" style={{ textAlign: "center" }}>
                                                    No links available
                                                </h6>
                                            )}
                                        </div>
                                    </div>}
                                </div>


                                    {state_project.issuedetails?.reference["Open AI result: "] &&
                                        <div className="estimated-box openai-box">
                                            <div className="access_projct"><img src="/images/icons/openai-ico.svg" width={'25px'} />
                                                <h5>Related OpenAI result</h5></div>

                                            <div className="openai-textSection related-box-links">
                                                <ReactMarkdown>
                                                    {state_project.issuedetails.reference["Open AI result: "]}
                                                </ReactMarkdown>
                                            </div>

                                        </div>}
                                </>}
                        </div>
                    </>



                    {/* {state_project.issuedetails.dueDate && <div>
                        <p>Due Date:{state_project.issuedetails.dueDate}</p>
                    </div>} */}
                    <div className="Assigned-section">

                        <div className="project-Assigned-title project-row">
                            <h6>Attachments:</h6>
                            {(UserEmail === state_project.issuedetails?.assignedTo || UserEmail === "admin@wrapup.com" || GroupadminAccessdata.find(item => item.access === state_project.selectedprojectdetails?.name && item.type !== "noaccess")) && <div className="image-upload-box1">

                                <input
                                    name={`issue-file-${state_project.issuedetails.key}`}
                                    type="file" multiple="multiple"
                                    // disabled={file.length === 1}
                                    className="form-control"
                                    onChange={(e) => uploadFile(e, state_project.issuedetails, 'story')}
                                />
                                <button type="button" className="upload-own-image-button project-row"><img src="/images/icons/attach-file.svg" style={{ "marginRight": "6px" }} />Attach
                                    files</button>
                            </div>}
                        </div>

                        <div className="attachments_wrapp form-group preview">
                            {state_project.issuedetails?.files.map((item, index) => {
                                return (
                                    <div className="image-upload-box1" key={`Image-Files-${index}`}>
                                        <div className="img-cercle attach-file-wrapp">
                                            <img src={item.contentUrl} alt="" className="main-img" />
                                            <div className="import-img">
                                                <img src="/images/icons/import-ico.svg" onClick={() => downloadImage(item.contentUrl)} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <hr />
                    </div>


                    {/* <div className="project-upload-section">
                                            <div className="image-upload-box1">
                                                <button type="button" className="upload-own-image-button" onClick={() => gofortaskwork(data)}>
                                                    Work on this task</button>
                                            </div>
                                        </div> */}

                    <div className="project-Assigned-title">
                        {/* <h6>Commented on:</h6>
                        <p>This task comments from other members</p> */}
                        <div className="form-group preview">
                            {state_project.issuedetails?.comments.length > 0 &&
                                state_project.issuedetails?.comments.filter(comment => comment.id !== 'new').map((item, index) => {
                                    return (<>
                                        <div key={`${index}-Comment-Data`}>
                                            <div className="task-comments">
                                                <ReactMarkdown className="markdown-text">{item.body}</ReactMarkdown>
                                                <div className="project-row task-comments-row">
                                                    <div className="Completed-section">
                                                        <p>By {item.author}</p>
                                                    </div>
                                                    <div className="Completed-section">
                                                        <div className="redio_cercle"></div>
                                                        Commented on:<p>{new Intl.DateTimeFormat('en-GB', {
                                                            month: 'long',
                                                            day: '2-digit',
                                                            year: 'numeric',
                                                        }).format(new Date(item.updated))}</p>
                                                    </div>
                                                    {item.commentemotion && <div className="Completed-section ai-review">
                                                        {item.commentemotion?.Sentiment === "neutral" && <p style={{ "text-transform": "capitalize" }}>😐 {item.commentemotion?.Sentiment}</p>}
                                                        {item.commentemotion?.Sentiment === "joy" && <p style={{ "text-transform": "capitalize" }}>😃 {item.commentemotion?.Sentiment}</p>}
                                                        {(item.commentemotion?.Sentiment === "sadness" || item.commentemotion?.Sentiment === "sad") && <p style={{ "text-transform": "capitalize" }}>😔 {item.commentemotion?.Sentiment}</p>}
                                                        {(item.commentemotion?.Sentiment === "angry" || item.commentemotion?.Sentiment === "anger") && <p style={{ "text-transform": "capitalize" }}>😠 {item.commentemotion?.Sentiment}</p>}
                                                        {item.commentemotion?.Sentiment === "surprise" && <p style={{ "text-transform": "capitalize" }}>😯 {item.commentemotion?.Sentiment} </p>}
                                                        {/* <p>😃Joy 😔Sad 😠Angry</p> */}
                                                    </div>}

                                                </div>
                                            </div>
                                        </div>

                                    </>
                                    );
                                })}
                        </div>
                    </div>

                    {/* <div className="project-upload-section">
                            <div className="image-upload-box1">
                                <button type="button" className="upload-own-image-button" onClick={() => gofortaskwork(state_project.issuedetails)}>
                                    Work on this task</button>
                            </div>
                        </div> */}
                    {/* <div className="project-upload-section">
                            <div className="image-upload-box1">
                                <input
                                    type="file" multiple="multiple"
                                    // disabled={file.length === 1}
                                    className="form-control"
                                    onChange={(e) => uploadFile(e, state_project.issuedetails.key, 'story')}
                                />
                                <button type="button" className="upload-own-image-button" >Attach
                                    files</button>

                                <div>
                                </div>
                            </div>
                        </div> */}
                    <hr />

                    {(state_project.issuedetails?.comments.length == 0 || state_project.issuedetails.comments[state_project.issuedetails.comments.length - 1]?.id != "new") && <button className="upload-own-image-button" onClick={() => addBlankComment(state_project.issuedetails.key, 'story')}>Add Comment</button>}

                    {(state_project.issuedetails?.comments.length != 0 && state_project.issuedetails.comments[state_project.issuedetails.comments.length - 1]?.id == "new") && <div>
                        <textarea
                            rows="5"
                            cols="50"
                            placeholder="Write your comment here..."
                            value={state_project.issuedetails.comments[state_project.issuedetails.comments.length - 1].body}
                            onChange={e => handleTextareaChange(e, 'story', state_project.issuedetails.key)}
                        />
                        <div className="comment_btn_row"><button className="upload-own-image-button comment_btn_cancel" onClick={() => removeBlankComment(state_project.issuedetails.key, 'story')}>Cancel</button><button className="upload-own-image-button" onClick={e => handleSaveClick(state_project.issuedetails, 'story')}>Save</button></div>
                    </div>}

                    {/* <div className="client-section">
                            <div className="client-row">
                                <div className="client-name">
                                    <p>Client Name:</p>
                                </div>
                                <h6>{state_project.singleprojectdetails[0].clientdetails}</h6>
                            </div>
                            <div className="client-row">
                                <div className="client-name">
                                    <p>Project due date:</p>
                                </div>
                                <h6>{new Intl.DateTimeFormat('en-GB', {
                                    month: 'long',
                                    day: '2-digit',
                                    year: 'numeric',
                                }).format(new Date(state_project.singleprojectdetails[0].projectdeadline))}</h6>
                            </div>
                            <div className="client-row">
                                <div className="client-name">
                                    <p>Estimated Cost:</p>
                                </div>
                                <h6>{state_project.singleprojectdetails[0].projectcost}</h6>
                            </div>
                            <div className="client-row">
                                <div className="client-name">
                                    <p>Project Status</p>
                                </div>
                                <h6>{state_project.singleprojectdetails[0].status}</h6>
                            </div>
                        </div> */}

                    {/* <div className="project-manager-section">
                            <div className="project-manager-title">
                                <h6>Project Manager</h6>
                            </div>
                            <div className="manager-row">
                                <p>{state_project.singleprojectdetails[0].projectmanager}</p>
                            </div>
                        </div> */}

                    {/* <div className="project-manager-section">
                            <div className="project-manager-title">
                            {state_project.singleprojectdetails.files?.length != 0 && <h6>Project Files</h6>}
                            </div>
                            <div className="file-preview">
                                {state_project.singleprojectdetails.files?.length > 0 &&
                                    state_project.singleprojectdetails.files.map((item, index) => {
                                        return (
                                            <div className="file-item" key={index}>
                                                <div className="file-thumbnail">
                                                    <img src={item.contentUrl} alt="" className="file-thumbnail-img" />
                                                </div>
                                                <div className="file-details">
                                                    <p className="file-name">{item.filename}</p>
                                                </div>
                                                <div className="file-actions">
                                                    <a href={item.contentUrl} download={item.filename} className="file-download">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            className="feather feather-download"
                                                        >
                                                            <path d="M21 15v4.5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V15m6-9l6 6-6 6m-6-6h12" />
                                                        </svg>
                                                        Download
                                                    </a>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div> */}


                </div>
                {state_project.issuedetails?.subTasks?.length > 0 && (
                    <>
                        {(state_project.issuedetails?.subTasks || []).map((data, index) => (
                            <div className="task-manager-section" key={`sub-task-list-${index}`}>

                                <div className="task-manager-title" key={`sub-task-key${index}`}>
                                    <h6>Sub Task: {data.key}</h6>

                                </div>
                                <div className="task-manager-title project-row" key={`sub-task-summary${index}`}>
                                    {/* <h6>Story Details</h6> */}
                                    {data.summary && <h6>{data.summary}</h6>}
                                    <div className="image-upload-box1">
                                        {UserEmail === data.assignedTo && <button type="button" className="upload-own-image-button project-row" onClick={() => gofortaskwork(data)} style={{ "width": "11.5rem", "marginLeft": "3px" }}><img src="/images/icons/timer.svg" style={{ "marginRight": "5px" }} />Start Task</button>}
                                    </div>
                                </div>
                                <div className="Completed-section" key={`sub-task-status${index}`}>
                                    {/* <div className={`redio_cercle ${(data.status).toLowerCase() === 'to do' ? 'status-to-do-background-color' :
                                        (data.status).toLowerCase() === 'in progress' ? 'status-in-progress-background-color' :
                                            (data.status).toLowerCase() === 'done' ? 'status-done-background-color' : ''}`}></div>
                                    <p className={`${(data.status).toLowerCase() === 'to do' ? 'status-to-do-text' :
                                        (data.status).toLowerCase() === 'in progress' ? 'status-in-progress-text' :
                                            (data.status).toLowerCase() === 'done' ? 'status-done-text' : ''}`}>{data.issueType} - {data.key}</p> */}
                                    <p className={`project-taskstatus ${(data.status).toLowerCase() === 'to do' ? 'status-to-do-text' :
                                        (data.status).toLowerCase() === 'in progress' ? 'status-in-progress-text' :
                                            (data.status).toLowerCase() === 'done' ? 'status-done-text' : ''}`}>
                                        {/* {data.issueType} */}
                                        {(data.issueType).toLowerCase() === 'story' ? <img src="images/icons/Story.svg" /> : (data.issueType).toLowerCase() === 'task' ? <img src="images/icons/Task.svg" /> : (data.issueType).toLowerCase() === 'sub-task' ? <img src="images/icons/Sub-task.svg" /> : <img src="images/icons/Bug.svg" />} {data.key}</p>
                                </div>
                                <div className="" key={`sub-task-status-key${index}`}>
                                    <div className="sec-center">
                                        <input className="dropdown" value="true" type="checkbox" id={`dropdown-${data.key}`} onClick={(e) => updateStatusboxByKey(state_project.issuedetails, data.key, "Subtask")} name={`status-dropdown-${data.key}`} />
                                        <label className="for-dropdown" htmlFor={`dropdown-${data.key}`}>{data.status}</label>
                                        <div className={`section-dropdown ${data.statusbox === true ? 'dropdown-show' : ''}`} id={`section-dropdown-${data.key}`}>
                                            <p className="status-to-do-text" value='To Do' onClick={() => updateIssueStatus(data, 'sub-task', 'To Do')} >To Do</p>
                                            <p className="status-in-progress-text" value='In Progress' onClick={() => updateIssueStatus(data, 'sub-task', 'In Progress')}>In Progress</p>
                                            <p className="status-done-text" value='Done' onClick={() => updateIssueStatus(data, 'sub-task', 'Done')}>Done</p>
                                        </div>
                                    </div>
                                </div>
                                <div key={`sub-task-details${index}`}>
                                    <div className="project-type-details">
                                        <div className="project-manager-title">
                                            <div className="Completed-section">
                                                <div className="redio_cercle"></div>
                                                {/* <p>{state_project.issueType} - {state_project.key} - {state_project.jiraId}</p> */}
                                            </div>

                                            {/* <h6>Id:- <span>{state_project.issuedetails.key}</span></h6> */}
                                            <div className="project-manager-title">
                                                {state_project.issuedetails.relatedEpics[0]?.summary && <h6>Epic: <span>{state_project.issuedetails.relatedEpics[0].summary}</span></h6>}
                                            </div>
                                            {/* <div className="project-manager-title">
                                    {state_project.issuedetails.summary && <h6>summary: <span>{state_project.issuedetails.summary}</span></h6>}
                                </div> */}
                                            <div className="project-manager-title">
                                                {data.description && <h6>Description: <span>{data.description}</span></h6>}
                                            </div>

                                            <div className="project-manager-title">
                                                {data.storyPoints && <h6>Story Point: <span>{data.storyPoints}</span></h6>}
                                            </div>

                                        </div>
                                    </div>
                                    <>
                                        <div className="project-type-details project-row" style={{ "marginBottom": "10px", "borderBottom": "0" }}>
                                            <div className="project-manager-title col-md-5">
                                                <h6>Assign to</h6>
                                                {/* {(Accessdata.some(item => item.access === "task" && item.type === 'both') || UserEmail === "admin@wrapup.com")  */}
                                                {(data.assignee === `${state_account.profile.firstname} ${state_account.profile.lastname}` || UserEmail === "admin@wrapup.com" || GroupadminAccessdata.find(item => item.access === state_project.selectedprojectdetails?.name && item.type !== "noaccess"))
                                                    && <div className="name-section">
                                                        <select id={`assignTo${data.key}`} type="text" placeholder="user name or email addresss"
                                                            className="project-form-padding project-form-control" name="manufacture_part_number" list="manager" onChange={(e) => dropdownselectUser(e, data, 'sub-task')} value={data?.assignedTo != undefined ? data.assignedTo : "null"}>
                                                            <option value="null" >--Select User--</option>
                                                            {(state_project.projectwiseemaillist || []).map((data, index) => (
                                                                <option value={data.user} key={`User-email-${index}`}>{data.user}</option>
                                                            ))}
                                                        </select>
                                                    </div>}
                                            </div>
                                            {/* </div>
                        <div className="project-type-details"> */}
                                            <div className="project-manager-title col-md-5">
                                                <h6>Due date: {data?.dueDate && <>{new Intl.DateTimeFormat('en-GB', {
                                                    month: 'long',
                                                    day: '2-digit',
                                                    year: 'numeric',
                                                }).format(new Date(data?.dueDate))}</>}</h6>
                                                {/* {(Accessdata.some(item => item.access === "task" && item.type === 'both') || UserEmail === "admin@wrapup.com")  */}
                                                {(data.assignee === `${state_account.profile.firstname} ${state_account.profile.lastname}` || UserEmail === "admin@wrapup.com" || GroupadminAccessdata.find(item => item.access === state_project.selectedprojectdetails?.name && item.type !== "noaccess"))
                                                    &&
                                                    <div className="name-section">
                                                        <input
                                                            type="date" data-date="" data-date-format="DD MMMM YYYY" name={"sub-task"}
                                                            placeholder="Select your task deadline date"
                                                            className="project-form-control"
                                                            onChange={(f) => handleChangetask(f, data)}
                                                            value={data?.dueDate ? data.dueDate : ""}
                                                        />
                                                    </div>}
                                            </div>



                                        </div>
                                        {data.assignedTo && <a href="#" className="project_key_email">
                                            {data.assignedTo}
                                        </a>}

                                        <hr />
                                        <div>
                                            {data?.estimatedtime && <div className="access_projct estimated-box">
                                                <img src="/images/icons/clock-ico.svg" width={'25px'} />
                                                <h5>Estimated time: <span>{data?.estimatedtime}</span></h5>
                                            </div>}
                                            {data?.reference &&
                                                <><div className="related-link-row">
                                                    {data?.reference["Google Links :"] && <div className="estimated-box related-link-box">
                                                        <div className="access_projct"><img src="/images/icons/google-ico.svg" width={'25px'} />
                                                            <h5>Related Google links</h5></div>
                                                        <div className="related-box-links">


                                                            {data.reference["Google Links :"][0]?.length > 0 ? (
                                                                data.reference["Google Links :"][0]?.map((link, index) => (
                                                                    <a key={`GoogleLinkSubtask${index}`} href={link} className="project_key_email">
                                                                        {link}
                                                                    </a>
                                                                ))
                                                            ) : (
                                                                <h6 className="project_key_email" style={{ textAlign: "center" }}>
                                                                    No links available
                                                                </h6>
                                                            )}



                                                        </div>
                                                    </div>}
                                                    {data?.reference["YouTube Links : "] && <div className="estimated-box related-link-box">
                                                        <div className="access_projct"><img src="/images/icons/youtube-ico.svg" width={'25px'} />
                                                            <h5>Related YouTube links</h5></div>
                                                        <div className="related-box-links">

                                                            {data.reference["YouTube Links : "][0]?.length > 0 ? (
                                                                data.reference["YouTube Links : "][0]?.map((link, index) => (
                                                                    <a key={index} href={link} className="project_key_email">
                                                                        {link}
                                                                    </a>
                                                                ))
                                                            ) : (
                                                                <h6 className="project_key_email" style={{ textAlign: "center" }}>
                                                                    No links available
                                                                </h6>
                                                            )}
                                                        </div>
                                                    </div>}
                                                </div>



                                                    {data?.reference["Open AI result: "] && <div className="estimated-box openai-box">
                                                        <div className="access_projct"><img src="/images/icons/openai-ico.svg" width={'25px'} />
                                                            <h5>Related OpenAI result</h5></div>

                                                        <div className="openai-textSection related-box-links">
                                                            <ReactMarkdown>
                                                                {data.reference["Open AI result: "]}
                                                            </ReactMarkdown>
                                                        </div>

                                                    </div>}
                                                </>}
                                        </div>
                                    </>

                                    {data.assignedTo && <div key={`sub-task-assignto${index}`}>
                                        <p>Assigned To:{data.assignedTo}</p>
                                    </div>}
                                    {/* {data.dueDate && <div>
                                        <p>Due Date:{data.dueDate}</p>
                                    </div>} */}
                                    <div className="Assigned-section" key={`sub-task-file-upload${index}`}>

                                        <div className="project-Assigned-title project-row">
                                            <h6>Attachments:</h6>
                                            {(UserEmail === data.assignedTo || UserEmail === "admin@wrapup.com" || GroupadminAccessdata.find(item => item.access === state_project.selectedprojectdetails?.name && item.type !== "noaccess")) && <div className="image-upload-box1">

                                                <input
                                                    name={`sub-task-file-${data.key}`}
                                                    type="file" multiple="multiple"
                                                    // disabled={file.length === 1}
                                                    className="form-control"
                                                    onChange={(e) => uploadFile(e, data, 'sub-task')}
                                                />
                                                <button type="button" className="upload-own-image-button project-row"><img src="/images/icons/attach-file.svg" style={{ "marginRight": "6px" }} />Attach
                                                    files</button>
                                            </div>}
                                        </div>

                                        <div className="attachments_wrapp form-group preview" key={`sub-task-key${index}`}>
                                            {data.files.map((item, index) => {
                                                return (
                                                    <div className="image-upload-box1" key={`Files-${index}`}>
                                                        <div className="img-cercle attach-file-wrapp">
                                                            <img src={item.contentUrl} alt="" className="main-img" />
                                                            <div className="import-img">
                                                                <img src="/images/icons/import-ico.svg" onClick={() => downloadImage(item.contentUrl)} />
                                                            </div>
                                                        </div>
                                                    </div>


                                                    // <div className="image-upload-box1" key={index}>
                                                    //     <div className="img-cercle">
                                                    //         <img src={item.contentUrl} alt="" className="main-img" />
                                                    //     </div>
                                                    // </div>
                                                );
                                            })}
                                        </div>
                                        <hr />
                                    </div>




                                    <div className="project-Assigned-title" key={`sub-task-comment${index}`}>
                                        {/* <h6>Commented on:</h6>
                                        <p>This task comments from other members</p> */}
                                        <div className="form-group preview">
                                            {data.comments.length > 0 &&
                                                data.comments.filter(comment => comment.id !== 'new').map((item, index) => {
                                                    return (<>
                                                        <div key={`Comment-data${index}`}>
                                                            <div className="task-comments">
                                                            <ReactMarkdown className="markdown-text">{item.body}</ReactMarkdown>
                                                                <div className="project-row task-comments-row">
                                                                    <div className="Completed-section">
                                                                        <p>By {item.author}</p>
                                                                    </div>
                                                                    <div className="Completed-section">
                                                                        <div className="redio_cercle"></div>
                                                                        Commented on:<p>{new Intl.DateTimeFormat('en-GB', {
                                                                            month: 'long',
                                                                            day: '2-digit',
                                                                            year: 'numeric',
                                                                        }).format(new Date(item.updated))}</p>
                                                                    </div>
                                                                    {item.commentemotion && <div className="Completed-section ai-review">
                                                                        {item.commentemotion?.Sentiment === "neutral" && <p style={{ "text-transform": "capitalize" }}>😐 {item.commentemotion?.Sentiment}</p>}
                                                                        {item.commentemotion?.Sentiment === "joy" && <p style={{ "text-transform": "capitalize" }}>😃 {item.commentemotion?.Sentiment}</p>}
                                                                        {(item.commentemotion?.Sentiment === "sadness" || item.commentemotion?.Sentiment === "sad") && <p style={{ "text-transform": "capitalize" }}>😔 {item.commentemotion?.Sentiment}</p>}
                                                                        {(item.commentemotion?.Sentiment === "angry" || item.commentemotion?.Sentiment === "anger") && <p style={{ "text-transform": "capitalize" }}>😠 {item.commentemotion?.Sentiment}</p>}
                                                                        {item.commentemotion?.Sentiment === "surprise" && <p style={{ "text-transform": "capitalize" }}>😯 {item.commentemotion?.Sentiment} </p>}
                                                                        {/* <p>😃Joy 😔Sad 😠Angry</p> */}
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </>
                                                    );
                                                })}
                                        </div>
                                    </div>


                                    <hr />

                                    {(data.comments.length == 0 || data.comments[data.comments.length - 1]?.id != "new") && <button className="upload-own-image-button" onClick={() => addBlankComment(data.key, 'sub-task')}>Add Comment</button>}

                                    {(data.comments.length != 0 && data.comments[data.comments.length - 1]?.id == "new") && <div>
                                        <textarea
                                            rows="5"
                                            cols="50"
                                            placeholder="Write your comment here..."
                                            value={data.comments[data.comments.length - 1].body}
                                            onChange={e => handleTextareaChange(e, 'sub-task', data.key)}
                                        />
                                        <div className="comment_btn_row"><button className="upload-own-image-button comment_btn_cancel" onClick={() => removeBlankComment(data.key, 'sub-task')}>Cancel</button><button className="upload-own-image-button" onClick={e => handleSaveClick(data, 'sub-task')}>Save</button></div>
                                    </div>}






                                </div>









                            </div>))}
                    </>)}
                {/* <Createtask /> */}


            </div>)}

        </>

    );
}
export { Projectdetails };