
import axios from "axios"
import { wrapupApi } from '../../config';
import {
  get_group_list_success,
  add_group_success,
  get_single_group_details_success

} from "./groupActions";

export const get_group_list_pagination =
  (Token, payload, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/group/get/paginate/groups`, payload, config)
      .then(res => {

        const value = res.data

        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };

export const create_group =
  (Token, payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    

    axios.post(`${wrapupApi}/api/group/add-group`, payload, config)
      .then(res => {

        const value = res.data
        dispatch(add_group_success(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
        callback({ success: false, data: err  });
      })
  };

export const update_group =
  (Token, payload, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    let resp = axios.post(`${wrapupApi}/api/group/update-group`, payload, config)
      .then(res => {

        // const value = res.data
        // dispatch(update_group_success(value))
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
        callback({ success: false, data: err  });
      })
    return resp;
  };


export const get_group_details =
  (Token, callback) => async (dispatch) => {
    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.get(`${wrapupApi}/api/group/get-groups`, config)
      .then(res => {
        dispatch(get_group_list_success(res.data))
        callback({ success: true, data: res.data });

      })
      .catch(err => {
        console.log(err);
      })
  }

export const get_search_group_list_pagination =
  (Token, payload, callback) => async (dispatch) => {

    const config = {
      headers: { Authorization: `${Token}` }
    };
    axios.post(`${wrapupApi}/api/group/get/paginate/searchgrouplist`, payload, config)
      .then(res => {
        callback({ success: true, data: res.data });
      })
      .catch(err => {
        console.log(err);
      })
  };


