import React, { useState } from 'react';

const DropdownMenu = () => {
    const [selectedProject, setSelectedProject] = useState("Project");
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  
    const projects = ['Project A', 'Project B', 'Project C', 'Project D'];
  
    const toggleDropdown = () => {
        
      setIsDropdownOpen(!isDropdownOpen);
    };
  
    const handleProjectClick = (project) => {
        
      setSelectedProject(project);
      setIsDropdownOpen(false);
    };

  return (
    <div className="dropdown">
      <div className="dropdown-menu" onClick={toggleDropdown}>
        <span className="selected-project">
          {selectedProject || 'Select a project'}
        </span>
        <ul className={`dropdown-content ${isDropdownOpen ? 'open' : ''}`}>
          {projects.map((project, index) => (
            <li key={`Project-Data${index}`} onClick={() => handleProjectClick(project)}>
              {project}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export {DropdownMenu};
