import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { SideBar } from 'components';
import Swal from 'sweetalert2'
import { get_user_profile } from "../../store/account/api";
import { get_single_task, add_comment_for_task, add_status_for_task } from "store/task/api";
import { add_single_task, add_single_comment, update_single_task, add_single_status } from "store/task/taskActions";
import { add_project_success, get_project_details_success, add_single_project } from "store/projects/projectActions"
import { uploadfile } from "store/account/api";
import { Admin, Manager, User } from '../../config';
const Tasks = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const state_account = useSelector(state => state.account);
    const state_project = useSelector(state => state.project);
    const state_task = useSelector(state => state.task);
    const singletaskdata = state_task.singletask;
    const singleprojectdata = state_project.singleproject
    const Token = localStorage.getItem("Token")
    const Getdata = localStorage.getItem("Access")
    let query = useQuery()
    const [taskdetails, settaskdetails] = useState("")
    const data = state_task.comment
    const [currentStatus, setCurrentStatus] = useState("Select task status")

    const changeStatus = (newStatus) => {
        setCurrentStatus(newStatus)

    }

    useEffect(() => {
        if (Token != null) {
            dispatch(get_user_profile(Token))
        }

    }, []);

    const project_name = query.get("project_name")
    const task_name = query.get("task_name")
    const date = query.get("date")
    const task_description = query.get("task_description")
    async function deleteFile(e) {
        const s = data.files.filter((item, index) => index !== e);
        data.files = s
        dispatch(add_single_comment(data))
        console.log(s);
    }

    async function getcomment(e) {
        if (e.target.name == "commentdetails") {
            data.comment = (e.target.value)
            dispatch(add_single_comment(data))
        }
    }
    // async function addstatus() {
    //     ;
    //     const id = query.get("id");
    //     singletaskdata.status = currentStatus
    //     dispatch(add_single_status(singletaskdata))
    //     dispatch(add_single_comment(data))

    //     console.log("Hlw", currentStatus)
    //     const payload = {
    //         id: id,
    //         status: singletaskdata.status
    //     }
    //     dispatch(add_status_for_task(Token, payload, (res => {

    //         if (res.success = true) {
    //             Swal.fire({
    //                 position: 'middle',
    //                 icon: 'success',
    //                 title: 'Your  task status has been saved',
    //                 showConfirmButton: false,
    //                 timer: 1500
    //             })
    //             navigate('/projects')
    //         } else {
    //             alert("Status update error")
    //         }
    //     }

    //     )))
    // }
    async function uploadimage(singlefile) {
        
        const formData = new FormData();
        formData.append(
            "file",
            singlefile
        );

        dispatch(uploadfile(formData, res => {
            console.log(res);
            let filedata = {
                "url": res.data.fileurl,
                "filename": res.data.filename,
                "uploadedby": state_account.profile.email,
                "uploadeddate": new Date()
            }
            data.files.push(filedata)
            dispatch(add_single_comment(data))
        }

        ))
        console.log(data)
    }

    async function uploadFile(e) {
        
        if (e) {
            for (let i = 0; i < e.target.files.length; i++) {
                await uploadimage(e.target.files[i])
            }

        }
    }
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    async function addcomment() {
        
        data.commentby = state_account.profile.email;
        data.commentdate = new Date();
        dispatch(add_single_task(data))
        const id = query.get("id");
        singletaskdata.status = currentStatus
        dispatch(add_single_task(singletaskdata))
        dispatch(add_single_comment(data))
        const payload = {
            id: id,
            data: data,
            status: singletaskdata.status
        }
        dispatch(add_status_for_task(Token, payload, (res => {
            if (res.success = true) {
                // alert("Comment Sucessfully Updated")
                Swal.fire({
                    position: 'middle',
                    icon: 'success',
                    title: 'Your Comment for this task has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
                // dispatch(add_project_success(false))
                // dispatch(get_project_details_success(true))
                // dispatch(update_single_task(false))
                navigate(`/projects`)
                window.location.reload()
            }

        }
        )))
    }

    return (
        <div>
            <div className='dashboard'>
                <div className="dashboard-nav">
                    <SideBar />
                </div>
                <div className='dashboard-app' id="main_section_toggle">
                    {/* <p className='dashboard-toolbar'><a href="#!" className="menu-toggle"><i style={{ "fontSize": "24px" }}
                        className="fa">&#xf0c9;</i></a></p> */}
                    <div className='dashboard-content'>


                        <div className='container'>
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="manager-wrap">
                                        <div className="manager-title">
                                            <p>Project Name: {project_name} </p>
                                            <p>Task Name: {task_name} </p>
                                            {/* <h6>Number of ongoing tasks:</h6> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="padding-section">
                                <div className="row">
                                    <div className="col-md-10">
                                        <div className="project-manager-section-box">
                                            <div className="project-box">
                                                <div className="project-row">
                                                    <div className="project-manager-row">

                                                        <div className="manager-name-section">
                                                            <p>Task Description: {task_description}</p>
                                                            {/* <h6>Assigned by: {singletaskdata.asignedby[0]}</h6> */}
                                                        </div>
                                                    </div>
                                                    <div className="deperment-devoloper">
                                                        <h6>Due date: {new Intl.DateTimeFormat('en-GB', {
                                                            month: 'long',
                                                            day: '2-digit',
                                                            year: 'numeric',
                                                        }).format(new Date(date))}
                                                        </h6>

                                                    </div>
                                                </div>

                                                <div className="project-heading-wrap-of">
                                                    <div className="task-number-of-project">
                                                        <div className="task-arrivals-containar">
                                                            <div className="task-work-progress-section">
                                                                <div className="work-progress-heading">
                                                                    <p>Your work progress:</p>
                                                                    <div className="create-box-section-row">
                                                                    </div>
                                                                    <div className="name-section" value={singletaskdata.status}>
                                                                        <select className="project-form-control" onChange={(event) => changeStatus(event.target.value)} >
                                                                            <option value="0">Select task status</option>
                                                                            <option value="Not Started">Not Started</option>
                                                                            <option value="In Progress">In Progress</option>
                                                                            <option value="In Review">In Review</option>
                                                                            <option value="Complete">Complete</option>
                                                                        </select>
                                                                    </div>
                                                                    {/* <div className="task-name-section">
                                                                        <button type="button"
                                                                            className="upload-own-image-button" data-toggle="dropdown" onClick={() => addstatus()}>
                                                                            Save
                                                                        </button>
                                                                    </div> */}
                                                                </div>


                                                                <div className="form-group preview">
                                                                    {data.files.length > 0 &&
                                                                        data.files.map((item, index) => {
                                                                            return (
                                                                                <div className="image-upload-box1" key={index}>
                                                                                    <div className="img-cercle">
                                                                                        <img src={item.url} alt="" className="main-img" />
                                                                                    </div>
                                                                                    <button type="button" className="upload-own-image-button" onClick={() => deleteFile(index)}>
                                                                                        Delete
                                                                                    </button>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                </div>


                                                                <div className="work-progress-heading">
                                                                    <p>Attachments:</p>
                                                                </div>
                                                                <div className="work-screenshot-box">
                                                                    <div className="image-upload-box1">
                                                                        <input type="file" id="img01" accept=".png, .jpg, .jpeg" onChange={(e) => uploadFile(e)} />
                                                                        <label htmlFor="img01" className="upload-imgboxtask">
                                                                            <button type="button" className="task-own-image-button">
                                                                                <img src="images/task manager/upload.svg.svg" style={{ width: '100%', height: '100%' }} />
                                                                            </button>
                                                                        </label>

                                                                    </div>
                                                                    <div className="upload-task-title">
                                                                        <p>Drag or upload your project files</p>
                                                                    </div>
                                                                </div>
                                                                <div className="task-comment-row">
                                                                    <div className="work-progress-heading">
                                                                        <p>Comments:</p>
                                                                    </div>
                                                                    <div className="comment-create-box-section-row">
                                                                        <div className="task-add-new">
                                                                            {/* <p>Add another</p> */}
                                                                            <img src="images/task manager/active.svg" alt="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="task-name-section">
                                                                    <textarea type="text" placeholder="" name="commentdetails" onChange={(e) => getcomment(e)}></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button type="button" className="upload-own-image-button" onClick={() => addcomment()}>
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export {
    Tasks
}