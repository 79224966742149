import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import $ from "jquery";
import Swal from 'sweetalert2'
import { get_users, get_user_profile } from "../../store/account/api";
import { get_Group_list_pagination } from "store/groups/api";
import { create_event } from "store/task/api"
import { add_group_success, get_group_details_success, add_single_group, create_group_success } from "store/groups/groupActions"
import { get_Group_details } from "store/groups/api";
import { get_project_list_pagination } from "store/projects/api"
import { get_project_list_success } from "store/projects/projectActions"



const Groupdetails = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const state_account = useSelector(state => state.account);
    const state_project = useSelector(state => state.project);

    const [showEventmodal, setShowEventmodal] = useState(false);
    const state_group = useSelector(state => state.group);
    const [email, setEmail] = useState([])
    const [selectemail, setselectEmail] = useState("")
    const Token = localStorage.getItem("Token")
    const [pageno, setpageno] = useState(0)
    const [count, setcount] = useState(20)
    // dispatch(get_user_profile(Token))
    const [openModal, setopenModal] = useState(false)
    // console.log(state_project.projectlist)
    useEffect(() => {
        scrollToTop()
    }, [state_group.singlegroupdetails]);

    useEffect(() => {
        getprojectlist()
        if (Token != null) {
            dispatch(get_user_profile(Token, (res => {
                if (res.success == true) {
                    dispatch(get_users(Token))

                }
            })))
        }
    }, []);

    async function scrollToTop() {
        $("#Groupdetails").animate({ scrollTop: 0 }, "smooth");
    };





    async function editGroup() {
        Swal.fire({
            title: 'Do you want to edit the group Details?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Swal.fire('confirmed!', '', 'success')
                dispatch(add_single_group(state_group.singlegroupdetails))
                dispatch(add_group_success(true))
                dispatch(create_group_success(true))
                dispatch(get_group_details_success(false))
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })


    }
    // async function Openeventmodal() {
    //     setShowEventmodal(true);

    // };





    async function dropdownselectEmployee(f) {
        setselectEmail(f.target.value)

    };
    async function employeeSelection() {
        if (selectemail != null || selectemail != "") {
            if (email.includes(selectemail)) {
            } else {
                setEmail(current => [...current, selectemail]);

            }
            // console.log(email)
            // singlegroupdetails.taskemployee = email
            // dispatch(add_single_Group(singlegroupdetails))
        }
    }
    // console.log(email)

    async function deleatEmployee(e) {

        const s = email.filter((data, index) => index !== e)
        setEmail(s);

        // console.log(s);
    }
    // console.log(state_group.singlegroupdetails)


    async function getprojectlist() {
        const payload = {
            pageno: 0,
            count: 50,
            user: state_account.profile,
            accesstype: "admin"
        }
        dispatch(get_project_list_pagination(Token, payload, (res) => {
            if (res.success == true) {
                let value = res.data
                dispatch(get_project_list_success(value))

            }
        }))
    }



    return (
        <>

            <div className="col-md-7 projectdetails-size" id="Groupdetails" style={{ "overflowY": "auto", "height": "95vh","margin":"0" }}>
                <div>
                    <div className="task-manager-title project-row">
                        <h6>Group Details</h6>
                        <div className="add-new">
                            <p>Edit Group</p>
                            <img onClick={() => editGroup()} src="images/settings/icon_edit.png" alt="" />
                        </div>
                    </div>
                    <div className="">

                        <div className="">
                            <div className="project-manager-title">
                                <h6>Group Name :- <span>{state_group.singlegroupdetails.groupname}</span> </h6>
                            </div>

                            <div className="project-manager-title">
                                <div className="title-project">
                                    <span>Created on: <h6 style={{ "margin": "0" }}>{new Intl.DateTimeFormat('en-GB', {
                                        month: 'long',
                                        day: '2-digit',
                                        year: 'numeric',
                                    }).format(new Date(state_group.singlegroupdetails.updatedAt))}</h6></span>

                                </div>

                                {/* <div className="project-details-wrap-section">

                                </div> */}
                            </div>
                        </div>
                        <hr />
                        <div className="">
                            <div className="project-manager-title">
                                <h6>Group Membars</h6>
                            </div>
                            {state_group.singlegroupdetails.userdetails.map(user => (
                                // <div className="manager-row" >
                                <p key={user._id}>{user.firstname} {user.lastname} - {user.email}</p>
                                // </div>
                            ))}
                        </div>
                        {state_group.singlegroupdetails.groupadmins.length>0 && <div className="">
                            <div className="project-manager-title">
                                <h6>Group Admins</h6>
                            </div>
                            {state_group.singlegroupdetails.groupadmins.map(user => (
                                // <div className="manager-row" >
                                <p key={user}>{user}</p>
                                // </div>
                            ))}
                        </div>}
                        <hr />

                        <div className="project-name" >
                            <p>Module Access</p>
                            {/* <div className="create-box-section-row">
                            </div> */}


                            <table className="access_table">
                                <tbody>
                                    <tr>
                                        <th>Modules</th>
                                        {/* <th>Read</th> */}
                                        <th>Allow Access</th>
                                        <th>No Access</th>
                                    </tr>


                                    <tr>
                                        <td>User</td>
                                        {/* <td>
                                            <input type="radio" value="read" name='users' checked={(state_group.singlegroupdetails.access.some(access => access.access === 'users') && state_group.singlegroupdetails.access.find(item => item.access === 'users').type == "read") ? true : false} />
                                        </td> */}
                                        <td>
                                            <input type="radio" disabled="true" value="both" name='users' checked={(state_group.singlegroupdetails.access.some(access => access.access === 'users') && state_group.singlegroupdetails.access.find(item => item.access === 'users').type == "both") ? true : false} />
                                        </td>
                                        <td>
                                            <input type="radio" disabled="true" value="noaccess" name='users' checked={(state_group.singlegroupdetails.access.some(access => access.access === 'users') && state_group.singlegroupdetails.access.find(item => item.access === 'users').type == "noaccess") ? true : false} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Group</td>
                                        {/* <td>
                                            <input type="radio" value="read" name='groups' checked={(state_group.singlegroupdetails.access.some(access => access.access === 'groups') && state_group.singlegroupdetails.access.find(item => item.access === 'groups').type == "read") ? true : false} />
                                        </td> */}
                                        <td>
                                            <input type="radio" disabled="true" value="both" name='groups' checked={(state_group.singlegroupdetails.access.some(access => access.access === 'groups') && state_group.singlegroupdetails.access.find(item => item.access === 'groups').type == "both") ? true : false} />
                                        </td>
                                        <td>
                                            <input type="radio" disabled="true" value="noaccess" name='groups' checked={(state_group.singlegroupdetails.access.some(access => access.access === 'groups') && state_group.singlegroupdetails.access.find(item => item.access === 'groups').type == "noaccess") ? true : false} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Assign Task </td>
                                        {/* <td>
                                            <input type="radio" value="read" name='task' checked={(state_group.singlegroupdetails.access.some(access => access.access === 'task') && state_group.singlegroupdetails.access.find(item => item.access === 'task').type == "read") ? true : false} />
                                        </td> */}
                                        <td>
                                            <input type="radio" disabled="true" value="both" name='task' checked={(state_group.singlegroupdetails.access.some(access => access.access === 'task') && state_group.singlegroupdetails.access.find(item => item.access === 'task').type == "both") ? true : false} />
                                        </td>
                                        <td>
                                            <input type="radio" disabled="true" value="noaccess" name='task' checked={(state_group.singlegroupdetails.access.some(access => access.access === 'task') && state_group.singlegroupdetails.access.find(item => item.access === 'task').type == "noaccess") ? true : false} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>




                        </div>

                        <div className="project_access_main">
                            <div className="access_projct">
                                <div><input type="checkbox" checked={(state_group.singlegroupdetails.access.some(access => access.access === "projects") && state_group.singlegroupdetails.access.find(item => item.access === "projects").type == "both") ? true : false} disabled="true" /></div>
                                <p style={{ "marginBottom": "0" }}>Select Project Access</p>
                            </div>



                            {(state_group.singlegroupdetails.access.some(access => access.access === "projects") && state_group.singlegroupdetails.access.find(item => item.access === "projects").type == "both") && <table className="access_table">
                                <tbody>
                                    <tr>
                                        <th>Project Name</th>
                                        {/* <th>Read</th> */}
                                        <th>Allow Access</th>
                                        <th>No Access</th>
                                    </tr>

                                    {(state_project.projectlist || []).map((data, index) => (
                                        <tr key={`Projects${index}`}>
                                            <td>{data.name}</td>
                                            {/* <td>
                                                    <input type="radio" value="read" name={data.name} onChange={(e) => updateaccessvalue(data.name, e)} checked={(singlegroupdata.access.some(access => access.access === data.name) && singlegroupdata.access.find(item => item.access === data.name).type == "read") ? true : false} />
                                                </td> */}
                                            <td>
                                                <input disabled={true} type="radio" value="both" name={data.name} checked={(state_group.singlegroupdetails.access.some(access => access.access === data.name) && state_group.singlegroupdetails.access.find(item => item.access === data.name).type == "both") ? true : false} />
                                            </td>
                                            <td>
                                                <input disabled={true} type="radio" value="noaccess" name={data.name} checked={(state_group.singlegroupdetails.access.some(access => access.access === data.name) && state_group.singlegroupdetails.access.find(item => item.access === data.name).type == "noaccess") ? true : false} />
                                            </td>
                                        </tr>))}
                                    {/* <tr>
                                                <td>Jesssu</td>
                                                <td>
                                                    <input type="radio" />
                                                </td>
                                                <td>
                                                    <input type="radio" />
                                                </td>
                                                <td>
                                                    <input type="radio" />
                                                </td>
                                            </tr> */}

                                </tbody>
                            </table>}

                        </div>

                    </div>

                </div>
            </div>




        </>

    );
}
export { Groupdetails };