import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from 'react-router-dom';
import $ from "jquery";
import { get_user_profile } from "../../store/account/api";
import { selectproejctforaccess } from "../../store/projects/projectActions";
import { get_access_list_pagination, get_search_access_list_pagination } from "store/access/api";
import { add_access_success, search_access, get_access_details_success, get_access_list_success, get_single_access_details_success, view_access_id, add_single_access, search_access_list_success } from "store/access/accessActions";
import { get_access_details } from "store/access/api";
import { green } from "@material-ui/core/colors";
const AccessList = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const state_account = useSelector(state => state.account);
    const state_access = useSelector(state => state.access);
    const state_project = useSelector(state => state.project);

    const Token = localStorage.getItem("Token")
    const [pageno, setpageno] = useState(0)
    // const accesss = state_access.accesslist
    const [access, setaccess] = useState([])
    const [count, setcount] = useState(5)
    const { id } = useParams();
    const newaccess = {
        accessname: "",
        users: [],
    }

    useEffect(() => {

        if (Token != null) {
            dispatch(get_user_profile(Token, (res => {
                if (res.success == true) {
                    getaccesslistpagination(pageno)
                    getaccessdetails()
                    scrollFunction()
                }
            })))
        }


    }, []);



    async function getaccesslistpagination(data) {
        // const payload = {
        //     pageno: data,
        //     count: count,
        //     user: state_account.profile
        // }
        // dispatch(get_access_list_pagination(Token, payload, (res) => {
        //     if (res.success == true) {
        //         let value = res.data
        //         value.forEach(i => {
        //             accesss.push(i)
        //         })
        //         get_access_list_success(accesss)
        //         setaccess(accesss)
        //     }
        // }))
    }
    async function scrollFunction() {

        if ((window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 400)) {
            setpageno(pageno + 1)
            getaccesslistpagination(pageno + 1)
        }
    }
    async function openaccessform() {

        dispatch(add_access_success(true))
        dispatch(get_access_details_success(false))
        navigate('/access')
    }

    async function getaccessdetails() {
        dispatch(get_access_details())
        dispatch(add_access_success(false))
        dispatch(get_access_details_success(false))
        navigate(`/access`)

    }
    async function getaccessdata(data) {

        console.log(data)
        dispatch(get_single_access_details_success(data))
        dispatch(get_access_details())
        dispatch(add_access_success(false))
        dispatch(get_access_details_success(true))

    }

    async function selectProjectforaccess(data) {
        dispatch(add_access_success(false))
        dispatch(get_access_details_success(true))
        dispatch(selectproejctforaccess(data))

    }


    async function getaccesslistpaginationbysearch(data, searchvalue) {
        const payload = {
            pageno: data,
            count: 1000,
            user: state_account.profile,
            search: searchvalue
        }
        let searchaccesss = []
        dispatch(get_search_access_list_pagination(Token, payload, (res) => {
            if (res.success == true) {
                let value = res.data
                value.forEach(i => {
                    searchaccesss.push(i)
                })
                dispatch(search_access_list_success(searchaccesss))
            }
        }))
    }
    console.log("Hi", state_access.accesslist)
    return (
        <>
            <div className="col-md-4" >
                <div className="access-heading" >
                    <div className="task-access-row">
                        <div className="col-md-3">
                            <div className="access-heading-wrap">
                                <p>Access</p>
                            </div>
                        </div>
                        <div className="col-md-2">

                        </div>
                        <div className="col-md-5">
                            <div className="access-manager-name">
                                <h6>{state_account.profile.firstname} {state_account.profile.lastname}</h6>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="manager-img">
                                {state_account.profile.profileimage != "" ? (<><img className="profile-photo-image" src={state_account.profile.profileimage} /></>) : (
                                    <><img className="profile-photo-image" src="images/settings/avatar.png" /></>
                                )}

                            </div>
                        </div>
                    </div>
                    <div className="create-box-section-group-row">
                        <div className="add-new-group-row" onClick={() => openaccessform()}>
                            <p>Create new access</p>
                            <img src="images/task manager/active.svg" alt="" />
                        </div>
                    </div>
                    <div className="search-section">
                        <input type="text" name="search" placeholder="Search access.."
                            className="search-form-control" />

                    </div>
                    <div className="project-section">
                        {(state_access.accesslist || []).map((data, index) => (

                            <div className="project-title-box-completed" key={index}
                            >
                                <div className="project-title-row" onClick={() => getaccessdata(data)}>
                                    <div className="title-project">
                                        <p>
                                            {data.grouptype}
                                            {data.screens.map((screen, index) => (
                                                <span key={index}>{screen.access}:- {screen.type}</span>
                                            ))}
                                        </p>
                                    </div>

                                    <div className="Completed-section">
                                        <div className="redio_cercle">

                                        </div>
                                        <p>{new Intl.DateTimeFormat('en-GB', {
                                            month: 'long',
                                            day: '2-digit',
                                            year: 'numeric',
                                        }).format(new Date(data.updatedAt))}</p>
                                    </div>
                                </div>
                                <div className="project-details-wrap" >
                                    <p>{data.groups} </p>
                                </div>

                                {data.userdetails.map(user => (
                                    <div className="project-details-wrap" key={user._id}>
                                        <p>{user.firstname} {user.lastname}</p>
                                    </div>
                                ))}
                            </div>))}

                        {/* {(state_project.projectlist || []).map((data, index) => (

                            <div className="project-title-box-completed" key={index} style={data.key === state_project.selectedprojectdetails?.key ? { border: "2px solid #42B847" } : {}}
                            >
                                <div className="project-title-row" onClick={() => selectProjectforaccess(data)}>
                                    <div className="title-project">
                                        <p> {data.key} - {data.name}

                                        </p>

                                    </div>

                                    <div className="Completed-section">
                                        <div className="redio_cercle">

                                        </div>

                                    </div>
                                </div>
                                <div className="project-details-wrap" >
                                    <p>{data.projectTypeKey} </p>
                                </div>
                                <div className="project-details-wrap" >
                                    <p>{(data.assignedTo || []).length} users </p>
                                </div>
                            </div>))} */}
                    </div>


                </div>

            </div>


        </>


    );
}
export { AccessList };