import { SideBar } from 'components'
import React from 'react'
import { useEffect } from 'react'
import { get_managers } from 'store/account/api';
import { useDispatch, useSelector } from 'react-redux';
import { get_user_profile } from "../../store/account/api";
import { add_project_success, search_project, get_project_details_success, get_project_list_success, get_single_project_details_success, view_project_id, add_single_project, search_project_list_success } from "store/projects/projectActions";
import { useNavigate } from 'react-router-dom';
import { get_project_details } from "store/projects/api";
import { Admin, Manager, User } from '../../config';

const Managers = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const state_project = useSelector(state => state.project);
    const Token = localStorage.getItem("Token");
    const Getdata = localStorage.getItem("Access")
    const state_account = useSelector(state => state.account);

    useEffect(() => {

        if (Token != null) {
            dispatch(get_user_profile(Token, (res => {
                dispatch(get_managers(Token))

            })))
            if (Getdata.includes(`${Admin}`)) {
            } else {
                navigate('/');
            }

        }
    }, [])

    console.log(state_account.managers)

    async function getprojectdetails(projectid, projectname) {
        const payload = {

            projectid: projectid
        }
        navigate(`/projects`)
        dispatch(view_project_id(projectid))
        dispatch(get_project_details(payload))
    }
    console.log(state_account.managers)
    return (
        <div>
            <div className='dashboard'>
                <SideBar />
                <div className='dashboard-app' id="main_section_toggle">
                    {/* <p className='dashboard-toolbar'><a href="#!" className="menu-toggle"><i style={{ "fontSize": "24px" }}
                        className="fa">&#xf0c9;</i></a></p> */}
                    <div className='dashboard-content'>
                        <div className='container'>
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="manager-wrap">
                                        <div className="manager-title">
                                            <p>Project Managers</p>
                                            <h6>Number of managers: {state_account.managers.length}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(state_account.managers || []).map((data, index) => (
                                <div className="padding-section">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <div className="project-manager-section-box">
                                                <div className="project-box">
                                                    <div className="project-row">
                                                        <div className="project-manager-row">
                                                            <div className="profile-img-container">
                                                                {/* <div className="profile-img" style={{ "padding": "2px" }}> */}
                                                                <img src={data.user.profileimage} alt="" className="avatar-img" />
                                                                {/* </div> */}
                                                            </div>
                                                            <div className="manager-name-section">
                                                                <p>{data.user.firstname} {data.user.lastname}</p>
                                                            </div>
                                                        </div>
                                                        <div className="deperment-devoloper">
                                                            <h6>Manager</h6>
                                                        </div>
                                                    </div>
                                                    <div className="project-heading-wrap-of">
                                                        <div className="heading-title">
                                                            <h6>Projects</h6>
                                                        </div>

                                                        <div className="number-of-project">
                                                            <div className="row">
                                                                <div className="new-arrivals-containar">
                                                                    {(data.usertasks || []).map((projects, index) => (
                                                                        <div className="new-look-img-section" key={index}>
                                                                            <div className="project-manager-box-completed">
                                                                                <div className="project-manager-row">
                                                                                    <div className="manager-project" onClick={() => getprojectdetails(projects.projectid)}>
                                                                                        <p>{projects.projectname}</p>
                                                                                        <div className="due-date">
                                                                                            <h6>{new Intl.DateTimeFormat('en-GB', {
                                                                                                month: 'long',
                                                                                                day: '2-digit',
                                                                                                year: 'numeric',
                                                                                            }).format(new Date(projects.updatedAt))}</h6>
                                                                                        </div>
                                                                                        <div className="description-section" onClick={() => getprojectdetails(projects.projectid)}>
                                                                                            <h5>Description: {projects.projectdescription}</h5>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            ))}


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {
    Managers
}