import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    reset_password,
} from "../../store/account/accountActions";
import Swal from 'sweetalert2'
import { wrapup_users_resetpassword } from "../../store/account/api"
import { EyeFill } from 'react-bootstrap-icons';
import { Admin, Manager, User} from '../../config';
import { get_user_profile } from "../../store/account/api";



const Newpassward = () => {
    const [passwordFieldType, setPasswordFieldType] = useState("password")
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const state_account = useSelector(state => state.account);
    const resetPassworddata = state_account.profile
    const Getdata = localStorage.getItem("Access")
    const Token = localStorage.getItem("Token");
    let query = useQuery()

    useEffect(() => {
        if (Token != null) {
            dispatch(get_user_profile(Token, (res => {

            })))
            if (Getdata.includes(`${Admin}`) || Getdata.includes(`${Manager}`) || Getdata.includes(`${User}`)) {
            } else {
                navigate('/');
            }
        }
    }, [])

    async function handleChange(e) {

        if (e.target.name == "password") {
            resetPassworddata.password = (e.target.value)
            dispatch(reset_password(resetPassworddata))
        }
        else if (e.target.name == "confirmpassword") {
            resetPassworddata.confirmpassword = (e.target.value)
            dispatch(reset_password(resetPassworddata))
        }
        console.log(resetPassworddata)
    };
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    async function resetpassword() {
        state_account.email = query.get("email");
        const payload = {
            email: state_account.email,
            newPassword: resetPassworddata.password,
            confirmPassword: resetPassworddata.confirmpassword
        }
        if(resetPassworddata.password===resetPassworddata.confirmpassword){
        dispatch(wrapup_users_resetpassword(payload, (res => {
            if (res.success == true) {
                Swal.fire({
                    position: 'middle',
                    icon: 'success',
                    title: 'Your Password Update Sucessfully.',
                    showConfirmButton: false,
                    timer: 1500
                })
                navigate("/")
            }
            else {
                console.log("Invalid response from API");
            }
        })))}
        else{
            Swal.fire({
                confirmButtonColor: "#144615",
                title: "Error",
                text: `Password not matched!`,
                icon: "error",
            })
        }

    }







    return (
        <div>
            <div className="login-section-area">
                <div className="signup-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                                <div className="login-box ">
                                    <div className="logo-section">
                                        <img src="images/task manager/wrapup logo.svg" alt="" />
                                    </div>
                                    <div className="login-heading">
                                        <p>New Password</p>

                                    </div>
                                    <div className="login-information-section">




                                        <div className="name-section">
                                            <h3>password</h3>
                                            <p style={{ position: "relative" }}><input type={passwordFieldType} placeholder="Password" className="product-form-control"
                                                name="password" onChange={(e) => handleChange(e)} />
                                                <EyeFill style={{ position: "absolute", "margin-top": "-25px", "marginLeft": "250px" }} onClick={(e) => { (passwordFieldType == "password") ? setPasswordFieldType("text") : setPasswordFieldType("password") }} />
                                            </p>
                                        </div>
                                        <div className="name-section">
                                            <h3>Conform Password</h3>
                                            <p style={{ position: "relative" }}><input type={passwordFieldType} placeholder="Password" className="product-form-control"
                                                name="confirmpassword" onChange={(e) => handleChange(e)} />
                                                <EyeFill style={{ position: "absolute", "margin-top": "-25px", "marginLeft": "250px" }} onClick={(e) => { (passwordFieldType == "password") ? setPasswordFieldType("text") : setPasswordFieldType("password") }} />
                                            </p>
                                        </div>
                                        <div className="sale-feed-action">
                                            <button type="button" className="sale-action-link" value="Submit" onClick={() => { resetpassword() }}>Save</button>
                                        </div>
                                        <div className="connect-login">
                                            <a href="/">Back to log in </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export {
    Newpassward
}
