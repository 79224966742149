import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    forgot_password,
} from "../../store/account/accountActions";
import Swal from 'sweetalert2'
import { wrapup_users_forgotpassword, wrapup_users_provider_checking } from "../../store/account/api"
import { get_user_profile } from "../../store/account/api";
import { Admin, Manager, User } from '../../config';

const Forgotpassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const state_account = useSelector(state => state.account);
    const [name, setName] = useState("");
    const Getdata = localStorage.getItem("Access")
    const Token = localStorage.getItem("Token");
    const value = state_account.status

    const forgotpassworddata = state_account.profile
    useEffect(() => {
        if (Token != null) {
            dispatch(get_user_profile(Token, (res => {

            })))
            if (Getdata.includes(`${Admin}`) || Getdata.includes(`${Manager}`) || Getdata.includes(`${User}`)) {
            } else {
                navigate('/');
            }
        }

    }, [])



    async function handleChange(e) {

        setName(e.target.value);
        forgotpassworddata.email = (e.target.value)
        dispatch(forgot_password(forgotpassworddata))
    }

    async function userforgotpassword() {
        
        const payload = {
            email: forgotpassworddata.email
        }
        dispatch(wrapup_users_provider_checking(payload, (res => {
            if (res.data === true) {
                const payload = {
                    email: forgotpassworddata.email
                }
                dispatch(wrapup_users_forgotpassword(payload, (res => {
                    if (res.success == true) {
                        Swal.fire({
                            position: 'middle',
                            icon: 'success',
                            title: 'Your Reset Password Otp send to you email.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        navigate("/verification?email=" + payload.email)
                    }
                    else {
                        console.log("Invalid response from API");
                    }
                })))
            } else  {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Reset password is only available for email registration users.',

                })
            }
        })))


    }






    return (
        <div>
            <div className="login-wrap">
                <div className="container">
                    <div className="row">
                        <div className="login-box-main">
                            <div className="login-box">
                                <div className="logo-section">
                                    <img src="images/task manager/wrapup logo.svg" alt="" />
                                </div>
                                <div className="login-heading">
                                    <p>Forgot password</p>
                                    <h6>Enter email address</h6>
                                </div>
                                <div className="login-information-section">

                                    <div className="name-section">
                                        <h3>Email</h3>
                                        <input type="text" placeholder="Email addresss" className="product-form-control"
                                            name="email" onChange={handleChange}
                                            value={name} />
                                    </div>
                                    <div className="connect-login">
                                        <a href="/">Back to log in </a>
                                    </div>
                                    <div className="sale-feed-action">
                                        <button type="button" className="sale-action-link" value="Submit" onClick={() => userforgotpassword()}>Send</button>
                                    </div>
                                    {/* <div className="account-sign">
                                        <span>Don’t have an account?</span><strong> Sign up</strong>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export {
    Forgotpassword
}