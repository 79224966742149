import React from "react";
import { Routes, Route } from "react-router-dom";

import {
  Login,
  Signup,
  Projects,
  Users,
  Managers,
  Tasks,
  Settings,
  Forgotpassword,
  Newpassward,
  Verification,
  Notification,
  Timer,
  Access,
  Group,
  Onboarding1,
  Onboarding2,
  Onboarding3,
  Onboarding4,
  Onboarding5

} from 'pages'

import {

} from 'components'


import "@fortawesome/fontawesome-free/css/all.min.css";
// import "@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css";

const RenderRoutes = () => {

  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/onboarding1" element={<Onboarding1 />} />
      <Route exact path="/onboarding2" element={<Onboarding2 />} />
      <Route exact path="/onboarding3" element={<Onboarding3 />} />
      <Route exact path="/onboarding4" element={<Onboarding4 />} />
      <Route exact path="/onboarding5" element={<Onboarding5 />} />
      <Route exact path="/signup"  element={<Signup />} />
      <Route exact path="/projects" element={<Projects />}/>
      <Route exact path="/users" element={ <Users />} />
      <Route exact path="/managers" element={<Managers />}/>
      <Route exact path="/tasks" element={<Tasks />}/>
      <Route exact path="/timer" element={<Timer />}/>
      <Route exact path="/settings" element={<Settings />}/>
      <Route exact path="/forgotpassward" element={<Forgotpassword />}/>
      <Route exact path="/newpassward" element={<Newpassward />}/>
      <Route exact path="/verification" element={<Verification />}/>
      <Route exact path="/notification" element={<Notification />}/>
      <Route exact path="/access" element={<Access />}/>
      <Route exact path="/groups" element={<Group />}/>

    </Routes>
  );
};

export default RenderRoutes;