import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';


const firebaseConfig = {
  apiKey: "AIzaSyBJp_38g0b6FX0W9qgZhRFdXMiuog1Vr30",
  authDomain: "wrapup-dev.firebaseapp.com",
  projectId: "wrapup-dev",
  storageBucket: "wrapup-dev.appspot.com",
  messagingSenderId: "452977571075",
  appId: "1:452977571075:web:3c9471a52ad741861ce536",
  measurementId: "G-QH49JZB0W7"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {vapidKey: "BOUPoR1vJG_fOhyG7nIjp_xwkHPJ2u6qqe1ev4CN-as2gLKeTNeP7_xj4pUTe1HpdFvbaGOxaDMs6QRghUuPnck"});
    if (currentToken) {
      return currentToken;
    } else {
      // Show permission request UI
      // console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log("payload", payload)
      resolve(payload);
    });
  });