import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2'
import { Admin, Manager, User } from '../../config';
import { get_user_access_success } from "../../store/access/accessActions";
import { selectproejctforaccess, get_project_list_success } from "../../store/projects/projectActions";
import { get_notification_count_action } from "store/account/accountActions";
import { get_user_profile, get_users_notification, get_users_notification_count, users_notification, users_notification_seen } from "../../store/account/api";
import { get_issue_list_for_jira_project, get_project_list_pagination } from "store/projects/api"
import { WebSocketComponent } from "../index"
import { AdminNavItems } from "../index";
import { CommonNavItems } from "../index";
import { UserNavItems } from "components/Usersidebar";


const SideBar = () => {
    const dispatch = useDispatch()
    const state_account = useSelector(state => state.account);
    const [showSidebar, setShowSidebar] = useState(false);
    const Token = localStorage.getItem("Token")




    function sidebartoggle() {
        setShowSidebar(!showSidebar);
        var element = document.getElementById("main_section_toggle");
        element.classList.toggle("add-section");
        var element = document.getElementById("menu-btn");
        element.classList.toggle("manu-bar-fixed");
        // var element = document.getElementById("menu-btn");
        // element.classList.toggle("manu-bar-fixed");
    }




    return (
        <>
            <WebSocketComponent />
            <div className={showSidebar ? 'showSidebar dashboard-nav' : 'dashboard-nav'}
            // style={{ display: showSidebar ? "none" : "block"}}
            >
                <header><a href="#" className="menu-toggle" onClick={sidebartoggle}><i style={{ "fontSize": "24px" }} className="fa">&#xf0c9;</i></a>
                    <a href="#" className="brand-logo">
                        <img src="/images/task manager/wrapup logo.svg" alt="" className="main-logo" />
                    </a>
                </header>
                <nav className="dashboard-nav-list">
                    {state_account.profile.email === "admin@wrapup.com" ? (
                        <nav className="dashboard-nav-list">
                            <AdminNavItems />
                            <CommonNavItems />
                        </nav>
                    ) : (
                        <nav className="dashboard-nav-list">
                            <UserNavItems />
                            <CommonNavItems />
                        </nav>
                    )}

                </nav>



            </div>

            <div className="dashboard-toolbar" id="menu-btn">
                <a href="#" className="text-dark" onClick={sidebartoggle}>
                    <img src="/images/icons/menu-ico.svg" width={"20px"} />
                </a>
            </div>

        </>

    );
}
export { SideBar };