import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import $ from "jquery";
import { get_users, get_user_profile } from "../../store/account/api";
import { get_group_details } from "../../store/groups/api";
import { add_single_access, add_access_success, get_access_details_success, view_access_id, get_access_list_success, create_access_success } from "store/access/accessActions"
import { create_access, update_access, get_access_details, get_access_list_pagination } from "../../store/access/api"
import { assignprojectaccess, get_project_list_pagination } from "../../store/projects/api"
import { selectproejctforaccess, get_project_list_success } from "../../store/projects/projectActions"

const Createaccess = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const state_account = useSelector(state => state.account);
    const state_group = useSelector(state => state.group);
    const state_access = useSelector(state => state.access);
    const state_project = useSelector(state => state.project);

    const state_task = useSelector(state => state.task);
    const Token = localStorage.getItem("Token")
    const [showNotification, setShowNotification] = useState(false);
    const [showNotificationMessage, setShowNotificationMessage] = useState(false);
    const [createAccessuccess, setcreateAccessuccess] = useState(false)
    const singleaccessdata = state_access.singleaccess;
    const accessdata = state_access.Is_created;
    const singletaskdata = state_task.singletask;
    const [selectmail, setselectmail] = useState("")
    const [selectgroup, setselectgroup] = useState("")
    const [cost, setCost] = useState("")
    const [selectemail, setselectEmail] = useState("")
    let mail = state_project.selectedprojectdetails.assignedTo || []
    let group = singleaccessdata.groups
    const [selectedusers, setselectedusers] = useState(state_project.selectedprojectdetails?.assignedTo || [])
    const [email, setEmail] = useState([])
    const [file, setFile] = useState([])
    const [pageno, setpageno] = useState(0)
    let Access = [];
    const [count, setcount] = useState(20)
    const [currentStatus, setCurrentStatus] = useState("")
    const [accessvalue, setaccessvalue] = useState("");
    const [subAccess, setSubAccess] = useState([]);
    const [subAccesstype, setsubAccesstype] = useState([])
    const [isWriteAccessavailable, setisWriteAccessavailable] = useState(false)

    const changeStatus = (newStatus) => {
        setCurrentStatus(newStatus)

    }
    console.log(singleaccessdata)



    useEffect(() => {
        if (Token != null) {
            // dispatch(get_user_profile(Token, (res => {
            //     if (res.success == true) {
            dispatch(get_users(Token))
            dispatch(get_group_details(Token))
            //     }
            // })))
        }


    }, []);


    async function getaccesslistpagination(data) {
        const payload = {
            pageno: data,
            count: count,
            user: state_account.profile
        }
        dispatch(get_access_list_pagination(Token, payload, (res) => {
            if (res.success == true) {
                let value = res.data
                value.forEach(i => {
                    Access.push(i)
                    // setAccess(current => [...current, i])
                })
                get_access_list_success(Access)
            }
        }))
    }



    async function createaccess() {
        
        dispatch(add_single_access(singleaccessdata))
        if (accessdata == false) {
            dispatch(add_single_access(singleaccessdata))


            dispatch(create_access(Token, singleaccessdata, (res => {

                if (res.success == true) {
                    Swal.fire({
                        title: 'Do you want to create the access In Wrap-up?',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            Swal.fire('Confirmed!', '', 'success')
                            Swal.fire({
                                position: 'middle',
                                icon: 'success',
                                title: 'Your access has been successfully created in Wrap-up',
                                showConfirmButton: false,
                                timer: 1500
                            })
                            dispatch(create_access_success(true))
                            dispatch(get_access_details())
                            dispatch(add_access_success(false))
                        } else if (result.isDenied) {
                            Swal.fire({
                                title: 'Changes are not saved',
                                text: 'Do you want to continue?',
                                icon: 'info',
                                showCancelButton: true,
                                confirmButtonText: 'Yes',
                                cancelButtonText: 'Cancel'
                            }).then((result) => {
                                if (result.isDismissed) {
                                    dispatch(get_access_details())
                                    dispatch(add_access_success(false))
                                    navigate('/access');
                                }
                            });
                        }

                    })
                }
                else {
                    console.log("Invalid response from API");
                }
            })))
        }
        else {
            dispatch(add_single_access(singleaccessdata))
            singleaccessdata.accessid = state_access.singleaccessdetails._id
            dispatch(add_single_access(singleaccessdata))
            dispatch(update_access(Token, singleaccessdata, (res => {
                if (res.success == true) {
                    window.location.reload();
                    Swal.fire({
                        position: 'middle',
                        icon: 'success',
                        title: 'Your access details sucessfull updated in Wrap-up',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    const payload = {
                        accessid: singleaccessdata.accessid
                    }
                    getaccesslistpagination(pageno)
                    dispatch(get_access_details(payload))
                    dispatch(add_access_success(false))
                    dispatch(get_access_details_success(true))
                    dispatch(view_access_id(singleaccessdata.accessid))
                }
                else {
                    console.log("Invalid response from API");
                }
            })))
        }
    }

    async function getprojectlist() {
        const payload = {
            pageno: 0,
            count: 50,
            user: state_account.profile,
            accesstype: "admin"
        }
        dispatch(get_project_list_pagination(Token, payload, (res) => {
            if (res.success == true) {
                let value = res.data
                dispatch(get_project_list_success(value))
                // dispatch(add_access_success(false))
                // dispatch(get_access_details_success(true))
            }
        }))
    }

    async function updateaccess() {
        
        const payload = {
            key: state_project.selectedprojectdetails.key,
            assignedTo: state_project.selectedprojectdetails.assignedTo
        }

        dispatch(assignprojectaccess(Token, payload, (res) => {
            if (res.success == true) {
                dispatch(add_access_success(false))
                dispatch(create_access_success(false))
                

                // dispatch(get_access_details_success(false))
                // navigate('/access');

            }
        }))
        // assignprojectaccess
    }

    async function dropdownselectUser(e) {
        setselectmail(e.target.value)
    };
    async function userSelection() {
        if (selectmail != "null" || selectmail != "") {
            if (mail.includes(selectmail)) {
            } else {
                mail.push(selectmail)
            }
            console.log(mail)
            singleaccessdata.users = mail
            dispatch(add_single_access(singleaccessdata))



            let data = { ...state_project.selectedprojectdetails }
            data.assignedTo = mail
            setselectedusers(mail)
            dispatch(selectproejctforaccess(data))

        }
    }
    async function dropdownselectGroup(e) {
        setselectgroup(e.target.value)
    };
    async function groupSelection() {

        if (selectgroup != "null" || selectgroup != "") {
            if (group.includes(selectgroup)) {

            } else {
                group.push(selectgroup)

            }
            console.log(group)
            singleaccessdata.groups = group
            dispatch(add_single_access(singleaccessdata))
        }
    }






    async function deleatUser(e) {

        const m = mail.filter((data, index) => index !== e)
        mail = m
        let data = { ...state_project.selectedprojectdetails }
        data.assignedTo = mail
        setselectedusers(mail)
        dispatch(selectproejctforaccess(data))
        // console.log(m);
        singleaccessdata.users = mail
        dispatch(add_single_access(singleaccessdata))

    }
    async function deleatGroup(e) {

        const m = group.filter((data, index) => index !== e)
        group = m
        // console.log(m);
        singleaccessdata.groups = group
        dispatch(add_single_access(singleaccessdata))

    }

    const handleChange = (e) => {
        
        if (e.target.name == "selective-val[]") {
            const newCheckboxValues = [...subAccess];
            const newaccesstypes = [...subAccesstype];
            if (e.target.checked) {
                newCheckboxValues.push(e.target.value);
                newaccesstypes.push({ "access": e.target.value, "type": "read" })
                setsubAccesstype(newaccesstypes)
                singleaccessdata.screens = newaccesstypes
                dispatch(add_single_access(singleaccessdata));
            } else {
                newCheckboxValues.splice(newCheckboxValues.indexOf(e.target.value), 1);
                const updatedaccess = newaccesstypes.filter(access => access.access !== e.target.value);
                setsubAccesstype(updatedaccess);
                singleaccessdata.screens = updatedaccess
                dispatch(add_single_access(singleaccessdata));
            }
            setSubAccess(newCheckboxValues);
        }
    };

    const getaccessval = (event) => {
        
        var getaccessValue = event.target.value
        if (getaccessValue == "Admin") {
            setaccessvalue(getaccessValue)
            setSubAccess(["Admin"])
            setsubAccesstype([{ "access": "Admin", "type": "Admin Read" }])
            singleaccessdata.grouptype = getaccessValue
            dispatch(add_single_access(singleaccessdata));

        } if (getaccessValue == "Manager") {
            setaccessvalue(getaccessValue)
            setSubAccess(["Manager"])
            setsubAccesstype([{ "access": "Manager", "type": "Manager Read" }])
            singleaccessdata.grouptype = getaccessValue
            dispatch(add_single_access(singleaccessdata));

        } if (getaccessValue == "User") {
            setSubAccess(["User"])
            setsubAccesstype([])
            setaccessvalue(getaccessValue)
            singleaccessdata.grouptype = getaccessValue
            dispatch(add_single_access(singleaccessdata));
        }
    }
    async function updateaccessvalue(name, e) {
        
        const updateaccess = subAccesstype.map(access => {
            if (access.access === name) {
                return { ...access, type: e.target.value };
            } else {
                return access;
            }
        });
        setsubAccesstype(updateaccess);
        singleaccessdata.screens = updateaccess
        dispatch(add_single_access(singleaccessdata));
    }











    return (
        <>
            <div className="col-md-5" style={{ "overflowY": "auto", "height": "500px" }}>
                <div className="add-project-section">
                    <div className="project-name">
                        <p>Select Access</p>
                        <div className="status-section">
                            <label className="select-access-wrapp select-access customers-container container-box">
                                <input type="checkbox" name="Admin" value="Admin" onChange={(e) => { getaccessval(e); handleChange(e); }} checked={singleaccessdata.grouptype == "Admin" ? true : false} />
                                <span className="select-access-checkmark checkmark"></span>
                                Admin
                            </label>
                            <label className="select-access-wrapp select-access customers-container container-box">
                                <input type="checkbox" name="Manager" value="Manager" onChange={(e) => { getaccessval(e); handleChange(e); }} checked={singleaccessdata.grouptype == "Manager" ? true : false} />
                                <span className="select-access-checkmark checkmark"></span>
                                Manager
                            </label>
                            <label className="select-access-wrapp select-access customers-container container-box">
                                <input type="checkbox" name="User" value="User" onChange={(e) => getaccessval(e)} checked={singleaccessdata.grouptype == "User" ? true : false} />
                                <span className="select-access-checkmark checkmark"></span>
                                User
                            </label>
                            <div className="select-multiple-access" style={{ display: (singleaccessdata.grouptype.includes("User") || singleaccessdata.screens.some(item => item.access === 'Project') || singleaccessdata.screens.some(item => item.access === 'Task')) ? "block" : "none" }}>
                                <label className="select-access-wrapp select-access customers-container container-box">
                                    <input type="checkbox" name="selective-val[]" value="Project" onChange={handleChange} checked={singleaccessdata.screens.some(item => item.access === 'Project')} />
                                    <span className="select-access-checkmark checkmark"></span>
                                    Project
                                </label>
                                {singleaccessdata.screens.some(item => item.access === 'Project') &&
                                    <div className="select-multiple-access" style={{ display: singleaccessdata.screens.some(item => item.access === 'Project') ? "block" : "none" }}>
                                        <label className="select-access-wrapp select-access customers-container container-box">
                                            <input type="checkbox" value="Project Read" onChange={(e) => updateaccessvalue("Project", e)} checked={singleaccessdata.screens.find(item => item.access === 'Project').type === "Project Read"} />
                                            <span className="select-access-checkmark checkmark"></span>
                                            Read
                                        </label>
                                        <label className="select-access-wrapp select-access customers-container container-box">
                                            <input type="checkbox" value="Project Both" onChange={(e) => updateaccessvalue("Project", e)} checked={singleaccessdata.screens.find(item => item.access === 'Project').type === "Project Both"} />
                                            <span className="select-access-checkmark checkmark"></span>
                                            Read/Write
                                        </label>
                                    </div>}
                                <label className="min-margin select-access-wrapp select-access customers-container container-box">
                                    <input type="checkbox" name="selective-val[]" value="Task" onChange={handleChange} checked={singleaccessdata.screens.some(item => item.access === 'Task')} />
                                    <span className="select-access-checkmark checkmark"></span>
                                    Task
                                </label>
                                {singleaccessdata.screens.some(item => item.access === 'Task') &&
                                    <div className="select-multiple-access" style={{ display: (singleaccessdata.grouptype.includes("User") || singleaccessdata.screens.some(item => item.access === 'Task') || singleaccessdata.screens.some(item => item.access === 'Project')) ? "block" : "none" }}>
                                        <label className="select-access-wrapp select-access customers-container container-box">
                                            <input type="checkbox" value="Task Read" onChange={(e) => updateaccessvalue("Task", e)} checked={singleaccessdata.screens.find(item => item.access === 'Task').type === "Task Read"} />
                                            <span className="select-access-checkmark checkmark"></span>
                                            Read
                                        </label>
                                        <label className="select-access-wrapp select-access customers-container container-box">
                                            <input type="checkbox" value="Task Both" onChange={(e) => updateaccessvalue("Task", e)} checked={singleaccessdata.screens.find(item => item.access === 'Task').type === "Task Both"} />
                                            <span className="select-access-checkmark checkmark"></span>
                                            Read/Write
                                        </label>
                                    </div>}
                            </div>






                        </div>
                    </div>

                    <div className="select-project-manager">
                        <div className="project-manager">

                            <div className="project-name" >
                                <p>Add Group</p>
                                <div className="create-box-section-row">
                                </div>
                                <div className="name-section">

                                    <select id="manager" input type="text" placeholder="user name or email addresss"
                                        className="project-form-padding project-form-control" name="manufacture_part_number" list="manager" onChange={(e) => dropdownselectGroup(e)}>
                                        <option value="null" >--Select Group--</option>
                                        {(state_group.grouplist || []).map((data, index) => (
                                            <option multiple={true} value={data.groupname} >{data.groupname}</option>

                                        ))}



                                    </select>


                                </div>


                                <div className="newadd">
                                    <div className="project-upload-section">
                                        <div className="image-upload-box1">

                                            <button type="button" className="upload-own-image-button" onClick={() => { groupSelection(group) }} >
                                                Add</button>
                                        </div>
                                    </div>
                                </div>
                                {(singleaccessdata.groups || []).map((data, index) => (
                                    <div style={{ "marginBottom": "2px", "marginRight": "2px" }} className="badge badge-pill badge-primary">{data} <span style={{ "color": "#ffb0af" }} className="badge badge-light" />
                                        <button type="button" className="badge badge-pill badge-primary" onClick={() => deleatGroup(index)} >X</button>
                                    </div>
                                ))}


                            </div>
                        </div>
                    </div>
                    {/* <div className="select-project-manager">
                        <div className="project-manager">

                            <div className="project-name" >
                                <h4>Project Name: {state_project.selectedprojectdetails?.name}</h4>
                                <h4>Project Key: {state_project.selectedprojectdetails?.key}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="select-project-manager">
                        <div className="project-manager">

                            <div className="project-manager">

                                <div className="project-name" >
                                    <p>Invite Users</p>
                                    <div className="create-box-section-row">
                                    </div>
                                    <div className="name-section">

                                        <select id="manager" input type="text" placeholder="user name or email addresss"
                                            className="project-form-padding project-form-control" name="manufacture_part_number" list="manager" onChange={(e) => dropdownselectUser(e)}>
                                            <option value="null" >--Select User--</option>
                                            {(state_account.users || []).map((data, index) => (
                                                <option multiple={true} value={data.email} >{data.email}</option>
                                            ))}



                                        </select>


                                    </div>


                                    <div className="newadd">
                                        <div className="project-upload-section">
                                            <div className="image-upload-box1">

                                                <button type="button" className="upload-own-image-button" onClick={() => { userSelection(mail) }} >
                                                    Add</button>
                                            </div>
                                        </div>
                                    </div>
                                    {(state_project.selectedprojectdetails?.assignedTo || []).map((data, index) => (
                                        <div style={{ "marginBottom": "2px", "marginRight": "2px" }} className="badge badge-pill badge-primary"> {data}<span style={{ "color": "#ffb0af" }} className="badge badge-light" />
                                            <button type="button" className="badge badge-pill badge-primary" onClick={() => deleatUser(index)}>X</button>
                                        </div>
                                    ))}


                                </div>
                            </div>
                        </div>
                        <div className="project-submit-section border_bottom pb">
                            <button type="button" onClick={() => updateaccess()} className="btn btn-success submit-own-image-button">Update
                            </button>
                        </div>
                    </div> */}

                </div>

            </div>
        </>

    );
}
export { Createaccess };