import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { get_managers, get_user_profile } from "../../store/account/api";
import { add_single_project, get_project_list_success, add_project_success } from "store/projects/projectActions"
import { create_project, update_project, get_project_list_pagination, get_project_details } from "../../store/projects/api"
import { uploadfile } from "store/account/api";
import { Createtask } from "../index.js";
import * as FormData from 'form-data';


const Createproject = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const state_account = useSelector(state => state.account);
  const state_project = useSelector(state => state.project);
  const state_task = useSelector(state => state.task);
  const Token = localStorage.getItem("Token")
  const [showNotification, setShowNotification] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const [createprojectsuccess, setcreateprojectsuccess] = useState(false)
  const singleprojectdata = state_project.singleproject;
  const singlecostdata = state_project.projectprice;
  const singlecurrencydata = state_project.projectcurrency;
  const singletaskdata = state_task.singletask;
  const [selectmail, setselectmail] = useState("")
  const [cost, setCost] = useState("")
  const [selectemail, setselectEmail] = useState("")
  let mail = singleprojectdata.projectmanager
  const [file, setFile] = useState([])
  const [pageno, setpageno] = useState(0)
  let projects = [];
  const [count, setcount] = useState(20)
  const [currentStatus, setCurrentStatus] = useState("Select Project status")





  useEffect(() => {
    
    if (Token != null) {
      dispatch(get_user_profile(Token, (res => {
        if (res.success == true) {
          dispatch(get_managers(Token))

        }
      })))
    }


  }, []);
  console.log(state_account.managers)
  const projectEmail = [
    "ods@edgewrapper.com",
    "wrapup@edgewrapper.com",
    "entrypoint@edgewrapper.com"
  ]

  async function getprojectlistpagination(data) {
    const payload = {
      pageno: data,
      count: count,
      user: state_account.profile
    }
    dispatch(get_project_list_pagination(Token, payload, (res) => {
      if (res.success == true) {
        let value = res.data
        // value.forEach(i => {
        //   projects.push(i)
        //   // setprojects(current => [...current, i])
        // })
        dispatch(get_project_list_success(value))
      }
    }))
  }

  //suchandan
  async function handleChangeproject(e) {
    // setShowNotification(false)

    if (e.target.name == "projectname") {
      singleprojectdata.projectname = (e.target.value)
      dispatch(add_single_project(singleprojectdata))
    }
    else if (e.target.name == "projectdescription") {
      singleprojectdata.projectdescription = (e.target.value)
      dispatch(add_single_project(singleprojectdata))
    }
    else if (e.target.name == "clientdetails") {
      singleprojectdata.clientdetails = (e.target.value)
      dispatch(add_single_project(singleprojectdata))
    }
    else if (e.target.name == "projectdeadline") {
      singleprojectdata.projectdeadline = (e.target.value)
      dispatch(add_single_project(singleprojectdata))
    }

  };
  const handleInputChange = (event) => {
    
    const value = event.target.value;
    singleprojectdata.projectprice = value
    dispatch(add_single_project(singleprojectdata))
  };

  const handleCurrencyChange = (event) => {
    
    const value = event.target.value;
    singleprojectdata.projectcurrency = value
    dispatch(add_single_project(singleprojectdata))

  };


  const displayCurrencySymbol = () => {
    // Return the currency symbol based on the selected currency
    switch (singleprojectdata.projectcurrency) {
      case 'INR':
        return '₹';
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
      case 'JPY':
        return '¥';
      case 'CAD':
        return '$';
      case 'AUD':
        return '$';
      default:
        return '';
    }
  };



  async function createProject() {
    

    let checkstatus = await datavalidation(singleprojectdata)
    if (checkstatus == true) {
      if (singleprojectdata.projectid == "") {
        singleprojectdata.created_by = state_account.profile.email
        dispatch(add_single_project(singleprojectdata))
        let text = singleprojectdata.projectname;
        let result = text.toUpperCase().substring(0, 4);

        function padTo2Digits(num) {
          return num.toString().padStart(2, '0');
        }
        function formatDate(date) {
          return (
            [
              date.getFullYear(),
              padTo2Digits(date.getUTCMonth() + 1),
              padTo2Digits(date.getUTCDate()),
            ].join('')
          );
        }

        singleprojectdata.projectid = (result) + (formatDate(new Date()));

        dispatch(add_single_project(singleprojectdata))
        singleprojectdata.status = currentStatus
        dispatch(add_single_project(singleprojectdata))

        singleprojectdata.projectcost = (singleprojectdata.projectprice) + ' ' + (singleprojectdata.projectcurrency)
        console.log(singleprojectdata)
        dispatch(add_single_project(singleprojectdata))
        //suchandan


        Swal.fire({
          title: 'Do you want to create the Project In Wrap-up?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No', // Add the cancel button text
        }).then((result) => {
          if (result.isConfirmed) {
            let text = singleprojectdata.projectname;
            let result = text.toUpperCase().substring(0, 4);

            function padTo2Digits(num) {
              return num.toString().padStart(2, '0');
            }
            function formatDate(date) {
              return (
                [
                  date.getFullYear(),
                  padTo2Digits(date.getUTCMonth() + 1),
                  padTo2Digits(date.getUTCDate()),
                ].join('')
              );
            }

            singleprojectdata.projectid = (result) + (formatDate(new Date()));

            dispatch(add_single_project(singleprojectdata))
            singleprojectdata.projectcost = (singleprojectdata.projectprice) + ' ' + (singleprojectdata.projectcurrency)
            console.log(singleprojectdata)
            dispatch(add_single_project(singleprojectdata))
            //suchandan
            singleprojectdata.created_by = state_account.profile.email
            dispatch(add_single_project(singleprojectdata))
            dispatch(create_project(Token, singleprojectdata, (res => {
              if (res.success === true) {
                Swal.fire({
                  position: 'middle',
                  icon: 'success',
                  title: `Project ${singleprojectdata.projectname}  sucessfull created in Wrap-up`,
                })
                window.location.reload();
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: `Their is an error to create ${singleprojectdata.projectname} in wrap up.`

                })
              }
            })))
          } else if (result.dismiss === Swal.DismissReason.cancel) { // Check for cancel button dismissal

            Swal.fire('Changes are not saved', '', 'info')
          }
        })



      } else {
        dispatch(add_single_project(singleprojectdata))
        
        singleprojectdata.projectcost = (singleprojectdata.projectprice) + ' ' + (singleprojectdata.projectcurrency)
        singleprojectdata.status = currentStatus
        dispatch(add_single_project(singleprojectdata))
        dispatch(update_project(Token, singleprojectdata, (res => {
          if (res.success == true) {
            window.location.reload();
            Swal.fire({
              position: 'middle',
              icon: 'success',
              title: 'Your project details sucessfull updated in Wrap-up',
              showConfirmButton: false,
              timer: 1500
            })
            window.location.reload()
            // const payload = {
            //   projectid: singleprojectdata.projectid
            // }
            // getprojectlistpagination(pageno)
            // dispatch(get_project_details(payload))
            // dispatch(add_project_success(false))
            // dispatch(get_project_details_success(true))
            // dispatch(view_project_id(singleprojectdata.projectid))
          }
        })))


      }
    }
  }


  const changeStatus = (newStatus) => {
    
    setCurrentStatus(newStatus)

  }


  // const changeStatus = (newStatus) => {
  
  //   setCurrentStatus(newStatus)
  //   singleprojectdata.status = newStatus
  //   dispatch(add_single_project(singleprojectdata))

  // }


  async function dropdownselectManager(e) {
    
    setselectmail(e.target.value)
  };
  async function managerSelection() {
    
    if (selectmail != "null" || selectmail != "") {
      if (mail.includes(selectmail)) {

      } else {
        mail.push(selectmail)

      }
      console.log(mail)
      singleprojectdata.projectmanager = mail
      dispatch(add_single_project(singleprojectdata))
    }
  }
  async function dropdownselectProjectemail(f) {
    
    setselectEmail(f.target.value)
    singleprojectdata.projectemail = [(f.target.value)]
    dispatch(add_single_project(singleprojectdata))

  };

  async function deleteFile(e) {

    const s = singleprojectdata.files.filter((item, index) => index !== e);
    singleprojectdata.files = s
    console.log(s);
    dispatch(add_single_project(singleprojectdata))
  }

  async function deleatManager(e) {

    const m = mail.filter((data, index) => index !== e)
    mail = m
    // console.log(m);
    singleprojectdata.projectmanager = mail
    dispatch(add_single_project(singleprojectdata))

  }


  async function uploadimage(singlefile) {
    const formData = new FormData();

    formData.append(
      "file",
      singlefile
    );

    dispatch(uploadfile(formData, res => {

      console.log(res);
      let filedata = {
        "url": res.data.fileurl,
        "filename": res.data.filename,
        "uploadedby": state_account.profile.email,
        "uploadeddate": new Date()
      }

      singleprojectdata.files.push(filedata)
      dispatch(add_single_project(singleprojectdata))
    }
    ))
  }

  async function uploadFile(e) {
    if (e) {
      for (let i = 0; i < e.target.files.length; i++) {
        await uploadimage(e.target.files[i])
      }

    }


  }

  async function datavalidation(singleprojectdata) {
    
    let errorFields = [];

    if (singleprojectdata.projectemail == "") {
      errorFields.push("Project email");

    }
    if (singleprojectdata.projectname == "") {
      errorFields.push("Project name");

    }

    if (singleprojectdata.projectdescription == "") {
      errorFields.push("Project description");
    }

    if (singleprojectdata.clientdetails == "") {
      errorFields.push("Client name");
    }

    if (singleprojectdata.projectdeadline == "") {
      errorFields.push("Project deadline");
    }
    if (singleprojectdata.status == "") {
      errorFields.push("Project Status");
    }

    if (singleprojectdata.projectprice == undefined) {
      errorFields.push("Project cost");
    }
    if (singleprojectdata.projectcurrency == undefined) {
      errorFields.push("Project currency");
    }
    if (singleprojectdata.projectmanager == "") {
      errorFields.push("Project manager");
    }

    if (errorFields.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${errorFields.join(", ")} field(s) cannot be empty!`,

      })
      return false
    } else {
      return true
    }


  }


  console.log(singleprojectdata.projectdeadline)

  return (
    <>
      <div className="col-md-5" style={{ "overflowY": "auto", "height": "500px" }}>
        <div className="add-project-section">
          <div className="project-name">
            <p>Select Project email</p>
            <div className="name-section">
              <select
                id="employee"
                value={singleprojectdata.projectemail && singleprojectdata.projectemail.length > 0 ? singleprojectdata.projectemail[0] : ""}
                className="project-form-control"
                name="manufacture_part_number"
                list="employee"
                onChange={(f) => dropdownselectProjectemail(f)}
              >
                <option value={"null"}>--Select Email--</option>

                {(projectEmail || []).map((data, index) => (
                  <option multiple={false}  >{data}</option>
                ))}
              </select>
            </div>


          </div>
          <div className="project-name">
            <p>Project name</p>
            <div className="name-section">
              <input type="text" placeholder="Write your project name "
                className="project-form-control" name="projectname" onChange={(e) => handleChangeproject(e)} value={singleprojectdata.projectname} />
            </div>
          </div>
          <div className="project-name">
            <p>Project description</p>
            <div className="name-section">
              <textarea type="text" placeholder="Write your project description here "
                name="projectdescription" onChange={(e) => handleChangeproject(e)} value={singleprojectdata.projectdescription}></textarea>
            </div>
          </div>
          <div className="project-name">
            <p>Project Status</p>
            <div className="name-section" value={singleprojectdata.status}>
              <select className="project-form-control" onChange={(event) => changeStatus(event.target.value)} >
                <option value="0">Select Project status</option>
                <option value="Not Started">Not Started</option>
                <option value="In Progress">In Progress</option>
                <option value="In Review">In Review</option>
                <option value="Complete">Complete</option>
              </select>
            </div>
          </div>

          <div className="project-name">
            <p>Client name </p>
            <div className="name-section">
              <input type="text" placeholder="Write your client name here "
                className="project-form-control" name="clientdetails" onChange={(e) => handleChangeproject(e)} value={singleprojectdata.clientdetails} />
            </div>
          </div>
          <div className="project-name">
            <p>Project Cost</p>
            <form className="form-inline">
              <label className="sr-only" htmlFor="inlineFormInputGroup">
                Amount
              </label>
              <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                <div className="input-group-addon currency-symbol">
                  {displayCurrencySymbol()}
                </div>
                <input
                  type="number"
                  className="form-control"
                  id="projectCost"
                  value={singleprojectdata.projectprice}
                  onChange={handleInputChange}
                  placeholder="0.00"
                  style={{ fontSize: "1.2rem", padding: "0.5rem", border: "1px solid #ccc", boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)" }}
                />
                <div className="input-group-addon currency-addon currency-addon-fixed">
                  {singleprojectdata.projectcurrency}
                </div>
              </div>
            </form>
            <select
              className="currency-selector"
              name="currency"
              value={singleprojectdata.projectcurrency}
              onChange={handleCurrencyChange}
              style={{ marginTop: "0.5rem", fontSize: "1.2rem", padding: "0.5rem", border: "1px solid #ccc", boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)" }}
            >
              <option value="0">Select Currency</option>
              <option data-symbol="₹" value="INR" data-placeholder="0.00">
                INR
              </option>
              <option data-symbol="$" value="USD" data-placeholder="0.00">
                USD
              </option>
              <option data-symbol="€" value="EUR" data-placeholder="0.00">
                EUR
              </option>
              <option data-symbol="£" value="GBP" data-placeholder="0.00">
                GBP
              </option>
              <option data-symbol="¥" value="JPY" data-placeholder="0">
                JPY
              </option>
              <option data-symbol="$" value="CAD" data-placeholder="0.00">
                CAD
              </option>
              <option data-symbol="$" value="AUD" data-placeholder="0.00">
                AUD
              </option>
            </select>
          </div>





          <div className="project-name">
            <p>Project deadline</p>
            <div className="name-section">
              <input
                type="date"
                data-date=""
                data-date-format="DD MMMM YYYY"
                name="projectdeadline"
                placeholder="Select your project deadline date"
                className="project-form-control"
                onChange={(e) => handleChangeproject(e)}
                defaultValue={singleprojectdata.projectdeadline != "" ? (new Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" }).format(Date.now(singleprojectdata.projectdeadline))) : "DD MMMM YYYY"}
              />
            </div>
          </div>



          <div className="form-group preview">
            {singleprojectdata.files.length > 0 &&
              singleprojectdata.files.map((item, index) => {
                return (
                  <div className="image-upload-box1" key={`${index}projectdata`}>
                    <div className="img-cercle">
                      {/* Check if the file is an image */}
                      {item.url.endsWith(".jpg") || item.url.endsWith(".jpeg") || item.url.endsWith(".png") || item.url.endsWith(".gif") ? (
                        <img src={item.url} alt="" className="main-img" />
                      ) : (
                        // Display file icon for non-image files
                        <div className="file  -icon">
                          <img src="pdf.png"></img>
                        </div>

                      )}
                    </div>
                    <div className="file-name">
                      {/* Get the original file name */}
                      <strong>{decodeURIComponent(item.url.split("/").pop())}</strong>
                    </div>
                    <button type="button" className="upload-own-image-button" onClick={() => deleteFile(index)}>
                      Delete
                    </button>
                  </div>
                );
              })}
          </div>

          <div className="project-upload-section">
            <div className="image-upload-box1">
              <input
                type="file" multiple="multiple"
                disabled={file.length === 1}
                className="form-control"
                onChange={(e) => uploadFile(e)}
              />
              <button type="button" className="upload-own-image-button" >Upload
                document</button>

              <div>
              </div>
            </div>
          </div>

          <div className="select-project-manager">
            <div className="project-manager">

              <div className="project-name" >
                <p>Invite project manager</p>
                <div className="create-box-section-row">
                </div>
                <div className="name-section">

                  <select id="manager" input type="text" placeholder="user name or email addresss"
                    className="project-form-padding project-form-control" name="manufacture_part_number" list="manager" onChange={(e) => dropdownselectManager(e)}>
                    <option value="null" >--Select Manager--</option>
                    {(state_account.managers || []).map((data, index) => (
                      <option multiple={true} value={data.user.email} key={`${index}users`}>{data.user.email}</option>

                    ))}



                  </select>


                </div>


                <div className="newadd">
                  <div className="project-upload-section">
                    <div className="image-upload-box1">

                      <button type="button" className="upload-own-image-button" onClick={() => { managerSelection(mail) }} >
                        Invite</button>
                    </div>
                  </div>
                </div>
                {(singleprojectdata.projectmanager || []).map((data, index) => (
                  <div style={{ "marginBottom": "2px", "marginRight": "2px" }} className="badge badge-pill badge-primary" key={`${index}groupmanager`}>{data} <span style={{ "color": "#ffb0af" }} className="badge badge-light" />
                    <button type="button" className="badge badge-pill badge-primary" onClick={() => deleatManager(index)}>X</button>
                  </div>))}


              </div>
            </div>
            <div className="project-submit-section border_bottom pb">
              <button onClick={() => createProject()} type="button" className="btn btn-success submit-own-image-button">submit
              </button>
            </div>
          </div>







        </div>
        {createprojectsuccess ? (
          <Createtask />
        ) : (
          <></>
        )}
      </div>
    </>

  );
}
export { Createproject };