import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { get_user_profile } from "../../store/account/api";
import { user_signin } from "store/account/accountActions"
import { wrapup_users_updateprofile } from "store/account/api";
import { SideBar } from 'components';
import { uploadfile } from "store/account/api";
import Swal from 'sweetalert2'

const Settings = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const state_account = useSelector(state => state.account);
  const profiledetails = state_account.profile;
  const updateuserdata = state_account.updateuser
  const Token = localStorage.getItem("Token");


  useEffect(() => {
    if (Token != null) {
      dispatch(get_user_profile(Token))
    }
  }, []);

  async function uploadimage(singlefile) {
    const formData = new FormData();

    formData.append(
      "file",
      singlefile
    );

    dispatch(uploadfile(formData, res => {
      console.log(res);

      profiledetails.profileimage = res.data.fileurl
      dispatch(user_signin(profiledetails))
    }
    ))
  }

  async function uploadFile(e) {
    if (e) {
      for (let i = 0; i < e.target.files.length; i++) {
        await uploadimage(e.target.files[i])
      }
    }
  }

  async function updateProfile() {

    const data = {
      email: profiledetails.email,
      firstname: profiledetails.firstname,
      profileimage: profiledetails.profileimage,
      lastname: profiledetails.lastname,
      firebasetoken: state_account.profile.firebasetoken,
      data: state_account.profile.data,
      employeeid: profiledetails.employeeid,
    }
    dispatch(wrapup_users_updateprofile(Token, data))
    Swal.fire('Profile updated successfully!', '', 'success')
    // navigate("/projects")
  }


  async function handleChangeProfile(e) {

    if (e.target.name == "firstname") {
      profiledetails.firstname = (e.target.value)
      dispatch(user_signin(profiledetails))
    }
    else if (e.target.name == "lastname") {
      profiledetails.lastname = (e.target.value)
      dispatch(user_signin(profiledetails))
    }
    else if (e.target.name == "employeeid") {
      profiledetails.employeeid = (e.target.value)
      dispatch(user_signin(profiledetails))
    }
  };

  return (
    <div>
      <div className="dashboard">
        {/* <div className="dashboard-nav"> */}
        <SideBar />
        {/* </div> */}
        <div className="dashboard-app" id="main_section_toggle">
          {/* <p className="dashboard-toolbar">
            <a href="#!" className="menu-toggle"><i style={{ "fontSize": "24px" }} className="fa">&#xf0c9;</i></a>
          </p> */}
          <div className="dashboard-content">

            <div className="header-row">
              <div className="col-md-5">
                <div className="manager-wrap">
                  <div className="manager-title">
                    <p>Settings</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="use-box">
              <div className="project-manager-section-box">
                <div className="project-box">
                  <div className="notification-row">

                    <div className="cercle-div">
                      <div className="main-cercle-div">
                        {state_account.profile.profileimage != "" ? (<><img className="profile-photo-image" src={state_account.profile.profileimage} /></>) : (
                          <><img className="profile-photo-image" src="images/icons/Default_profile.svg" /></>
                        )}
                      </div>
                      <div className="ellipse">
                        <img src="images/settings/Ellipse.png" alt="" className="ellipse-img" width={"32px"} />
                        <div className="camera">
                          <input
                            type="file" multiple="multiple"
                            className="form-control cursor"
                            onChange={(e) => uploadFile(e)}
                          />
                          <img src="images/settings/camera.png" alt="" className="camera-img" />
                        </div>
                      </div>

                    </div>





                    <div className="employee-name">
                      <div className="deperment-devoloper">
                        <h2 style={{ "margin": "0" }}>{state_account.profile.firstname} {state_account.profile.lastname}</h2>
                      </div>
                      <div className="deperment-designation" style={{
                        "textTransform": "capitalize"
                      }}>
                        <h4>{state_account.profile.role} </h4>
                      </div>

                    </div>

                  </div>
                  <div className="employee-data">
                    <div className="employee-info">
                      <div className="">
                        <label className="employee-name-holder">FIRST NAME</label><br />
                        <input type="text" name='firstname' placeholder="FIRST NAME" className="employee-data-holder" value={profiledetails.firstname} onChange={(e) => handleChangeProfile(e)} />
                      </div>
                      <div className="">
                        <label className="employee-name-holder">LAST NAME</label><br />
                        <input type="text" name='lastname' placeholder="LAST NAME" className="employee-data-holder" value={profiledetails.lastname} onChange={(e) => handleChangeProfile(e)} />
                      </div>
                    </div>
                    <div className="employee-info">
                      <div className="">
                        <label className="employee-name-holder">EMPLOYEE ID</label><br />
                        <input type="text" name='employeeid' placeholder="EMPLOYEE ID" className="employee-data-holder" value={profiledetails.employeeid} onChange={(e) => handleChangeProfile(e)} />
                      </div>
                      <div className="">
                        <label className="employee-name-holder">EMAIL ID</label><br />
                        <input type="email" placeholder="EMAIL ID" name='email' defaultValue={profiledetails.email}
                          className="employee-data-holder employee-data-holder-edit" />
                      </div>

                    </div>
                  </div>
                  <div className="project-submit-section border_bottom pb save-btn-align">
                    <button type="button" className="btn btn-success submit-own-image-button" onClick={() => updateProfile()}>SAVE
                    </button>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  )

}

export {
  Settings
}