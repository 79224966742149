import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Cell, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

const DynamicBarCharts = ({ sprintData, onBarClick }) => {
  // Handle chart click only on bars
  const handleChartClick = (e) => {
    const activePayload = e.activePayload;

    // Check if there is an active payload and it is related to a Bar
    if (activePayload && activePayload.length > 0 && activePayload[0].dataKey === 'count') {
      const data = activePayload[0].payload;
      const clickedStatus = data.status;
      const projectKey = data.projectKey;

      if (clickedStatus && projectKey) {
        onBarClick(clickedStatus, projectKey);
      } else {
        console.warn('Could not extract valid status or project key');
      }
    }
  };

  // Helper function to get color based on status
  const getStatusColor = (status) => {
    switch (status) {
      case 'To Do': return 'rgba(255, 99, 132, 0.6)';  // Red
      case 'InProgress': return 'rgba(54, 162, 235, 0.6)';  // Blue
      case 'Done': return 'rgba(75, 192, 75, 0.6)';  // Green
      default: return 'rgba(255, 159, 64, 0.6)';  // Default color if status is unknown
    }
  };

  return (
    <div style={{ display: 'flex', overflowX: 'auto', width: '100%' }}>
      {sprintData.map((sprint, index) => {
        // Map the statuses to an array with assigned colors
        const chartData = sprint.statuses.map(status => ({
          status: status.status,
          count: status.count,
          projectKey: sprint.projectKey,
          color: getStatusColor(status.status),  // Add color to each status
        }));

        return (
          <div key={index} style={{ minWidth: '300px', marginRight: '20px' }}>
            {/* Display Sprint Name and Project Key */}
            <h5>{`Sprint: ${sprint.sprint} - Project: ${sprint.projectKey}`}</h5>
            <ResponsiveContainer width="100%" height={200}>
              <BarChart
                data={chartData}
                margin={{ top: 20, right: 20, left: 0, bottom: 5 }}
                onClick={handleChartClick} // Use the modified click handler
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="status" />
                <YAxis />
                <Tooltip />
                <Legend />
                {/* Bar component using dynamic fill based on color */}
                <Bar dataKey="count">
                  {/* We now set the fill dynamically based on the color in the chartData */}
                  {chartData.map((entry, idx) => (
                    <Cell key={`cell-${idx}`} fill={entry.color} />
                  ))}
                  {/* Optionally add labels to each bar */}
                  <LabelList dataKey="count" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        );
      })}
    </div>
  );
};

export { DynamicBarCharts };
