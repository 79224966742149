import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    otp_varification,
} from "../../store/account/accountActions";
import Swal from 'sweetalert2'
import { wrapup_users_otpvarification, wrapup_users_forgotpassword } from "../../store/account/api"
import { Admin, Manager, User} from '../../config';
import { get_user_profile } from "../../store/account/api";


const Verification = () => {
    const OTP_TIMER = 180;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const state_account = useSelector(state => state.account);
    const otpVerificationdata = state_account.profile
    // const [otp, setOtp] = useState(["X", "X", "X", "X"]);
    const [otp, setOtp] = useState("XXXX");
    const [sendOtpVisible, setResendOtpVisible] = useState(false)
    let query = useQuery()
    const [counter, setCounter] = useState(OTP_TIMER);
    const Getdata = localStorage.getItem("Access")
    const Token = localStorage.getItem("Token")


    useEffect(() => {
        if (Token != null) {
            dispatch(get_user_profile(Token, (res => {

            })))
            if (Getdata.includes(`${Admin}`) || Getdata.includes(`${Manager}`) || Getdata.includes(`${User}`)) {
            } else {
                navigate('/');
            }
        }
        const timer = setInterval(() => setCounter(counter - 1), 1000);
        if (counter == 0) {
            setResendOtpVisible(true);
        } else {

        }
        return () => clearInterval(timer);
    }, [counter]);

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    async function handleOtp(e) {
        if (isNaN(e.target.value)) {
          return false;
        }

        const tempOtp = otp.split("");
        //  ["", "", "", ""];
      const inputValues = e.target.value.split("");
      const isBackspace = e.nativeEvent.inputType === "deleteContentBackward";
      if (inputValues.length > 1) {
        for (let i = 0; i < inputValues.length; i++) {
          if (i < 4) {
            tempOtp[i] = inputValues[i];
            document.getElementsByName(`otp${i + 1}`)[0].value = inputValues[i];
            if (i < 3) {
              document.getElementsByName(`otp${i + 2}`)[0].focus();
            } else {
              document.getElementsByName(`otp${i + 1}`)[0].focus();//<<=sm
            }
          }
        }
      } else {
        const index = parseInt(e.target.name.slice(-1)) - 1;
    
        if (isBackspace) {
          tempOtp[index] = "";
          if (index > 0) {
            document.getElementsByName(`otp${index}`)[0].focus();
          } else {
            document.getElementsByName(`otp1`)[0].focus();
          }
        } else {
    
    
        tempOtp[index] = e.target.value;
        if (index < 3) {
          document.getElementsByName(`otp${index + 2}`)[0].focus();
        } else {
          document.getElementsByName(`otp${index + 1}`)[0].focus();//<<=sm
        }
      }
      }
    
    
    
    
        const otpStr = tempOtp.join("");
        console.log(otpStr)
        setOtp(otpStr);
    
        if (!isNaN(otpStr)) {
          console.log("FINAL OTP ", otpStr);
          otpVerificationdata.otp = otpStr;
          dispatch(otp_varification(otpVerificationdata));
        }
      }

    async function resendOtp() {

        state_account.email = query.get("email");
        const payload = {
            email: state_account.email
        }
        dispatch(wrapup_users_forgotpassword(payload))
        Swal.fire({
            position: 'middle',
            icon: 'success',
            title: 'Your Reset Password Otp send to you email.',
            showConfirmButton: false,
            timer: 1500
          })
        setResendOtpVisible(false);
        setCounter(OTP_TIMER);
    }

    async function otpVerification() {
        
        state_account.email = query.get("email");
        const payload = {
            email: state_account.email,
            token: otpVerificationdata.otp
        }
        dispatch(wrapup_users_otpvarification(payload, (res => {
            if (res.success == true) {
                Swal.fire({
                    position: 'middle',
                    icon: 'success',
                    title: 'Your Otp varified Sucessfully.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                navigate("/newpassward?email="+ payload.email)
            }
            else {
                console.log("Invalid response from API");
            }
        })))
    }











    return (
        <div>
            <div className="login-section-area">
                <div className="login-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4 container-new">
                                <div className="login-box">
                                    <div className="logo-section">
                                        <img src="images/task manager/wrapup logo.svg" alt="" />
                                    </div>
                                    <div className="login-heading">
                                        <p>Verification</p>
                                        <h6>Enter verification code</h6>
                                    </div>
                                    <div className="login-information-section">
                                        <div className="verification">

                                            <div className="card text-center verification-otp">



                                                <div className="input-container d-flex flex-row justify-content-center mt-2 verification-otp-new ">
                                                    <input type="text" className="m-1 text-center form-control rounded" name="otp1"
                                                    required onChange={(e) => handleOtp(e)} />
                                                    <input type="text" className="m-1 text-center form-control rounded" name="otp2"
                                                        maxlength="1" required onChange={(e) => handleOtp(e)} />
                                                    <input type="text" className="m-1 text-center form-control rounded" name="otp3"
                                                        maxlength="1" required onChange={(e) => handleOtp(e)} />
                                                    <input type="text" className="m-1 text-center form-control rounded" name="otp4"
                                                        maxlength="1" required onChange={(e) => handleOtp(e)} />

                                                </div>

                                                <div>
                                                    <small>
                                                        If you didn't receive the code
                                                        {sendOtpVisible ? (
                                                            <a onClick={() => { resendOtp() }}> Resend OTP </a>
                                                        ) : (
                                                            <a className="btn">Resend OTP in 00:{counter}</a>
                                                        )}

                                                    </small>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="sale-feed-action">
                                            <button type="button" className="sale-action-link" value="Submit" onClick={() => otpVerification()}>Send</button>
                                        </div>
                                        {/* <div className="account-sign">
                                    <span>Don’t have an account?</span><strong> Sign up</strong>
                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export {
    Verification
}