import { combineReducers } from "redux";


import accountReducer from "./account/accountReducer";
import projectReducer from "./projects/projectReducer";
import taskReducer from "./task/taskReducer";
import groupReducer from "./groups/groupReducer";
import accessReducer from "./access/accessReducer";


export default combineReducers({

  account: accountReducer,
  project: projectReducer,
  task: taskReducer,
  group: groupReducer,
  access: accessReducer

});
