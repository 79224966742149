export const ADD_SINGLE_TASK = 'ADD_SINGLE_TASK';
export const ADD_TASK_LIST_SUCCESS = 'ADD_TASK_LIST_SUCCESS';
export const GET_TASK_LIST_SUCCESS = 'GET_TASK_LIST_SUCCESS';
export const GET_TASK_DETAILS_SUCCESS = 'GET_TASK_DETAILS_SUCCESS';
export const GET_SINGLE_TASK_ID = 'GET_SINGLE_TASK_ID';
export const UPDATE_SINGLE_TASK = 'UPDATE_SINGLE_TASK';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const ADD_SINGLE_EVENT = 'ADD_SINGLE_EVENT';
export const ADD_STATUS_SUCCESS = 'ADD_STATUS_SUCCESS';
export const GET_SINGLE_USER_TASK_TIMER_DETAILS_SUCCESS = 'GET_SINGLE_USER_TASK_TIMER_DETAILS_SUCCESS';
