import * as types from "./projectTypes";


export function get_project_list_success(payload) {

  return {
    type: types.GET_PROJECT_LIST_SUCCESS,
    payload: payload,
  };
}

export function add_project_success(payload) {
  return {
    type: types.ADD_PROJECT_LIST_SUCCESS,
    payload: payload,
  };
}

export function view_project_id(payload) {
  return {
    type: types.VIEW_PROJECT_ID_SUCCESS,
    payload: payload,
  };
}

export function view_issue_id(payload) {
  return {
    type: types.VIEW_ISSUE_ID_SUCCESS,
    payload: payload,
  };
}
export function get_project_details_success(payload) {
  return {
    type: types.GET_PROJECT_DETAILS_SUCCESS,
    payload: payload,
  };
}

export function add_single_project(payload) {
  return {
    type: types.ADD_SINGLE_PROJECT,
    payload: payload,
  };
}

export function get_single_project_details_success(payload) {
  return {
    type: types.GET_SINGLE_PROJECT_SUCCESS,
    payload: payload,
  };
}

export function search_project(payload) {
  return {
    type: types.SEARCH_PROJECT_SUCCESS,
    payload: payload,
  };
}

export function search_project_list_success(payload) {
  return {
    type: types.SEARCH_PROJECT_LIST_SUCCESS,
    payload: payload,
  };
}
export function projectcostsuccess(payload) {
  return {
    type: types.PROJECTCOSTSUCCESS,
    payload: payload,
  };
}
export function projectcurrencysuccess(payload) {
  return {
    type: types.PROJECTCURRENCYSUCCESS,
    payload: payload,
  };
}

export function getjiraprojectwiseissue(payload) {
  return {
    type: types.JIRAISSUESFORAPROJECTSUCCESS,
    payload: payload,
  };
}

export function getjiraprojectwiseissuedetails(payload) {
  return {
    type: types.JIRAISSUESDETAILSSUCCESS,
    payload: payload,
  };
}


export function selectproejctforaccess(payload) {
  return {
    type: types.SELECTAPROJECTFORACCESSSUCCESS,
    payload: payload,
  };
}

export function projectwiseuserlist(payload) {
  return {
    type: types.PROJECTWISEGETUSERSSUCCESS,
    payload: payload,
  };
}

export function getaccessableprojectcount(payload) {
  return {
    type: types.GETACCESSABLEPROJECTCOUNTSSUCCESS,
    payload: payload,
  };
}

export function projectlistviewsuccess(payload) {
  return {
    type: types.PROJECTLISTVIEW,
    payload: payload,
  };
}

export function getepiclist(payload) {
  return {
    type: types.GET_EPIC_LIST_SUCCESS,
    payload: payload,
  };
}

export function selectepic(payload) {
  return {
    type: types.GET_SELECTED_EPIC,
    payload: payload,
  };
}
export function setloading(payload) {
  return {
    type: types.LOADING_STATUS_UPDATE,
    payload: payload,
  };
}

export function updateissuestatus(payload) {
  return {
    type: types.ISSUE_STATUS_TYPE_UPDATE,
    payload: payload,
  };
}
