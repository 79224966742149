import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import { get_user_profile } from "../../store/account/api";
import { add_single_task, add_task_success, update_single_task } from "store/task/taskActions";
import { get_project_list_pagination, get_search_project_list_pagination, getJiraCredential } from "store/projects/api";
import {
    setloading, updateissuestatus,
    add_project_success, view_issue_id, search_project, get_project_details_success, selectproejctforaccess,
    get_project_list_success, get_single_project_details_success, view_project_id, selectepic,
    add_single_project, search_project_list_success, getjiraprojectwiseissuedetails, projectlistviewsuccess
} from "store/projects/projectActions";
import { get_project_details } from "store/projects/api";
import { green } from "@material-ui/core/colors";
import { Admin, Manager, User, Project_Full } from '../../config';
import { get_issue_list_for_jira_project } from "store/projects/api"

const ProjectList = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const state_account = useSelector(state => state.account);
    const state_project = useSelector(state => state.project);
    const state_task = useSelector(state => state.task);
    let tasklist = [];
    const Token = localStorage.getItem("Token")
    const Getdata = localStorage.getItem("Access")
    const [pageno, setpageno] = useState(0)
    let projects = state_project.projectlist
    const [project, setProject] = useState([])
    const [count, setcount] = useState(50)
    const { id } = useParams();
    // Declare a variable to cancel image process for previous issue.
    let cancelGetJiraCredentials = null;
    const newproject = {
        projectname: "",
        projectdescription: "",
        projectdetails: "",
        projectmanager: [],
        projectdeadline: "",
        projectcost: "",
        projectemployee: [],
        projectid: "",
        status: "",
        clientdetails: "",
        files: [],

    }
    const [searchQuery, setSearchQuery] = useState('');
    const [statuscheckedQuery, setstatuscheckedQuery] = useState('');
    let GroupadminAccessdata = []
    if (localStorage.getItem("GroupadminAccessdata") != "undefined") {
        GroupadminAccessdata = JSON.parse(localStorage.getItem("GroupadminAccessdata"))
    }
    let UserEmail = ''
    if (localStorage.getItem("UserEmail") != "undefined") {
        UserEmail = localStorage.getItem("UserEmail")
    }

    useEffect(() => {

        if (Token != null) {
            dispatch(get_user_profile(Token, (res => {
                if (res.success == true) {
                    if (projects.length == 0) {
                        getprojectlistpagination(pageno)
                    }
                    else {
                        if (state_project.selectedprojectid != "") {
                            getprojectdetails(state_project.selectedprojectid, "")
                        }
                    }
                }
            })))
        }


    }, []);

    async function GetIssueList(data, value, searchvalue, epicvalue) {
        let allowvalue = (UserEmail === "admin@wrapup.com" || GroupadminAccessdata.find(item => item.access === data?.name && item.type !== "noaccess")) ? true : false
        dispatch(selectproejctforaccess(data))
        dispatch(get_project_details_success(false))
        dispatch(setloading(true))
        const payload = {
            "projectKey": data.key,
            "projectname": data.name,
            "userEmail": UserEmail,
            "allow": allowvalue,
            "firstname": state_account.profile.firstname,
            "lastname": state_account.profile.lastname,
            "status": value,
            "searchvalue": searchvalue,
            "epic": epicvalue
        }
        dispatch(get_issue_list_for_jira_project(Token, payload, async (res) => {
            if (res.data.length != 0) {
                dispatch(projectlistviewsuccess(true))
                selectIssue(res.data[0])
                // dispatch(selectepic(''))
                dispatch(setloading(false))
            }
            else {
                dispatch(setloading(false))
                Swal.fire('No task available', '', 'info')
            }

        }))
    }

    const handleCheckboxClick = (event) => {
        const { value, checked } = event.target;
        // Uncheck all checkboxes
        // Update the searchQuery based on the checked status
        // setstatuscheckedQuery(checked ? value : '');
        if (checked) {
            dispatch(updateissuestatus(value))
            GetIssueList(state_project.selectedprojectdetails, value, searchQuery, state_project.selectedepic)
        } else {
            dispatch(updateissuestatus(''))
            GetIssueList(state_project.selectedprojectdetails, '', searchQuery, state_project.selectedepic)
        }

    };

    async function selectIssue(data) {
        await addCommentsField(data)
        // Add comments field to each subtask object
        data.subTasks.forEach(async subtask => await addCommentsField(subtask));
        dispatch(getjiraprojectwiseissuedetails(data))
        dispatch(get_project_details_success(true))
        dispatch(view_issue_id(data.key))
        const newData = JSON.parse(JSON.stringify(data));
        await addStatusboxToObject(newData);
    }

    // Function to add comments field to an object
    async function addCommentsField(obj) {
        if (!obj.comments) {
            obj.comments = [];
        }
        if (!obj.files) {
            obj.files = []
        }
    }


    async function addStatusboxToObject(obj) {
        // Add the "statusbox" property to the object
        obj.statusbox = false; // Replace "your_value_here" with the desired value

        // If the object has subtasks, add the "statusbox" property to each subtask
        if (obj.subTasks && Array.isArray(obj.subTasks)) {
            obj.subTasks.forEach((subtask) => {
                addStatusboxToObject(subtask);
            });
        }
    }

    async function getprojectlistpagination(data) {
        if (Getdata.includes(`${Admin}`)) {
            const payload = {
                pageno: data,
                count: count,
                user: state_account.profile,
                accesstype: "admin"
            }
            dispatch(get_project_list_pagination(Token, payload, (res) => {
                if (res.success == true) {
                    let value = res.data

                    dispatch(get_project_list_success(value))
                    setProject(value)
                    if (state_project.selectedprojectid != "") {
                        getprojectdetails(state_project.selectedprojectid, "")
                    }

                }
            }))
        } else {
            const payload = {
                pageno: data,
                count: count,
                email: state_account.profile.email,
                user: state_account.profile,
            }
            dispatch(get_project_list_pagination(Token, payload, (res) => {
                if (res.success == true) {
                    let value = res.data
                    // value.forEach(i => {
                    //     projects.push(i)
                    // })
                    dispatch(get_project_list_success(value))
                    setProject(value)
                    if (state_project.selectedprojectid != "") {
                        getprojectdetails(state_project.selectedprojectid, "")
                    }
                }
            }))
        }
    }
    async function scrollFunction() {

        if ((window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 400)) {
            setpageno(pageno + 1)
            const email = state_account.profile.email

            getprojectlistpagination(pageno + 1, email)
        }
    }
    async function openprojectform() {
        if (Getdata.includes(`${Admin}`) || Getdata.includes(`${Project_Full}`)) {
            dispatch(update_single_task(false))
            dispatch(add_single_project(newproject))
            dispatch(get_single_project_details_success([]))
            dispatch(add_project_success(true))
            dispatch(get_project_details_success(false))
            navigate('/projects')
            dispatch(view_project_id(''))
        } else {
            Swal.fire({ confirmButtonColor: "#365d5a", title: "You don't have access", type: 'info' })
        }

    }

    async function getprojectdetails(projectid, projectname) {
        dispatch(add_task_success(tasklist))
        dispatch(update_single_task(false))
        const payload = {

            projectid: projectid
        }
        navigate(`/projects`)
        dispatch(get_project_details(payload))
        dispatch(add_project_success(false))
        dispatch(get_project_details_success(true))
        dispatch(view_project_id(projectid))
    }



    async function getsearchtext(e) {
        if (e.target.value.length > 1) {
            dispatch(search_project(true))
            // console.log(e.target.value)
            getprojectlistpaginationbysearch(0, e.target.value)

        }
        else {
            dispatch(search_project(false))
        }
    }

    async function getprojectlistpaginationbysearch(data, searchvalue) {
        const payload = {
            pageno: data,
            count: 1000,
            user: state_account.profile,
            search: searchvalue
        }
        let searchprojects = []
        dispatch(get_search_project_list_pagination(Token, payload, (res) => {
            if (res.success == true) {
                let value = res.data
                value.forEach(i => {
                    searchprojects.push(i)
                })
                dispatch(search_project_list_success(searchprojects))
            }
        }))
    }

    function handleKeyPress(event) {
        if (event.keyCode === 13) {
            // Check if the pressed key is Enter (keycode 13)
            handleCheckboxClick(event)
        }
    }

    function SelectEpic(event) {
        if (event.target.value === "") {
            dispatch(updateissuestatus('In Progress'))
            GetIssueList(state_project.selectedprojectdetails, 'In Progress', searchQuery, event.target.value)
            // Check if the pressed key is Enter (keycode 13)
            //   handleCheckboxClick(event)
        } else {
            dispatch(updateissuestatus(''))
            GetIssueList(state_project.selectedprojectdetails, '', searchQuery, event.target.value)
        }
    }
    return (
        <>
            <div className="col-md-4" >
                <div className="project-heading" >
                    {/* <div className="task-project-row">
                        <div className="col-md-3">
                            <div className="project-heading-wrap">
                                {state_project.issuelist.length > 0 && <p>Stories/Tasks</p>}
                            </div>
                        </div>
                        <div className="col-md-2">

                        </div>
                        <div className="col-md-5">
                            <div className="project-manager-name">
                                <h6>{state_account.profile.firstname} {state_account.profile.lastname}</h6>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="manager-img">
                                {state_account.profile.profileimage != "" ? (<><img className="profile-photo-image" src={state_account.profile.profileimage} /></>) : (
                                    <><img className="profile-photo-image" src="images/settings/avatar.png" /></>
                                )}

                            </div>
                        </div>
                    </div> */}
                    {/* <div className="create-box-section-row">
                        <div className="add-new" onClick={() => openprojectform()}>
                            <p>Create new project</p>
                            <img src="images/task manager/active.svg" alt="" />
                        </div>
                    </div> */}
                    <div className="search-section">
                        {/* <input type="text" name="search" placeholder="Search Project.."
                            className="search-form-control" onChange={(e) => getsearchtext(e)} /> */}
                        <div className="search-bar-wrapp"> <input type="text" className="search-form-control" input="true"
                            placeholder="Search by key / summary and press Enter"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)} onKeyDown={(e) => handleKeyPress(e)} />
                            {searchQuery != "" && <div className="search-bar-clear pointer-cursor" onClick={()=>{GetIssueList(state_project.selectedprojectdetails, 'In Progress', "", state_project.selectedepic); setSearchQuery(""); dispatch(updateissuestatus('In Progress'))}}>
                                <img src="/images/cross.svg"/></div>}
                            </div>
                        {/* <button onClick={(e) => handleCheckboxClick(e)} name="search">Search</button> */}
                    </div>
                    <div>
                        <select className="epic-dropdown" onChange={(e) => { SelectEpic(e); dispatch(selectepic(e.target.value)) }} value={state_project.selectedepic} >
                            <option value=''>{state_project.selectedepic != "" ? "--Select All--" : "-- Select Epic --"}</option>
                            {(state_project.epiclist || []).map((data, index) => (
                                <option key={`epic-${index}`} value={data.key}>{data.relatedEpic}</option>
                            ))}
                        </select>
                    </div>
                    <div className="task-status-list">
                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    value="In Progress"
                                    onChange={(e) => handleCheckboxClick(e)}
                                    checked={state_project?.issuestatustype == "In Progress" ? true : false}
                                    input="true"
                                />
                                In Progress
                            </label>
                        </div>
                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    value="To Do"
                                    onChange={(e) => handleCheckboxClick(e)}
                                    checked={state_project?.issuestatustype == "To Do" ? true : false}
                                    input="true"
                                />
                                To Do
                            </label>
                        </div>
                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    value="Done"
                                    onChange={(e) => handleCheckboxClick(e)}
                                    checked={state_project?.issuestatustype == "Done" ? true : false}
                                    input="true"
                                />
                                Done
                            </label>
                        </div>

                    </div>

                    <div className="project-heading-wrap">
                        {state_project.issuelist.length == 0 && <span>No task found. Please select another status.</span>}
                    </div>
                    <div className="project-section" >

                        {/* {state_project.searchproject == false && <>
                            {(state_project.projectlist || []).map((data, index) => (
                                <div className="project-title-box-completed" key={index}
                                    style={data.projectid === state_project.selectedprojectid ? { border: "2px solid #42B847" } : {}}>

                                    <div className="project-title-row" onClick={() => getprojectdetails(data.projectid, data.projectname)} onScroll={getprojectlistpagination}>
                                        <div className="title-project">

                                            <p>{data.projectname}</p>

                                           {data.updatedAt!=undefined && <span>Project Date: {new Intl.DateTimeFormat('en-GB', {
                                                month: 'long',
                                                day: '2-digit',
                                                year: 'numeric',
                                            }).format(new Date(data.updatedAt))}</span>}
                                        </div>
                                        <div className="Completed-section">
                                            <div className="redio_cercle"></div>

                                            <p>{data.status}</p>
                                        </div>
                                    </div>

                                    <div className="project-details-wrap">
                                        <p>{data.projectdescription}</p>
                                    </div>
                                    <div className="project-details-wrap">
                                        <p>Total task: {data.task.length}</p>
                                    </div>
                                </div>
                            ))}</>}

                        {state_project.searchproject == true && <>
                            {(state_project.searchprojectlist || []).map((data, index) => (
                                <div className="project-title-box-completed" key={index}
                                    style={data.projectid === state_project.selectedprojectid ? { backgroundColor: "#FFFFFF;" } : { border: "2px solid #42B847" }}>

                                    <div className="project-title-row" onClick={() => getprojectdetails(data.projectid, data.projectname)}>
                                        <div className="title-project">

                                            <p>{data.projectname}</p>

                                            <span>{new Intl.DateTimeFormat('en-GB', {
                                                month: 'long',
                                                day: '2-digit',
                                                year: 'numeric',
                                            }).format(new Date(data.updatedAt))}</span>
                                        </div>
                                        <div className="Completed-section">
                                            <p>{data.status}</p>
                                        </div>
                                    </div>

                                    <div className="project-details-wrap" onClick={() => getprojectdetails(data.projectid, data.projectname)}>
                                        <p>{data.projectdescription}</p>
                                    </div>
                                </div>
                            ))}</>} */}





                        {(state_project?.issuelist || []).map((data, index) => (
                            <div className="project-title-box-completed" key={`issue-list${index}`} onClick={() => selectIssue(data)}
                                style={data.key === state_project.issueid ? {
                                    border: `2px solid ${(() => {
                                        switch ((data.status).toLowerCase()) {
                                            case 'to do':
                                                return '#E65F2B'; // Change this color code as needed
                                            case 'in progress':
                                                return '#DFA510'; // Change this color code as needed
                                            case 'done':
                                                return '#42B847'; // Change this color code as needed
                                            default:
                                                return '#42B847'; // Default border color if status doesn't match
                                        }
                                    })()}`
                                } : {}}>
                                {/* {data.relatedEpics.length != 0 && <div className="project-details-wrap">
                                    <p>Epic: {data.relatedEpics[0].summary}</p>
                                </div>} */}
                                <div className="" onClick={() => selectIssue(data)} key={`issue${index}`}>
                                    <div className="title-project">

                                        <div className="header-notification-row" style={{ "alignItems": "center", "marginBottom": "10px" }}>
                                            <div className="Completed-section">
                                                {/* <div className={`redio_cercle ${(data.status).toLowerCase() === 'to do' ? 'status-to-do-background-color' :
                                                    (data.status).toLowerCase() === 'in progress' ? 'status-in-progress-background-color' :
                                                        (data.status).toLowerCase() === 'done' ? 'status-done-background-color' : ''}`}></div> */}
                                                <p className={`project-taskstatus ${(data.status).toLowerCase() === 'to do' ? 'status-to-do-text' :
                                                    (data.status).toLowerCase() === 'in progress' ? 'status-in-progress-text' :
                                                        (data.status).toLowerCase() === 'done' ? 'status-done-text' : ''}`}>
                                                    {/* {data.issueType} */}
                                                    {(data.issueType).toLowerCase() === 'story' ? <img src="images/icons/Story.svg" /> : (data.issueType).toLowerCase() === 'task' ? <img src="images/icons/Task.svg" /> : (data.issueType).toLowerCase() === 'sub-task' ? <img src="images/icons/Sub-task.svg" /> : <img src="images/icons/Bug.svg" />} {data.key}</p>
                                            </div>
                                            <div className={`task-status ${(data.status).toLowerCase() === 'to do' ? 'status-to-do-bg status-to-do-text' :
                                                (data.status).toLowerCase() === 'in progress' ? 'status-in-progress-bg status-in-progress-text' :
                                                    (data.status).toLowerCase() === 'done' ? 'status-done-bg status-done-text' : ''}`}>{data.status}</div>

                                        </div>
                                        <h6>{data.summary}</h6>

                                        {/* {data.updatedAt != undefined && <span>Project Date: {new Intl.DateTimeFormat('en-GB', {
                                            month: 'long',
                                            day: '2-digit',
                                            year: 'numeric',
                                        }).format(new Date(data.updatedAt))}</span>} */}
                                    </div>
                                    <div className="Completed-section">
                                        {/* <div className={`task-status ${data.status === 'To Do' ? 'status-to-do-bg status-to-do-text' : 
                 data.status === 'In Progress' ? 'status-in-progress-bg status-in-progress-text' :
                 data.status === 'Done' ? 'status-done-bg status-done-text' : ''}`}>{data.status}</div> */}

                                        {/* <p>Subtasks: {data.subTasks.length}</p> */}
                                    </div>
                                </div>

                                {/* <div className="project-details-wrap">
                                    <p>{data.description}</p>
                                </div> */}
                                <div className="project-details-wrap">
                                    <p>Subtasks: {data.subTasks.length}</p>
                                    {/* <p>{data.issueType} - {data.key} - {data.jiraId}</p> */}
                                </div>
                            </div>
                        ))}


                    </div>
                </div>

            </div >
        </>
    );
}
export { ProjectList };