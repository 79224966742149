import * as types from "./groupTypes";

let groupobj = {
  grouplist: [],
  addgroup: false,
  groupdetails: false,
  searchgroup: false,
  searchgrouplist: [],
  singlegroup: {
    groupname: "",
    users: [],
    access:[],
    groupadmins:[]
  },
  singlegroupdetails: [],
  selectedgroupid: "",
  Is_created : false
};



export default function groupReducer(state = groupobj, { type, payload }) {
  switch (type) {


    case types.ADD_SINGLE_GROUP:
      groupobj.singlegroup.groupname = payload.groupname;
      groupobj.singlegroup.users = payload.users;

      return {
        ...state,
        loaded: true,
        loading: false,
        singlegroup: groupobj.singlegroup,
      };


    case types.GET_GROUP_LIST_SUCCESS:
      
      return {
        ...state,
        loaded: true,
        loading: false,
        grouplist: payload,
      }

    case types.VIEW_GROUP_ID_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        selectedgroupid: payload,
      }

    case types.ADD_GROUP_LIST_SUCCESS:
      
      return {
        ...state,
        loaded: true,
        loading: false,
        addgroup: payload,
      }

    case types.GET_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        groupdetails: payload,
      }

    case types.GET_SINGLE_GROUP_SUCCESS:
      
      return {
        ...state,
        loaded: true,
        loading: false,
        singlegroupdetails: payload,
      }

    case types.SEARCH_GROUP_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        searchgroup: payload,
      }
    case types.SEARCH_GROUP_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        searchgrouplist: payload,
      }
      case types.IS_GROUP_CREATE_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        Is_created: payload,
      }

    default:
      return state;
  }

}
