import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from 'sweetalert2'
import {
  user_signin,
} from "../../store/account/accountActions";
import { } from "../../firebase";
import { wrapup_users_login, wrapup_google_login, wrapup_users_updateprofile, get_user_profile, update_event } from "../../store/account/api"
import { get_user_access_details } from "../../store/access/api"
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { requestForToken } from "../../firebase";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { get_user_access_success } from "../../store/access/accessActions"
import { onboardingApp, landingApp } from "../../config"

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const state_account = useSelector(state => state.account);
  const logindata = state_account.profile
  const [passwordFieldType, setPasswordFieldType] = useState("password")
  const [firebasetoken, setFirebaseToken] = useState("");
  const auth = getAuth();
  const [view, setView] = useState(false)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const token = searchParams.get('token');

  useEffect(() => {
    requestNotificationPermission();
    requestForToken().then(val => { setFirebaseToken(val) });
    if (email && token) {
      checkForRedirectionFromOnboarding(email, token)
    }
  }, []);

  async function requestNotificationPermission() {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notifications.");
      return;
    }

    if (Notification.permission !== "granted") {
      try {
        if ("msSaveOrOpenBlob" in navigator) {
          const result = await navigator.permissions.query({ name: "notifications" });
          if (result.state === "granted") {
            const val = await requestForToken();
            setFirebaseToken(val);
            // Perform further actions for enabled notifications
          } else {
            console.log("Notification permission denied.");
          }
        } else {
          const permission = await Notification.requestPermission();
          if (permission === "granted") {
            const val = await requestForToken();
            setFirebaseToken(val);
            // Perform further actions for enabled notifications
          } else {
            console.log("Notification permission denied.");
          }
        }
      } catch (error) {
        console.log("Error requesting notification permission:", error);
      }
    }
  }
  const setCookie = (name, value, options = {}) => {
    let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; path=/; max-age=${options.maxAge || 0}`;
    if (options.domain) {
      cookieString += `; domain=${options.domain}`;
    }
    document.cookie = cookieString;
  };
  async function setTokentoCookie(token) {
    setCookie('authToken', token, {
      maxAge: 60 * 60 * 24 * 7, // 1 week (adjust as needed)
      domain: '.wrapup.live'
    });
  }
  async function setEmailtoCookie(email) {
    setCookie('userEmail', email, {
      maxAge: 60 * 60 * 24 * 7, // 1 week (adjust as needed)
      domain: '.wrapup.live'
    });
  }
  // console.log(firebasetoken)
  localStorage.setItem("firebasetoken", (firebasetoken))

  // console.log(state_account)
  async function closeNotification() {
    setShowNotification(false);
    setView(false)

  };

  const togglePasswordVisibility = () => {
    setPasswordFieldType(prevType => prevType === "password" ? "text" : "password");
  };
  async function handleChange(e) {
    setShowNotification(false)
    setView(false)
    if (e.target.name == "email") {
      logindata.email = (e.target.value)
      dispatch(user_signin(logindata))
    }
    else if (e.target.name == "password") {
      logindata.password = (e.target.value)
      dispatch(user_signin(logindata))
    }
  };

  async function datavalidation(logindata) {
    if (logindata.email == "") {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please submit email!',
      })
    }
    else if (logindata.password == "") {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please submit password!',
      })
    }

  }

  async function googlelogin() {
    debugger
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user
        // const payload = {
        //   email: user.email
        // }
        let displayname = user.displayName.split(' ');
        const payload = {
          "email": user.email,
          "firstname": displayname[0],
          "lastname": displayname[1],
          "profileimage": user.photoURL,
          "provider": "Google-login",
          "password": "null",
          "firebasetoken": firebasetoken
        }

        dispatch(wrapup_google_login(payload, (res) => {

          if (res.success == true) {
            if (res.data.org == undefined) {
              setEmailtoCookie(res.data.email)
              setTokentoCookie(res.data.token)
              window.location.href = onboardingApp;
            } else {
              localStorage.setItem("Token", res.data.token);
              setTokentoCookie(res.data.token)
              localStorage.setItem("UserEmail", user.email)
              setEmailtoCookie(user.email)
              const accessAsString = JSON.stringify(res.data.access);
              localStorage.setItem("Accessdata", accessAsString);
              const groupadminaccessAsString = JSON.stringify(res.data.groupadminaccess);
              localStorage.setItem("GroupadminAccessdata", groupadminaccessAsString);
              const eventpayload = {
                email: user.email
              }
              dispatch(update_event(res.data.token, eventpayload))
              const data = {
                "email": user.email,
                "firstname": res.data.firstname === undefined ? displayname[0] : res.data.firstname,
                "lastname": res.data.lastname === undefined ? displayname[1] : res.data.lastname,
                "profileimage": res.data.profileimage === undefined ? user.photoURL : res.data.profileimage,
                "provider": "Google-login",
                "firebasetoken": firebasetoken,
                "employeeid": res.data.employeeid
              }
              dispatch(wrapup_users_updateprofile(res.data.token, data));
              dispatch(get_user_access_details(payload, res.data.token, (result => {
                if (result.success == true) {
                  const value = result.data
                  let accessdata = []
                  if (value.length === 0) {
                    localStorage.setItem("Access", ("User"))
                    Swal.fire({
                      position: 'middle',
                      icon: 'success',
                      title: 'Your Task-Manager Google-login Successful in Wrap-up portal',
                      confirmButtonText: 'Ok',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        const planDetailsFromCookie = getPlanDetailsFromCookie();
                        if (planDetailsFromCookie) {
                          const { req } = planDetailsFromCookie;
                          if (req) {
                            navigate(landingApp);
                          }
                        }
                        navigate('/projects');
                      }
                    });
                  } else if (value.length > 0) {
                    value.forEach((grouptype) => {
                      accessdata.push(grouptype.grouptype);
                      if (grouptype.screens && grouptype.screens.length > 0) {
                        grouptype.screens.forEach((screens) => {
                          accessdata.push(screens.access);
                          accessdata.push(screens.type);
                        });
                      }
                    });
                    accessdata = accessdata.flat();
                    dispatch(get_user_access_success(accessdata))
                    localStorage.setItem("Access", (accessdata))
                    Swal.fire({
                      position: 'middle',
                      icon: 'success',
                      title: 'Your Task-Manager Google-login Successful in Wrap-up portal',
                      showCancelButton: true,
                      confirmButtonText: 'Ok',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        const planDetailsFromCookie = getPlanDetailsFromCookie();
                        if (planDetailsFromCookie) {
                          const { req } = planDetailsFromCookie;
                          if (req) {
                            navigate(landingApp);
                          }
                        }
                        navigate('/projects');
                      }
                    });
                  }
                }
              })))
            }

          }
          else {
            // setShowNotification(true)
            // setShowNotificationMessage(res.data.message)
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: (res.message),
            })

          }
        }));
      })
      .catch((error) => {
      });
  }

  function getPlanDetailsFromCookie() {
    const cookieValue = getCookie('selectedPlan');
    if (cookieValue) {
      return JSON.parse(decodeURIComponent(cookieValue));
    }
    return null;
  }
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
  async function login() {
    debugger
    await datavalidation(logindata);
    if (logindata != "null") {
      dispatch(wrapup_users_login(logindata, async (res) => {
        if (res.success == true) {
          if (res.data.org == undefined) {
            setTokentoCookie(res.data.token)
            setEmailtoCookie(res.data.email)
            window.location.href = onboardingApp;
          } else {
            localStorage.setItem("Token", res.data.token);
            setTokentoCookie(res.data.token)
            localStorage.setItem("UserEmail", logindata.email)
            // localStorage.setItem("Accessdata", res.data.access);
            const accessAsString = JSON.stringify(res.data.access);
            localStorage.setItem("Accessdata", accessAsString);
            const groupadminaccessAsString = JSON.stringify(res.data.groupadminaccess);
            localStorage.setItem("GroupadminAccessdata", groupadminaccessAsString);

            dispatch(get_user_profile(res.data.token, (result => {

              const data = {
                email: state_account.profile.email,
                firstname: state_account.profile.firstname,
                profileimage: state_account.profile.profileimage,
                lastname: state_account.profile.lastname,
                firebasetoken: firebasetoken,
                data: state_account.profile.data,
                employeeid: state_account.profile.employeeid,
              }
              dispatch(wrapup_users_updateprofile(res.data.token, data));

              // Update Firebase token in user table


              const payload = {
                email: logindata.email
              };

              dispatch(get_user_access_details(payload, res.data.token, async (accessresponse) => {
                if (accessresponse.success == true) {
                  const value = accessresponse.data;
                  let accessdata = [];

                  if (value.length === 0) {
                    localStorage.setItem("Access", "User");
                    Swal.fire({
                      position: 'middle',
                      icon: 'success',
                      title: 'Your Task-Manager Email-login Successful in Wrap-up portal',
                      confirmButtonText: 'Ok',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        const planDetailsFromCookie = getPlanDetailsFromCookie();
                        if (planDetailsFromCookie) {
                          const { req } = planDetailsFromCookie;
                          if (req) {
                            navigate(landingApp);
                          }
                        }
                        navigate('/projects');

                      }
                    });
                  } else if (value.length > 0) {
                    value.forEach((grouptype) => {
                      /* The above code is printing the value of the variable "grouptype" to the console. */
                      // console.log(grouptype);
                      accessdata.push(grouptype.grouptype);
                      if (grouptype.screens && grouptype.screens.length > 0) {
                        grouptype.screens.forEach((screens) => {
                          accessdata.push(screens.access);
                          accessdata.push(screens.type);
                        });
                      }
                    });

                    accessdata = accessdata.flat();
                    // console.log(accessdata);

                    dispatch(get_user_access_success(accessdata));
                    localStorage.setItem("Access", accessdata);

                    Swal.fire({
                      position: 'middle',
                      icon: 'success',
                      title: 'Your Task-Manager Email-login Sucessfull in Wrap-up portal',
                      confirmButtonText: 'Ok',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        const planDetailsFromCookie = getPlanDetailsFromCookie();
                        if (planDetailsFromCookie) {
                          const { req } = planDetailsFromCookie;
                          if (req) {
                            navigate(landingApp)
                          }
                        }
                        navigate("/projects")
                      }
                    })
                  }
                }
              }));
            })))
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "User not found",
          })

        }
      }
      ));
    }
  }

  async function checkForRedirectionFromOnboarding(emailvalue, tokenvalue) {
    debugger
    localStorage.setItem("Token", tokenvalue);
    setTokentoCookie(tokenvalue)
    localStorage.setItem("UserEmail", emailvalue)
    const eventpayload = {
      email: emailvalue
    }
    dispatch(update_event(tokenvalue, eventpayload))

    dispatch(get_user_access_details(eventpayload, tokenvalue, (res => {
      if (res.success == true) {
        const value = res.data
        let accessdata = []
        if (value.length === 0) {
          localStorage.setItem("Access", ("User"))
          Swal.fire({
            position: 'middle',
            icon: 'success',
            title: 'Your Task-Manager login Successful in Wrap-up portal',
            confirmButtonText: 'Ok',
          }).then((result) => {
            if (result.isConfirmed) {
              const planDetailsFromCookie = getPlanDetailsFromCookie();
              if (planDetailsFromCookie) {
                const { req } = planDetailsFromCookie;
                if (req) {
                  navigate(landingApp);
                }
              }
              navigate('/projects');

            }
          });
        } else if (value.length > 0) {
          value.forEach((grouptype) => {
            accessdata.push(grouptype.grouptype);
            if (grouptype.screens && grouptype.screens.length > 0) {
              grouptype.screens.forEach((screens) => {
                accessdata.push(screens.access);
                accessdata.push(screens.type);
              });
            }
          });
          accessdata = accessdata.flat();
          dispatch(get_user_access_success(accessdata))
          localStorage.setItem("Access", (accessdata))
          Swal.fire({
            position: 'middle',
            icon: 'success',
            title: 'Your Task-Manager login Successful in Wrap-up portal',
            showCancelButton: true,
            confirmButtonText: 'Ok',
          }).then((result) => {
            if (result.isConfirmed) {
              const planDetailsFromCookie = getPlanDetailsFromCookie();
              if (planDetailsFromCookie) {
                const { req } = planDetailsFromCookie;
                if (req) {
                  navigate(landingApp);
                }
              }
              navigate('/projects');
            }
          });
        }
      }
    })))



  }

  return (
    <>

      <div className="login-section-area">

        <div className="login-wrap">
          <div className="container">
            <div className="row">
              <div className="login-box-main">
                <div className="login-box">
                  <div className="logo-section">
                    <img src="/images/task manager/wrapup logo.svg" alt="" />
                  </div>
                  <div className="login-heading">
                    <p>Log In to Task Manager</p>
                    <h6>Enter your email and password below</h6>
                  </div>
                  <div className="login-information-section">
                    <div className="name-section">
                      <h3>Email</h3>
                      <input type="text" placeholder="Email addresss" className="product-form-control"
                        name="email" onChange={(e) => handleChange(e)} />
                    </div>
                    <div className="name-section">
                      <div className="pass-row">
                        <h3>password</h3>
                        <p><Link to={"/forgotpassward"}>Forgot password?</Link></p>
                      </div>
                      <p style={{ position: "relative" }}>
                        <input
                          type={passwordFieldType}
                          placeholder="Password"
                          className="product-form-control"
                          name="password"
                          onChange={handleChange}
                        />
                        {passwordFieldType === "password" ? (
                          <EyeFill className="eye-icon" onClick={togglePasswordVisibility} />
                        ) : (
                          <EyeSlashFill className="eye-icon" onClick={togglePasswordVisibility} />
                        )}
                      </p>
                    </div>
                    <div className="sale-feed-action">
                      <button type="button" className="sale-action-link" value="Submit" onClick={() => login()}>Log In</button>
                    </div>
                    <div className="account-sign">
                      <span>Don't have an account?</span><strong><Link to={"/signup"}> Sign up</Link></strong>
                    </div>
                    <div className="sale-feed-action" >
                      <img src="/images/googlelogin.svg" alt="" onClick={() => googlelogin()} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
      </div>

      {showNotification ? (
        <div className="addwishlist-pop-up-box">
          <div className="add-wishlist-row-section">
            <img src="/images/Icon metro-cancel.svg" alt="" onClick={closeNotification} />
            <h5>{showNotificationMessage}</h5>
          </div>
        </div>
      ) : (
        <></>
      )}


    </>
  );
}
export {
  Login
}
