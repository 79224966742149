import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { wrapupApi } from '../../config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { update_event } from "../../store/account/api"
import { get_user_profile, get_users_notification_count, users_notification, users_notification_seen } from "../../store/account/api";
import { get_notification_count_action, getnotificationlist } from "store/account/accountActions";
const WebSocketComponent = () => {
  const Token = localStorage.getItem("Token")
  const dispatch = useDispatch()
  const navigate = useNavigate();
  let isAllowedToUseApp = true;
  const offset = 0;
  const count = 100;
  //Public API that will echo messages sent to it back to the client
  // const [socketUrl, setSocketUrl] = useState('ws://localhost:3000');
  const [messageHistory, setMessageHistory] = useState([]);
  const UserEmail = localStorage.getItem("UserEmail")

  // const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  useEffect(() => {
    const eventSource = new EventSource(`${wrapupApi}/api/notification/eventnotification`);
    let eventData = null;
    eventSource.addEventListener('message', (event) => {
      if (event.data != "Initial Data" || event.data == null ) {
        eventData = JSON.parse(event.data);
        if (eventData.type === "Access") {
          if (eventData.email === UserEmail) {
            
            const payload = {
              email: UserEmail
            }
            dispatch(update_event(Token, payload, (res) => {
              if (res.success == true) {
                localStorage.clear();
                Swal.fire({
                  position: 'middle',
                  icon: 'success',
                  title: 'Session expired! Please log in again.',
                  showConfirmButton: true,
                  timer: 10000
                });
                localStorage.clear()
                navigate('/');
              }
            }))
            // eventSource.close();
          }
        }
        else if (eventData.type === "Notification") {
          
          if (eventData.email === UserEmail) {
            const payload = {
              email: UserEmail,
              pageno: offset,
              size: count,
            }
            dispatch(users_notification(Token, payload, (res) => {
              
            }))
            const data = {
              email: UserEmail
            }
            dispatch(get_users_notification_count(Token, data, (res => {
              
            })))

            // eventSource.close();
          }
        }
      }
    }, false);

    return () => {
      eventSource.close();
    };
  }, []);

  return <div></div>; // This component doesn't render anything in the UI
};
export {
  WebSocketComponent
}
