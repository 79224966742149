import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Createaccess, AccessList, SideBar, Accessdetails } from "../../components/index";
import { get_user_access_success } from "../../store/access/accessActions";
import { get_user_profile } from "../../store/account/api";
import { get_user_access_details } from "../../store/access/api"
import { Admin, Manager, User} from '../../config';



const Access = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showNotification, setShowNotification] = useState(false);
    const [showNotificationMessage, setShowNotificationMessage] = useState(false);
    const state_account = useSelector(state => state.account);
    const state_access = useSelector(state => state.access);
    const Token = localStorage.getItem("Token")
    const Getdata = localStorage.getItem("Access")
    const [addaccess, setaddaccess] = useState(false)
    async function closeNotification() {
        setShowNotification(false);
    };
    useEffect(() => {
        
        if (Token != null) {
            dispatch(get_user_profile(Token, (res => {
                dispatch(get_user_access_success(Getdata))


            })))
            if (Getdata.includes(`${Admin}`)) {
            } else {
                navigate('/');
            }

        }


    }, []);


    return (
        <>

            <div className="dashboard">
                <div className='dashboard-app'>
                    <div className='dashboard-content'>
                        <div className='container'>
                            <div className="row">
                                <SideBar />
                                <AccessList />

                                <div className="col-md-1">
                                    <div className="vl1">
                                    </div>
                                </div>
                                {state_access.addaccess ? (
                                    <Createaccess />
                                ) : (
                                    <></>
                                )}
                                {state_access.accessdetails ? (
                                    <Accessdetails />
                                ) : (
                                    <></>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showNotification ? (
                <div className="addwishlist-pop-up-box">
                    <div className="add-wishlist-row-section">
                        <img src="/images/Icon metro-cancel.svg" alt="" onClick={closeNotification} />
                        <h5>{showNotificationMessage}</h5>
                    </div>
                </div>
            ) : (
                <></>
            )}


        </>
    );
}
export {
    Access
}
