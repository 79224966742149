import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import { wrapup_users_register, wrapup_google_login, wrapup_users_updateprofile, update_event } from "../../store/account/api"
import {
  user_signin,
} from "../../store/account/accountActions";
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { get_user_access_details } from "../../store/access/api"
import { requestForToken } from "../../firebase";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { get_user_access_success } from "../../store/access/accessActions"
import { onboardingApp, landingApp } from "../../config"


const Signup = () => {
  const dispatch = useDispatch();
  const auth = getAuth();
  const [showNotification, setShowNotification] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const state_account = useSelector(state => state.account);
  const navigate = useNavigate();
  const signupdata = state_account.profile
  const [passwordFieldType, setPasswordFieldType] = useState("password")
  const [confirmPasswordFieldType, setConfirmPasswordFieldType] = useState("password")
  const [firebasetoken, setFirebaseToken] = useState("");




  useEffect(() => {
    requestNotificationPermission();
    requestForToken().then(val => { setFirebaseToken(val) });
  }, []);

  async function requestNotificationPermission() {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notifications.");
      return;
    }

    if (Notification.permission !== "granted") {
      try {
        if ("msSaveOrOpenBlob" in navigator) {
          const result = await navigator.permissions.query({ name: "notifications" });
          if (result.state === "granted") {
            const val = await requestForToken();
            setFirebaseToken(val);
            // Perform further actions for enabled notifications
          } else {
            console.log("Notification permission denied.");
          }
        } else {
          const permission = await Notification.requestPermission();
          if (permission === "granted") {
            const val = await requestForToken();
            setFirebaseToken(val);
            // Perform further actions for enabled notifications
          } else {
            console.log("Notification permission denied.");
          }
        }
      } catch (error) {
        console.log("Error requesting notification permission:", error);
      }
    }
  }
  const setCookie = (name, value, options = {}) => {
    let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; path=/; max-age=${options.maxAge || 0}`;
    if (options.domain) {
      cookieString += `; domain=${options.domain}`;
    }
    document.cookie = cookieString;
  };
  async function setTokentoCookie(token) {
    setCookie('authToken', token, {
      maxAge: 60 * 60 * 24 * 7, // 1 week (adjust as needed)
      domain: '.wrapup.live'
    });
  }
  async function setEmailtoCookie(email) {
    setCookie('userEmail', email, {
      maxAge: 60 * 60 * 24 * 7, // 1 week (adjust as needed)
      domain: '.wrapup.live'
    });
  }



  const togglePasswordVisibility = () => {
    setPasswordFieldType(prevType => prevType === "password" ? "text" : "password");
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordFieldType(prevType => prevType === "password" ? "text" : "password");
  };

  async function handleChange(e) {
    setShowNotification(false)
    if (e.target.name == "firstname") {
      signupdata.firstname = (e.target.value)
      dispatch(user_signin(signupdata))
    }
    else if (e.target.name == "lastname") {
      signupdata.lastname = (e.target.value)
      dispatch(user_signin(signupdata))
    }
    else if (e.target.name == "email") {
      signupdata.email = (e.target.value)
      dispatch(user_signin(signupdata))
    }

    else if (e.target.name == "password") {
      signupdata.password = (e.target.value)
      dispatch(user_signin(signupdata))
    }
    else if (e.target.name == "confirmpassword") {
      signupdata.confirmpassword = (e.target.value)
      dispatch(user_signin(signupdata))
    }
    signupdata.provider = "Email-login"
    dispatch(user_signin(signupdata))
    console.log(signupdata)
  };

  async function datavalidation(signupdata) {
    if (signupdata.email == "") {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please submit email!',
      })
    }
    else if (signupdata.firstname == "") {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please submit firstname!',
      })
    }
    else if (signupdata.lastname == "") {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please submit lastname!',
      })
    }
    else if (signupdata.password == "") {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please submit password!',
      })
    }
    else if (signupdata.confirmpassword == "") {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please submit confirm password!',
      })
    }

    else if (signupdata.password != signupdata.confirmpassword) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Password didn't match!",
      })
    }

  }
  async function register() {

    await datavalidation(signupdata)
    dispatch(wrapup_users_register(signupdata, (res => {
      if (res.success == true) {
        Swal.fire({
          position: 'middle',
          icon: 'success',
          title: 'You Sucessfully Registerd in Wrap-up Please login',
          showConfirmButton: false,
          timer: 1500
        })
        navigate("/")
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "user email already exists!",
        })
      }
    }))
    )
  }

  async function googlelogin() {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const displayname = user.displayName.split(' ');

      const payload = {
        email: user.email,
        firstname: displayname[0],
        lastname: displayname[1],
        profileimage: user.photoURL,
        provider: 'Google-login',
        password: 'null',
        firebasetoken: firebasetoken
      };

      dispatch(wrapup_google_login(payload, async (res) => {
        debugger
        if (res.success) {
          const data = res.data;
          if (data.org === undefined) {
            setEmailtoCookie(data.email);
            setTokentoCookie(data.token);
            window.location.href = onboardingApp;
          } else {
            localStorage.setItem('Token', data.token);
            setTokentoCookie(data.token);
            localStorage.setItem('UserEmail', user.email);
            setEmailtoCookie(user.email);

            const accessAsString = JSON.stringify(data.access);
            localStorage.setItem('Accessdata', accessAsString);
            const groupadminaccessAsString = JSON.stringify(data.groupadminaccess);
            localStorage.setItem('GroupadminAccessdata', groupadminaccessAsString);

            const eventpayload = { email: user.email };
            await dispatch(update_event(data.token, eventpayload));

            const userData = {
              email: user.email,
              firstname: data.firstname === undefined ? displayname[0] : data.firstname,
              lastname: data.lastname === undefined ? displayname[1] : data.lastname,
              profileimage: data.profileimage === undefined ? user.photoURL : data.profileimage,
              provider: 'Google-login',
              firebasetoken: firebasetoken,
              employeeid: data.employeeid
            };
            await dispatch(wrapup_users_updateprofile(data.token, userData));

            await dispatch(get_user_access_details(payload, data.token, async (result) => {
              if (result.success) {
                const value = result.data;
                let accessdata = [];
                if (value.length === 0) {
                  localStorage.setItem('Access', 'User');
                  Swal.fire({
                    position: 'middle',
                    icon: 'success',
                    title: 'Your Task-Manager Google-login Successful in Wrap-up portal',
                    confirmButtonText: 'Ok',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      const planDetailsFromCookie = getPlanDetailsFromCookie();
                      if (planDetailsFromCookie) {
                        const { req } = planDetailsFromCookie;
                        if (req) {
                          navigate(landingApp);
                        }
                      }
                      navigate('/projects');

                    }
                  });
                } else if (value.length > 0) {
                  value.forEach((grouptype) => {
                    accessdata.push(grouptype.grouptype);
                    if (grouptype.screens && grouptype.screens.length > 0) {
                      grouptype.screens.forEach((screens) => {
                        accessdata.push(screens.access);
                        accessdata.push(screens.type);
                      });
                    }
                  });
                  accessdata = accessdata.flat();
                  dispatch(get_user_access_success(accessdata));
                  localStorage.setItem('Access', accessdata);
                  Swal.fire({
                    position: 'middle',
                    icon: 'success',
                    title: 'Your Task-Manager Google-login Successful in Wrap-up portal',
                    showCancelButton: true,
                    confirmButtonText: 'Ok',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      const planDetailsFromCookie = getPlanDetailsFromCookie();
                      if (planDetailsFromCookie) {
                        const { req } = planDetailsFromCookie;
                        if (req) {
                          navigate(landingApp);
                        }
                        navigate('/projects');
                      }
                    }
                  });
                }
              }
            }));
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: res.message,
          });
        }
      }));
    } catch (error) {
      console.error('Google login error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An unexpected error occurred. Please try again later.',
      });
    }
  }


  function getPlanDetailsFromCookie() {
    const cookieValue = getCookie('selectedPlan');
    if (cookieValue) {
      return JSON.parse(decodeURIComponent(cookieValue));
    }
    return null;
  }
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }


  async function closeNotification() {
    setShowNotification(false);
  };


  return (
    <>

      <div className="login-section-area">
        <div className="signup-wrap">

          <div className="container">
            <div className="row">
              <div className="login-box-main">
                <div className="login-box">
                  <div className="logo-section">
                    <img src="images/task manager/wrapup logo.svg" alt="" />
                  </div>
                  <div className="login-heading">
                    <p>Sign up to Task Manager</p>
                    <h6>Enter your details below</h6>
                  </div>
                  <div className="login-information-section">
                    <div className="name-section">
                      <h3>FIRST NAME</h3>
                      <input type="text" placeholder="Your first name" className="product-form-control"
                        name="firstname" onChange={(e) => handleChange(e)} />
                    </div>
                    <div className="name-section">
                      <h3>LAST NAME</h3>
                      <input type="text" placeholder="Your last name" className="product-form-control"
                        name="lastname" onChange={(e) => handleChange(e)} />
                    </div>
                    <div className="name-section">
                      <h3>Email</h3>
                      <input type="text" placeholder="Email addresss" className="product-form-control"
                        name="email" onChange={(e) => handleChange(e)} />
                    </div>
                    <div className="name-section">
                      <h3>Password</h3>
                      <p style={{ position: "relative" }}>
                        <input
                          type={passwordFieldType}
                          placeholder="Password"
                          className="product-form-control"
                          name="password"
                          onChange={handleChange}
                        />
                        {passwordFieldType === "password" ? (
                          <EyeFill className="eye-icon" onClick={togglePasswordVisibility} />
                        ) : (
                          <EyeSlashFill className="eye-icon" onClick={togglePasswordVisibility} />
                        )}
                      </p>
                    </div>
                    <div className="name-section">
                      <h3>Confirm Password</h3>
                      <p style={{ position: "relative" }}>
                        <input
                          type={confirmPasswordFieldType}
                          placeholder="Confirm Password"
                          className="product-form-control"
                          name="confirmpassword"
                          onChange={handleChange}
                        />
                        {confirmPasswordFieldType === "password" ? (
                          <EyeFill className="eye-icon" onClick={toggleConfirmPasswordVisibility} />
                        ) : (
                          <EyeSlashFill className="eye-icon" onClick={toggleConfirmPasswordVisibility} />
                        )}
                      </p>
                    </div>
                    <div className="sale-feed-action">
                      <button type="button" className="sale-action-link" value="Submit" onClick={() => register()}>Sign up</button>
                    </div>
                    <div className="sale-feed-action" >
                      <img src="/images/googlelogin.svg" alt="" onClick={() => googlelogin()} />
                    </div>
                    <div className="account-sign">
                      <span>Already have an account?</span><strong><Link to={"/"}> Log in</Link></strong>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>

        </div>
      </div>
      {showNotification ? (
        <div className="addwishlist-pop-up-box">
          <div className="add-wishlist-row-section">
            <img src="/images/Icon metro-cancel.svg" alt="" onClick={closeNotification} />
            <h5>{showNotificationMessage}</h5>
          </div>
        </div>
      ) : (
        <></>
      )}

    </>
  );
}
export {
  Signup
}
