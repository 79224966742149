import { SideBar } from "components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { get_user_profile, get_users_notification_count, users_notification, users_notification_seen } from "../../store/account/api";
import React, { useEffect, useState } from "react";
import { get_notification_count_action, getnotificationlist } from "store/account/accountActions";
import { Admin, Manager, User } from '../../config';
import { get_project_details } from "store/projects/api";
import { view_project_id } from "../../store/projects/projectActions"
import moment from 'moment';


const Notification = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const state_account = useSelector(state => state.account);
    const notificationcountdata = state_account.notificationcount
    const Token = localStorage.getItem("Token")
    const Getdata = localStorage.getItem("Access")
    const [allNotification, SetAllNotification] = useState("")
    const [notifications, setNotifications] = useState([]);
    const [notificationcount, setNotificationcount] = useState("")
    const [seennotificationcount, setSeenNotificationcount] = useState("")
    const notificationidlist = [];

    const offset = 0;
    const count = 100;

    useEffect(() => {
        if (Token != null) {
            dispatch(get_user_profile(Token, (async res => {
                if (res.success == true) {
                    if (Getdata.includes(`${Admin}`) || Getdata.includes(`${Manager}`) || Getdata.includes(`${User}`)) {
                    } else {
                        navigate('/');
                    }

                    const payload = {
                        email: state_account.profile.email,
                        pageno: offset,
                        size: count,
                    }
                    dispatch(users_notification(Token, payload, (res) => {
                        const notificationdata = res
                        SetAllNotification(notificationdata)
                    }))
                    const data = {
                        email: state_account.profile.email
                    }
                    dispatch(get_users_notification_count(Token, data, (res => {
                    })))

                }
            })))
        }
    }, []);

    async function viewnotification(data) {

        console.log(data)
        const payload = {
            "notificationId": data._id
        }
        dispatch(users_notification_seen(Token, payload, (res) => {
            if (res.success == true) {
                const data = {
                    email: state_account.profile.email
                }
                dispatch(get_users_notification_count(Token, data, (res => {

                })))
                const payload = {
                    email: state_account.profile.email,
                    pageno: offset,
                    size: count,
                }
                dispatch(users_notification(Token, payload, (res) => {

                }))
            }

        }))

        // if (data.content.PROJECTID != undefined) {
        //     const payload = {
        //         projectid: data.content.PROJECTID
        //     }
        //     navigate("/projects")
        //     dispatch(view_project_id(data.content.PROJECTID))
        //     dispatch(get_project_details(payload))
        // }
    }

    return (
        <div>
            <div className='dashboard'>

                <SideBar />
                <div className='dashboard-app' id="main_section_toggle">
                    <div className="dashboard-container">
                        <div className="header-notification-row">
                            <div className="Notification-section">
                                <h4>Unread Notifications: {state_account.notificationcount}</h4>
                            </div>
                            <div className="user-section">
                                <div className="user-name">
                                    <h5>{state_account.profile.firstname} {state_account.profile.lastname}</h5>
                                </div>
                                <div className="user-upload-logo">
                                    <div className="profile-img-container">
                                        <img src={(state_account.profile.profileimage === undefined || state_account.profile.profileimage === '') ? '/images/icons/Default_profile.svg' : state_account.profile.profileimage} alt="" className="avatar-img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {state_account.notificationcount != 0 ? (<div className="full-page">
                            <div className="container-task">
                                {(state_account.notifications || []).map((data, index) => (
                                    <>{(data.type == "Task Reminder" || data.type == "Add Task" || data.type == 'Ticket Request' || data.type == 'Ticket updated' || data.type == 'Ticket Created' || data.type == 'Salary Add' || data.type == 'Salary updated' || data.type == 'Expense Request' || data.type == 'Expense updated' || data.type == "Reset Password") && <div className="container-task-time" key={index}>
                                        <div className="task-time-details" onClick={() => viewnotification(data)} style={{ cursor: "pointer" }}>
                                            <div className="task-time">
                                                {moment(data.createdAt).format('YYYY-MM-DD hh:mm A')}
                                            </div>
                                            <div className="task-details-info">
                                                <div className="task-details-head">
                                                    {data.type == "Task Reminder" && <h5 style={{ "margin": "0" }}>Task reminder for ({data.content.TASKNAME}). Deadline {data.content.DEADLINE}</h5>}

                                                    {(data.type == "Add Task" || data.type == 'Ticket Request' || data.type == 'Ticket updated' || data.type == 'Ticket Created' || data.type == 'Salary Add' || data.type == 'Salary updated' || data.type == 'Expense Request' || data.type == 'Expense updated' || data.type == "Reset Password") && <h5 style={{ "margin": "0" }}>{data.content.NOTIFICATION}.</h5>}
                                                </div>
                                                <div className={`task-details-head-view ${data.status ? "green" : "green"}`} style={{ color: "green" }}
                                                    onClick={() => viewnotification(data)}>
                                                    Mark as read
                                                </div>
                                            </div>
                                        </div>
                                    </div>}</>
                                ))}
                            </div>
                        </div>) :

                            (<div className="project-manager-section-box" style={{ "marginTop": "0px", "marginBottom": "20px" }}>
                                <div className="text-center">
                                    <img src="/images/icons/no-notification.svg" />
                                    <h5 className="my-0">You don't have any new notification </h5>
                                </div>
                            </div>)}


                    </div>
                </div>

            </div>
        </div>
    )
}
export {
    Notification
}