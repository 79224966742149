import * as types from "./taskTypes";




export function add_task_success(payload) {
  return {
    type: types.ADD_TASK_LIST_SUCCESS,
    payload: payload,
  };
}
export function create_event_success(payload) {
  return {
    type: types.CREATE_EVENT_SUCCESS,
    payload: payload,
  };
}
export function get_task_id(payload) {
  return {
    type: types.GET_SINGLE_TASK_ID,
    payload: payload,
  };
}
export function update_single_task(payload) {
  return {
    type: types.UPDATE_SINGLE_TASK,
    payload: payload,
  };
}
export function add_single_comment(payload) {
  return {
    type: types.ADD_COMMENT_SUCCESS,
    payload: payload,
  };
}
export function add_single_status(payload) {
  return {
    type: types.ADD_STATUS_SUCCESS,
    payload: payload,
  };
}
export function get_task_list_success(payload) {
  return {
    type: types.GET_TASK_LIST_SUCCESS,
    payload: payload,
  };
}

export function add_single_task(payload) {

  return {
    type: types.ADD_SINGLE_TASK,
    payload: payload,
  };
}
export function add_single_event(payload) {

  return {
    type: types.ADD_SINGLE_EVENT,
    payload: payload,
  };
}
export function get_task_details_success(payload) {
  return {
    type: types.GET_TASK_DETAILS_SUCCESS,
    payload: payload,
  };
}

export function get_single_user_task_timer_details_success(payload) {
  return {
    type: types.GET_SINGLE_USER_TASK_TIMER_DETAILS_SUCCESS,
    payload: payload,
  };
}


