import * as types from "./groupTypes";


export function get_group_list_success(payload) {
  
  return {
    type: types.GET_GROUP_LIST_SUCCESS,
    payload: payload,
  };
}

export function add_group_success(payload) {
  
  return {
    type: types.ADD_GROUP_LIST_SUCCESS,
    payload: payload,
  };
}
export function create_group_success(payload) {
  
  return {
    type: types.IS_GROUP_CREATE_SUCCESS,
    payload: payload,
  };
}

export function view_group_id(payload) {
  return {
    type: types.VIEW_GROUP_ID_SUCCESS,
    payload: payload,
  };
}


export function get_group_details_success(payload) {
  return {
    type: types.GET_GROUP_DETAILS_SUCCESS,
    payload: payload,
  };
}

export function add_single_group(payload) {
  return {
    type: types.ADD_SINGLE_GROUP,
    payload: payload,
  };
}

export function get_single_group_details_success(payload) {
  
  return {
    type: types.GET_SINGLE_GROUP_SUCCESS,
    payload: payload,
  };
}

export function search_group(payload) {
  return {
    type: types.SEARCH_GROUP_SUCCESS,
    payload: payload,
  };
}

export function search_group_list_success(payload) {
  return {
    type: types.SEARCH_GROUP_LIST_SUCCESS,
    payload: payload,
  };
}




