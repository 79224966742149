import React, { useEffect, useState } from "react";


const Onboarding1 = () => {
    return (
        <>
            <div className="onboarding-section onboarding-section1">
                <div className="text-center">
                    <img src="/images/task manager/wrapup logo.svg" width={"120px"} />
                    <h2>Welcome to WrapUp</h2>
                    <h6>Stay organized, stay ahead</h6>

                    <a href="/onboarding2"><button className="upload-own-image-button get-started-btn">Let’s Get Started
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="-3 0 16 10" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.47029 3.13671C8.2106 2.87702 7.78956 2.87702 7.52987 3.13671C7.27035 3.39624 7.27016 3.81696 7.52945 4.07671L10.7801 7.33317H3.33341C2.96522 7.33317 2.66675 7.63165 2.66675 7.99984C2.66675 8.36803 2.96522 8.6665 3.33341 8.6665H10.7801L7.52945 11.923C7.27016 12.1827 7.27035 12.6034 7.52987 12.863C7.78956 13.1227 8.2106 13.1226 8.47029 12.863L13.3334 7.99984L8.47029 3.13671Z" fill="white" /></svg></button></a>
                </div>
            </div>
        </>
    )
}

const Onboarding2 = () => {
    return (
        <>
            <div className="onboarding-section">
                <div className="col-md-10">
                    <h2>Account Setup</h2>
                    <p>Follow the steps to setup your wrapup account</p>

                    <div className="organization-box">
                        <h5>Add Your Organization name</h5>
                        <input type="text" placeholder="Start typing the name ..." />
                        <div className="text-center">
                            <div className="organization-box-row">
                                <a href="/onboarding3"><button className="upload-own-image-button go-next-btn">Save & go next</button></a>
                                <span>or</span>
                                <button className="upload-own-image-button press-enter-btn">press enter<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 10" fill="none"><g clip-path="url(#clip0_1298_1938)"><path d="M12 6.61584L12 6.6665C12 8.87584 10.2093 10.6665 8 10.6665L7.054 10.6665L4 10.6665" stroke="#B6B6B6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /><path d="M12 6.61328L12 3.33328" stroke="#B6B6B6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /><path d="M6 8.6665L4 10.6665L6 12.6665" stroke="#B6B6B6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></g><defs><clipPath id="clip0_1298_1938"><rect width="16" height="16" fill="white" transform="translate(16 16) rotate(180)" /></clipPath></defs></svg></button>
                            </div>
                        </div>

                        <div className="onboarding-progress-bar">
                            <div className="onboarding-progress-bar-box" style={{ "background": "#42B847" }}></div>
                            <div className="onboarding-progress-bar-box" style={{ "background": "#42B8474D" }}></div>
                            <div className="onboarding-progress-bar-box" style={{ "background": "#42B8474D" }}></div>
                            <div className="onboarding-progress-bar-box" style={{ "background": "#42B8474D" }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const Onboarding3 = () => {
    return (
        <>
            <div className="onboarding-section">
                <a href="/onboarding2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 110 90" fill="none">
                        <path d="M45 89C69.3027 89 89 69.3027 89 45C89 20.6973 69.3027 1.00002 45 1.00001C20.6973 1.00001 1.00001 20.6973 1 45C1 69.3027 20.6973 89 45 89Z" stroke="#42B847" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M25.445 45L64.5561 45" stroke="#42B847" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M40.1116 29.6667L25.445 44.3333L40.1116 59" stroke="#42B847" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </a>
                <div className="col-md-10">
                    <h2>Account Setup</h2>
                    <p>Follow the steps to setup your wrapup account</p>

                    <div className="organization-box">
                        <h5>Total Number of Employee</h5>
                        <div className="employee-number">
                            <div className="employee-number-box">
                                <div><input type="radio" name="employee_number" /></div>
                                <span>10-20</span>
                            </div>
                            <div className="employee-number-box">
                                <div><input type="radio" name="employee_number" /></div>
                                <span>20-50</span>
                            </div>
                            <div className="employee-number-box">
                                <div><input type="radio" name="employee_number" /></div>
                                <span>50-100</span>
                            </div>
                            <div className="employee-number-box">
                                <div><input type="radio" name="employee_number" /></div>
                                <span>Above 100</span>
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="organization-box-row">
                                <a href="/onboarding4"><button className="upload-own-image-button go-next-btn">Save & go next</button></a>
                                <span>or</span>
                                <button className="upload-own-image-button press-enter-btn">press enter<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 10" fill="none"><g clip-path="url(#clip0_1298_1938)"><path d="M12 6.61584L12 6.6665C12 8.87584 10.2093 10.6665 8 10.6665L7.054 10.6665L4 10.6665" stroke="#B6B6B6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /><path d="M12 6.61328L12 3.33328" stroke="#B6B6B6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /><path d="M6 8.6665L4 10.6665L6 12.6665" stroke="#B6B6B6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></g><defs><clipPath id="clip0_1298_1938"><rect width="16" height="16" fill="white" transform="translate(16 16) rotate(180)" /></clipPath></defs></svg></button>
                            </div>
                        </div>

                        <div className="onboarding-progress-bar">
                            <div className="onboarding-progress-bar-box" style={{ "background": "#42B847" }}></div>
                            <div className="onboarding-progress-bar-box" style={{ "background": "#42B847" }}></div>
                            <div className="onboarding-progress-bar-box" style={{ "background": "#42B8474D" }}></div>
                            <div className="onboarding-progress-bar-box" style={{ "background": "#42B8474D" }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const Onboarding4 = () => {
    return (
        <>
            <div className="onboarding-section">
                <a href="/onboarding3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 62 120" fill="none">
                        <path d="M45 89C69.3027 89 89 69.3027 89 45C89 20.6973 69.3027 1.00002 45 1.00001C20.6973 1.00001 1.00001 20.6973 1 45C1 69.3027 20.6973 89 45 89Z" stroke="#42B847" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M25.445 45L64.5561 45" stroke="#42B847" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M40.1116 29.6667L25.445 44.3333L40.1116 59" stroke="#42B847" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </a>
                <div className="col-md-10">
                    <h2>Account Setup</h2>
                    <p>Follow the steps to setup your wrapup account</p>

                    <div className="organization-box">
                        <h5>Do you want to fetch your tasks from Jira or create tasks on Wrapup?</h5>
                        <div className="task-box-row">
                            <div className="task-box">Fetch tasks from jira account</div>
                            <div className="task-box">Create task on wrapup</div>
                        </div>
                        <div className="text-center">
                            <div className="organization-box-row">
                                <a href="#"><button className="upload-own-image-button go-next-btn">Save & go next</button></a>
                                <span>or</span>
                                <button className="upload-own-image-button press-enter-btn">press enter<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 10" fill="none"><g clip-path="url(#clip0_1298_1938)"><path d="M12 6.61584L12 6.6665C12 8.87584 10.2093 10.6665 8 10.6665L7.054 10.6665L4 10.6665" stroke="#B6B6B6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /><path d="M12 6.61328L12 3.33328" stroke="#B6B6B6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /><path d="M6 8.6665L4 10.6665L6 12.6665" stroke="#B6B6B6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></g><defs><clipPath id="clip0_1298_1938"><rect width="16" height="16" fill="white" transform="translate(16 16) rotate(180)" /></clipPath></defs></svg></button>
                            </div>
                        </div>

                        <div className="onboarding-progress-bar">
                            <div className="onboarding-progress-bar-box" style={{ "background": "#42B847" }}></div>
                            <div className="onboarding-progress-bar-box" style={{ "background": "#42B8474D" }}></div>
                            <div className="onboarding-progress-bar-box" style={{ "background": "#42B8474D" }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const Onboarding5 = () => {
    return (
        <>
            {/* <div className="onboarding-section">
                <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 62 120" fill="none">
                    <path d="M45 89C69.3027 89 89 69.3027 89 45C89 20.6973 69.3027 1.00002 45 1.00001C20.6973 1.00001 1.00001 20.6973 1 45C1 69.3027 20.6973 89 45 89Z" stroke="#42B847" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M25.445 45L64.5561 45" stroke="#42B847" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M40.1116 29.6667L25.445 44.3333L40.1116 59" stroke="#42B847" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <div className="col-md-10">
                    <h2>Account Setup</h2>
                    <p>Follow the steps to setup your wrapup account</p>

                    <div className="organization-box">
                        <h5>Do you want to fetch your tasks from Jira or create tasks on Wrapup?</h5>
                        <div className="task-box-row">
                            <div className="task-box">Fetch tasks from jira account</div>
                            <div className="task-box">Create task on wrapup</div>
                        </div>
                        <div className="text-center">
                            <a href="#"><button className="upload-own-image-button go-next-btn">Fetch Jira Data</button></a>
                        </div>

                        <div className="onboarding-progress-bar">
                            <div className="onboarding-progress-bar-box" style={{ "background": "#42B847" }}></div>
                            <div className="onboarding-progress-bar-box" style={{ "background": "#42B8474D" }}></div>
                            <div className="onboarding-progress-bar-box" style={{ "background": "#42B8474D" }}></div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}


export {
    Onboarding1,
    Onboarding2,
    Onboarding3,
    Onboarding4,
    Onboarding5
}
