export const USER_SIGNUP = 'USER_SIGNUP';
export const USER_SIGNIN = 'USER_SIGNIN';
export const GET_ALL_EMPLOYEE_SUCCESS = 'GET_ALL_EMPLOYEE_SUCCESS';
export const GET_ALL_MANAGER_SUCCESS = 'GET_ALL_MANAGER_SUCCESS';
export const GET_ALL_USER_SUCCESS = 'GET_ALL_USER_SUCCESS';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const OTP_VARIFICATION_SUCCESS = 'OTP_VARIFICATION_SUCCESS';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const USER_GOOGLE_SIGNIN = 'USER_GOOGLE_SIGNIN';
export const USER_NOTIFICATION = 'USER_NOTIFICATION';
export const NOTIFICATION_COUNT= 'NOTIFICATION_COUNT';
export const USER_GOOGLE_VERIFICATION = 'USER_GOOGLE_VERIFICATION';
export const NOTIFICATION_LIST_SUCCESS= 'NOTIFICATION_LIST_SUCCESS';



