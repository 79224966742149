import * as types from "./accessTypes";


export function get_access_list_success(payload) {
  
  return {
    type: types.GET_ACCESS_LIST_SUCCESS,
    payload: payload,
  };
}

export function add_access_success(payload) {
  
  return {
    type: types.ADD_ACCESS_LIST_SUCCESS,
    payload: payload,
  };
}

export function view_access_id(payload) {
  return {
    type: types.VIEW_ACCESS_ID_SUCCESS,
    payload: payload,
  };
}


export function get_access_details_success(payload) {
  return {
    type: types.GET_ACCESS_DETAILS_SUCCESS,
    payload: payload,
  };
}

export function add_single_access(payload) {
  return {
    type: types.ADD_SINGLE_ACCESS,
    payload: payload,
  };
}

export function get_single_access_details_success(payload) {
  
  return {
    type: types.GET_SINGLE_ACCESS_SUCCESS,
    payload: payload,
  };
}
export function create_access_success(payload) {
  
  return {
    type: types.IS_ACCESS_CREATE_SUCCESS,
    payload: payload,
  };
}
export function get_user_access_success(payload) {
  
  
  return {
    type: types.USER_ACCESS_GET_SUCCESS,
    payload: payload,
  };
}
export function get_user_sidebar_access_success(payload) {
  
  
  return {
    type: types.USER_SIDEBAR_ACCESS_GET_SUCCESS,
    payload: payload,
  };
}

export function search_access(payload) {
  return {
    type: types.SEARCH_ACCESS_SUCCESS,
    payload: payload,
  };
}

export function search_access_list_success(payload) {
  return {
    type: types.SEARCH_ACCESS_LIST_SUCCESS,
    payload: payload,
  };
}




