import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from 'react-router-dom';
import $ from "jquery";
import { get_user_profile } from "../../store/account/api";
import { get_group_list_pagination, get_search_group_list_pagination } from "store/groups/api";
import { add_group_success, search_group, get_group_details_success,create_group_success, get_group_list_success, get_single_group_details_success, view_group_id, add_single_group, search_group_list_success } from "store/groups/groupActions";
import { get_group_details } from "store/groups/api";
import { green } from "@material-ui/core/colors";
const GroupList = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const state_account = useSelector(state => state.account);
    const state_group = useSelector(state => state.group);
    const Token = localStorage.getItem("Token")
    const [pageno, setpageno] = useState(0)
    // const groups = state_group.grouplist
    const [group, setgroup] = useState([])
    const [count, setcount] = useState(5)
    const { id } = useParams();
    const newgroup = {
        groupname: "",
        users: [],
        access: [],
        groupadmins: []
    }
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {

        if (Token != null) {
            dispatch(get_user_profile(Token, (res => {
                if (res.success == true) {
                    getgrouplistpagination(pageno)
                    getgroupdetails()
                }
            })))
        }


    }, []);

    async function getgrouplistpagination(data) {
        // const payload = {
        //     pageno: data,
        //     count: count,
        //     user: state_account.profile
        // }
        // dispatch(get_group_list_pagination(Token, payload, (res) => {
        //     if (res.success == true) {
        //         let value = res.data
        //         value.forEach(i => {
        //             groups.push(i)
        //         })
        //         get_group_list_success(groups)
        //         setgroup(groups)
        //     }
        // }))
    }
    async function scrollFunction() {

        if ((window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 400)) {
            setpageno(pageno + 1)
            getgrouplistpagination(pageno + 1)
        }
    }
    async function opengroupform() {
        dispatch(get_single_group_details_success(newgroup))
        dispatch(add_group_success(true))
        dispatch(create_group_success(false))
        dispatch(get_group_details_success(false))
        navigate('/groups')
    }

    async function getgroupdetails() {

        dispatch(get_group_details(Token))
        dispatch(add_group_success(false))
        dispatch(get_group_details_success(false))
        navigate(`/groups`)

    }
    async function getgroupdata(data) {
        if (!data.groupadmins) {
            data.groupadmins = []
        }
        // console.log(data)
        dispatch(get_group_details(Token))
        dispatch(get_single_group_details_success(data))
        dispatch(add_group_success(false))
        dispatch(get_group_details_success(true))
    }

    // async function getsearchtext(e) {
    //     if (e.target.value.length > 1) {
    //         dispatch(search_group(true))
    //         console.log(e.target.value)
    //         getgrouplistpaginationbysearch(0, e.target.value)

    //     }
    //     else {
    //         dispatch(search_group(false))
    //     }
    // }

    async function getgrouplistpaginationbysearch(data, searchvalue) {
        const payload = {
            pageno: data,
            count: 1000,
            user: state_account.profile,
            search: searchvalue
        }
        let searchgroups = []
        dispatch(get_search_group_list_pagination(Token, payload, (res) => {
            if (res.success == true) {
                let value = res.data
                value.forEach(i => {
                    searchgroups.push(i)
                })
                dispatch(search_group_list_success(searchgroups))
            }
        }))
    }
    return (
        <>
            <div className="col-md-4" >
                <div className="project-heading" >
                    {/* <div className="task-group-row">
                        <div className="col-md-3">
                            <div className="project-heading-wrap">
                                <p>Groups</p>
                            </div>
                        </div>
                        <div className="col-md-2">

                        </div>
                        <div className="col-md-5">
                            <div className="project-manager-name">
                                <h6>{state_account.profile.firstname} {state_account.profile.lastname}</h6>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="manager-img">
                                {state_account.profile.profileimage != "" ? (<><img className="profile-photo-image" src={state_account.profile.profileimage} /></>) : (
                                    <><img className="profile-photo-image" src="images/settings/avatar.png" /></>
                                )}

                            </div>
                        </div>
                    </div> */}
                    <div className="search-section">
                        <input type="text" className="search-form-control" input="true"
                            placeholder="Search group.."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)} />

                    </div>
                    <div className="create-box-section-group-row">
                        <div className="add-new-group-row" onClick={() => opengroupform()}>
                            <p>Create new group</p>
                            <img src="images/task manager/active.svg" alt="" />
                        </div>
                    </div>


                    <div className="project-section" style={{ "height": "74vh" }}>

                        {(state_group.grouplist || []).filter(data => {

                            const matchesSearchQuery =
                                data.groupname.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                data.users.some(item =>
                                    item.toLowerCase().includes(searchQuery.toLowerCase())
                                )
                            return matchesSearchQuery;
                        }).map((data, index) => (

                            // {(state_group.grouplist || []).map((data, index) => (

                            <div className="project-title-box-completed" key={`Group-List${index}`}
                                style={data._id === state_group.singlegroupdetails._id ? { border: "2px solid #42B847" } : {}} onClick={() => getgroupdata(data)}>

                                <div className="project-title-row" onClick={() => getgroupdata(data)}>
                                    <div className="title-project" >
                                        <p>{data.groupname}</p>
                                    </div>
                                    <div className="Completed-section">
                                        <div className="redio_cercle">

                                        </div>
                                        <p>{new Intl.DateTimeFormat('en-GB', {
                                            month: 'long',
                                            day: '2-digit',
                                            year: 'numeric',
                                        }).format(new Date(data.updatedAt))}</p>
                                    </div>
                                </div>
                                {/* {data.userdetails.map(user => ( */}
                                <div className="" >
                                    <p>{data.userdetails.length} Members</p>
                                </div>
                                {/* ))} */}
                            </div>))}
                    </div>
                </div>
            </div>
        </>

    );
}
export { GroupList };