import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { get_employees, get_users, get_user_profile } from "../../store/account/api";
import { add_single_task, add_task_success, update_single_task } from "store/task/taskActions"
import { create_task, update_task } from "../../store/task/api"
import { uploadfile } from "store/account/api";
import * as FormData from 'form-data';
import Swal from 'sweetalert2'
import { add_single_project, add_project_success, get_project_details_success, view_project_id, get_project_list_success } from "store/projects/projectActions"
import { create_project, get_project_details, get_project_list_pagination } from "../../store/projects/api"



const Updatetask = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const state_account = useSelector(state => state.account);
  const state_project = useSelector(state => state.project);
  const state_task = useSelector(state => state.task);
  const Token = localStorage.getItem("Token")
  const [showNotification, setShowNotification] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const singletaskdata = state_task.singletask;
  let tasklist = state_task.tasklist;
  const [selectmail, setselectmail] = useState("")
  const [selectemail, setselectEmail] = useState("")
  const [mail, setMail] = useState([])
  const [email, setEmail] = useState([])
  const [file, setFile] = useState([])


  useEffect(() => {
    if (Token != null) {
      dispatch(get_user_profile(Token, (res => {
        if (res.success == true) {
          dispatch(get_users(Token))
        }
      })))
    }
  }, []);


  const [task_visible, setTask_visible] = useState(true)
  const [add_task_visible, setAdd_task_visible] = useState(false);





  async function addCommentonTask() {
    Swal.fire({
      title: 'Do you want to edit the Task Details?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
  }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
          Swal.fire('confirmed!', '', 'success')
          dispatch(update_task(Token, singletaskdata, (res => {
            if (res.success == true) {
              Swal.fire({
                position: 'middle',
                icon: 'success',
                title: 'Your task details sucessfull updated in Wrap-up',
                showConfirmButton: false,
                timer: 1500
              })
              dispatch(add_task_success([]))
              const payload = {
                projectid: state_project.singleprojectdetails[0].projectid
              }
              dispatch(get_project_details(payload))
              dispatch(add_project_success(false))
              dispatch(get_project_details_success(true))
              dispatch(view_project_id(state_project.singleprojectdetails[0].projectid))
              dispatch(update_single_task(false))
              const newtask = {
                projectid: "",
                taskname: "",
                taskdescription: "",
                taskdetails: "",
                taskdeadline: Date,
                taskemployee: [],
                files: [],
                comments: [],
                status: ""
              }
              dispatch(add_single_task(newtask))
            }
            else {
              console.log("Invalid response from API");
            }
          })))
      } else if (result.dismiss === Swal.DismissReason.cancel) { // Check for cancel button dismissal
          Swal.fire('Changes are not saved', '', 'info')
        }
  })
   
  }


  async function handleChangetask(f) {
    if (f.target.name == "taskname") {
      singletaskdata.taskname = (f.target.value)
      dispatch(add_single_task(singletaskdata))
    }
    else if (f.target.name == "taskdescription") {
      singletaskdata.taskdescription = (f.target.value)
      dispatch(add_single_task(singletaskdata))
    }
    else if (f.target.name == "taskdeadline") {
      singletaskdata.taskdeadline = (f.target.value)
      dispatch(add_single_task(singletaskdata))
    }
    console.log(singletaskdata)
  };



  async function dropdownselectEmployee(f) {
    setselectEmail(f.target.value)

  };
  async function employeeSelection() {
    if (selectemail != null || selectemail != "") {
      if (singletaskdata.asignedto.includes(selectemail)) {
      } else {
        singletaskdata.asignedto.push(selectemail)
        dispatch(add_single_task(singletaskdata))
      }
     
    }
  }


  async function deleatEmployee(e) {
    ;
    const s = singletaskdata.asignedto.filter((data, index) => index !== e)
    singletaskdata.asignedto = s
    dispatch(add_single_task(singletaskdata))
    console.log(s);
  }



  async function uploadimage(singlefile) {
    const formData = new FormData();

    formData.append(
      "file",
      singlefile
    );

    dispatch(uploadfile(formData, res => {

      console.log(res);
      let filedata = {
        "url": res.data.fileurl,
        "filename": res.data.filename,
        "uploadedby": state_account.profile.email,
        "uploadeddate": new Date()
      }
      singletaskdata.files.push(filedata)
      dispatch(add_single_task(singletaskdata))
    }
    ))
  }

  async function uploadFile(e) {
    if (e) {
      for (let i = 0; i < e.target.files.length; i++) {
        await uploadimage(e.target.files[i])
      }

    }


  }
  async function deleteFile(e) {
    const s = singletaskdata.files.filter((item, index) => index !== e);
    singletaskdata.files = s
    console.log(s);
    dispatch(add_single_task(singletaskdata))
  }
  console.log(state_project.singleprojectdetails[0])


  return (
    <div className="col-md-5" id="projectdetails" style={{ "overflowY": "auto", "height": "650px" }}>

      <div>
        <div className="task-heading">
          <p>Edit Tasks</p>
          <p>Project Id: {state_project.singleprojectdetails[0].projectid}</p>
        </div>
      </div>
      {/* {(state_task.tasklist || []).map((data, index) => ( */}
      <> <div className="project-name">
        <p>Task name</p>
        <div className="name-section">
          <input
            type="text"
            placeholder="Write your Task name "
            className="project-form-control"
            name="taskname"
            onChange={(f) => handleChangetask(f)} value={singletaskdata.taskname}
          />
        </div>
      </div>
        <div className="project-name">
          <p>Task description</p>
          <div className="name-section">
            <textarea
              type="text"
              placeholder="Write your Task description here "
              name="taskdescription"
              onChange={(f) => handleChangetask(f)} value={singletaskdata.taskdescription}
            ></textarea>
          </div>
        </div>
        <div className="project-name">
          <p>Task due date</p>
          <div className="name-section">
            <input
              type="date"
              id="appt"
              name="taskdeadline"
              placeholder="Select your due date"
              className="project-form-control"
              onChange={(f) => handleChangetask(f)} 
              defaultValue={singletaskdata.taskdeadline != "" ? (new Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" }).format(Date.now(singletaskdata.taskdeadline))) : "DD MMMM YYYY"}
            />
          </div>
        </div>
        <div className="project-name">
          <p>Assign task to</p>
          <div className="name-section">
            <select id="employee"
              className="project-form-control" name="manufacture_part_number" list="employee" onChange={(e) => dropdownselectEmployee(e)}>
              <option value="null" >--Select Employee--</option>

              {(state_account.users || []).map((data, index) => (
                <option multiple={true} value={data.email} >{data.email}</option>
              ))}
            </select>

            <div className="newadd">

              <div className="project-upload-section">
                <div className="image-upload-box1">
                  <button onClick={() => { employeeSelection(email) }} type="button" className="upload-own-image-button">
                    Invite</button>
                </div>
              </div>
            </div>
            {(singletaskdata.asignedto || []).map((data, index) => (
              <div style={{ "marginBottom": "2px", "marginRight": "2px" }} className="badge badge-pill badge-primary">{data} <span style={{ "color": "#ffb0af" }} className="badge badge-light" />
                <button type="button" className="badge badge-pill badge-primary" onClick={() => deleatEmployee(index)}>X</button>
              </div>))}
          </div>
          <div className="form-group preview">
            {singletaskdata.files !=undefined && singletaskdata.files.length >0 &&
              singletaskdata.files.map((item, index) => {
                return (
                  <div className="image-upload-box1" key={index}>
                    <div className="img-cercle">
                      <img src={item.url} alt="" className="main-img" />
                    </div>
                    <button type="button" className="upload-own-image-button" onClick={() => deleteFile(index)}>
                      Delete
                    </button>
                  </div>
                );
              })}
          </div>
          <div className="project-upload-section">
            <div className="image-upload-box1">
              <input
                type="file" multiple="multiple"
                disabled={file.length === 1}
                className="form-control"
                onChange={(e) => uploadFile(e)}
              />
              <button type="button" className="upload-own-image-button" >Upload
                document</button>

              <div>
              </div>
            </div>
          </div>
          <div>
            <div>
            </div>
            <div className="project-submit-section border_bottom pb">
              <button onClick={() => addCommentonTask()} type="button" className="btn btn-success submit-own-image-button">submit
              </button>
            </div>
          </div>
        </div>
      </>
      {/* ))} */}

    </div>

  );
}
export { Updatetask };

