import React from "react";
import RenderRoutes from "routes";


import "@fortawesome/fontawesome-free/css/all.min.css";
// import "@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css";

const App = () => {
  return (
     <>   
     <RenderRoutes />
     </>
    

    // <ErrorBoundary
    //   FallbackComponent={ErrorHandler}
    //   onReset={() => navigate("/")}
    // >
    //   <RenderRoutes />
    //   <SweetAlert
    //     title=""
    //     show={state_error.show}
    //     error
    //     confirmBtnText="Oops!"
    //     onConfirm={() => dispatch(clear_error())}
    //     confirmBtnCssClass="button is-danger"
    //   >
    //     {state_error.message}
    //   </SweetAlert>
    // </ErrorBoundary>
  );
};

// const ErrorHandler = ({ error, componentStack, resetErrorBoundary }) => {
//   return (
//     <>Error</>
//     // <SweetAlert
//     //   show
//     //   error
//     //   title="Oops!"
//     //   confirmBtnText="Go To Homepage"
//     //   onConfirm={resetErrorBoundary}
//     //   confirmBtnCssClass="button is-danger"
//     // >
//     //   An error occured while attempting to display this page.
//     // </SweetAlert>
//   );
// };

export default App;
