import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import $ from "jquery";
import Swal from 'sweetalert2'
import { get_users, get_user_profile } from "../../store/account/api";
import { get_group_details } from "../../store/groups/api";
import { add_access_success, get_access_details_success, add_single_access, create_access_success } from "store/access/accessActions"

const Accessdetails = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const state_account = useSelector(state => state.account);
    const [showEventmodal, setShowEventmodal] = useState(false);
    const state_access = useSelector(state => state.access);
    const state_project = useSelector(state => state.project);

    const [email, setEmail] = useState([])
    const [selectemail, setselectEmail] = useState("")
    const Token = localStorage.getItem("Token")
    const [pageno, setpageno] = useState(0)
    const [count, setcount] = useState(20)
    // dispatch(get_user_profile(Token))

    // useEffect(() => {
    //     scrollToTop()
    // }, [state_access.singleaccessdetails]);

    useEffect(() => {

        if (Token != null) {
            dispatch(get_user_profile(Token, (res => {
                if (res.success == true) {
                    dispatch(get_users(Token))
                    dispatch(get_group_details(Token))


                }
            })))
        }
    }, []);






    async function editaccess() {

        Swal.fire({
            title: 'Do you want to edit the access Details?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Swal.fire('confirmed!', '', 'success')
                dispatch(add_single_access(state_access.singleaccessdetails))
                dispatch(add_access_success(true))
                dispatch(create_access_success(true))
                dispatch(get_access_details_success(false))
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })


    }

    async function editaccessforproject() {
        Swal.fire({
            title: 'Do you want to edit the access Details?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Swal.fire('confirmed!', '', 'success')
                dispatch(add_access_success(true))
                dispatch(create_access_success(true))
                dispatch(get_access_details_success(false))
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })


    }

    console.log(state_access.singleaccessdetails)

    return (
        <>

            <div className="col-md-5 projectdetails-size" id="accessdetails" style={{ "overflowY": "auto", "height": "650px" }}>
                <div>
                    <div className="task-manager-title">
                        <h6>Access Details</h6>
                    </div>
                    <div className="">
                        <div className="add-new">
                            <p>Edit Access Type</p>
                            <img onClick={() => editaccess()} src="images/settings/icon_edit.png" alt="" />
                        </div>
                        <div className="project-type-details">
                            <div className="project-manager-title">
                                <h6>Access Type :- <span>{state_access.singleaccessdetails.grouptype}</span> </h6>

                            </div>
                            {state_access.singleaccessdetails.screens.map(data => (
                                <div className="project-manager-title">
                                    <h6>Access Screens :- <span>{data.access}: {data.type}</span> </h6>
                                </div>))}

                            <div className="project-manager-title">
                                <div className="title-project">
                                    <span>Created on: <h6>{new Intl.DateTimeFormat('en-GB', {
                                        month: 'long',
                                        day: '2-digit',
                                        year: 'numeric',
                                    }).format(new Date(state_access.singleaccessdetails.updatedAt))}</h6></span>

                                </div>

                                <div className="project-details-wrap-section">

                                </div>
                            </div>
                        </div>
                        <div className="project-manager-section">
                           
                            <p>{state_access.singleaccessdetails.groups} </p>
                            <div className="project-manager-title">
                                <h6>Membars Access</h6>
                            </div>
                            {state_access.singleaccessdetails.userdetails.map(user => (
                                <div className="manager-row" key={user._id}>
                                    <p>{user.firstname} {user.lastname} - {user.email}</p>
                                </div>
                            ))}
                        </div>

                    </div>

                </div>
                {/* {state_project.selectedprojectdetails != "" &&
                    <div>
                        <div className="task-manager-title">
                            <h6>Project and Access Details</h6>
                        </div>
                        <div className="">
                            <div className="add-new">
                                <p>Edit Access</p>
                                <img onClick={() => editaccessforproject()} src="images/settings/icon_edit.png" alt="" />
                            </div>
                            <div className="project-type-details">
                                <div className="project-manager-title">
                                    <h6>Project Name :- <span>{state_project.selectedprojectdetails.name}</span> </h6>
                                    <h6>Project Key :- <span>{state_project.selectedprojectdetails.key}</span> </h6>
                                </div>


                            </div>
                            <div className="project-manager-section">

                                {state_project.selectedprojectdetails.assignedTo?.length != 0 && <div className="project-manager-title">
                                    <h6>Access</h6>
                                </div>}
                                {(state_project.selectedprojectdetails.assignedTo || []).map(data => (
                                    <div className="manager-row" key={data}>
                                        <p>{data}</p>
                                    </div>
                                ))}
                            </div>

                        </div>

                    </div>} */}
            </div>




        </>

    );
}
export { Accessdetails };