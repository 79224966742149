import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { get_users, get_user_profile } from "../../store/account/api";
import { add_single_task, add_task_success } from "store/task/taskActions"
import { create_task } from "../../store/task/api"
import { uploadfile } from "store/account/api";
import * as FormData from 'form-data';
import { add_single_project, add_project_success, get_project_details_success, view_project_id, get_project_list_success } from "store/projects/projectActions"
import { create_project, get_project_details, get_project_list_pagination } from "../../store/projects/api"
import { Admin, Manager, Task_Full, User } from '../../config';



const Createtask = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const state_account = useSelector(state => state.account);
  const state_project = useSelector(state => state.project);
  const state_task = useSelector(state => state.task);
  const Token = localStorage.getItem("Token")
  const Getdata = localStorage.getItem("Access")
  const [showNotification, setShowNotification] = useState(false);
  const [showNotificationMessage, setShowNotificationMessage] = useState(false);
  const singletaskdata = state_task.singletask;
  let tasklist = state_task.tasklist;
  const [selectmail, setselectmail] = useState("")
  const [selectemail, setselectEmail] = useState("")
  const [mail, setMail] = useState([])
  const [email, setEmail] = useState([])
  const [file, setFile] = useState([])


  useEffect(() => {
    if (Token != null) {
      dispatch(get_user_profile(Token, (res => {
        if (res.success == true) {
          dispatch(get_users(Token))
        }
      })))
    }
  }, []);
  console.log(state_account.users)







  async function createTask() {
    
    let checkstatus = await datavalidation(singletaskdata);
    if (checkstatus == true) {
      Swal.fire({
        title: `Do you want to create the task under '${state_project.singleprojectdetails[0].projectname}'?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire('Confirmed!', '', 'success');
          const payload = {
            projectid: state_project.singleprojectdetails[0].projectid,
            projectname: state_project.singleprojectdetails[0].projectname,
            taskname: singletaskdata.taskname,
            taskdescription: singletaskdata.taskdescription,
            taskdetails: singletaskdata.taskdetails,
            asignedto: email,
            asignedby: state_account.profile.email,
            taskdeadline: singletaskdata.taskdeadline,
            files: singletaskdata.files,
            comments: [],
            status: "Not Started"
          };

          dispatch(create_task(Token, payload, (res) => {
            if (res.success == true) {
              Swal.fire({
                position: 'middle',
                icon: 'success',
                title: 'Your task successfully created in Wrap-up',
                showConfirmButton: false,
                timer: 1500
              });

              dispatch(add_task_success([]));
              const payload = {
                projectid: state_project.singleprojectdetails[0].projectid
              };
              dispatch(get_project_details(payload));
              dispatch(add_project_success(false));
              dispatch(get_project_details_success(true));
              dispatch(view_project_id(state_project.singleprojectdetails[0].projectid));
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire('Changes are not saved', '', 'info');
            }
          }));
          // Ask to update the task
          const newtask = {
            projectid: "",
            projectname: "",
            taskname: "",
            taskdescription: "",
            taskdetails: "",
            taskdeadline: Date,
            asignedto: [],
            asignedby: [],
            files: [],
            comments: [],
            status: ""
          };
          dispatch(add_single_task(newtask));
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire('Changes are not saved', '', 'info');
        }
      });
    } else {
      console.log("Invalid response from API");
    }


  }



  async function datavalidation(singletaskdata) {
    
    let errorFields = [];

    if (singletaskdata.taskname == "") {
      errorFields.push("Task name");

    }
    if (singletaskdata.taskdescription == "") {
      errorFields.push("Task  descripition");

    }

    if (singletaskdata.taskdeadline == "") {
      errorFields.push("Task deadline");
    }

    if (email == "") {
      errorFields.push("Select employee");
    }


    if (errorFields.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${errorFields.join(", ")} field(s) cannot be empty!`,

      })
      return false
    } else {
      return true
    }


  }




  async function handleChangetask(f) {
    if (f.target.name == "taskname") {
      singletaskdata.taskname = (f.target.value)
      dispatch(add_single_task(singletaskdata))
    }
    else if (f.target.name == "taskdescription") {
      singletaskdata.taskdescription = (f.target.value)
      dispatch(add_single_task(singletaskdata))
    }
    else if (f.target.name == "taskdeadline") {
      singletaskdata.taskdeadline = (f.target.value)
      dispatch(add_single_task(singletaskdata))
    }
    console.log(singletaskdata)
  };



  async function dropdownselectEmployee(f) {
    setselectEmail(f.target.value)

  };
  async function employeeSelection() {
    if (selectemail != null || selectemail != "") {
      if (email.includes(selectemail)) {
      } else {
        setEmail(current => [...current, selectemail]);

      }
      console.log(email)
    }
  }
  console.log(email)

  async function deleatEmployee(e) {

    const s = email.filter((data, index) => index !== e)
    setEmail(s);
    singletaskdata.asignedto = s
    dispatch(add_single_task(singletaskdata))
    console.log(s);
  }



  async function uploadimage(singlefile) {
    const formData = new FormData();

    formData.append(
      "file",
      singlefile
    );

    dispatch(uploadfile(formData, res => {

      console.log(res);
      let filedata = {
        "url": res.data.fileurl,
        "filename": res.data.filename,
        "uploadedby": state_account.profile.email,
        "uploadeddate": new Date()
      }

      singletaskdata.files.push(filedata)
      dispatch(add_single_task(singletaskdata))
    }
    ))
  }

  async function uploadFile(e) {
    if (e) {
      for (let i = 0; i < e.target.files.length; i++) {
        await uploadimage(e.target.files[i])
      }

    }


  }

  async function addTask() {

    if (Getdata.includes(`${Admin}`) || Getdata.includes(`${Manager}`) || Getdata.includes(`${Task_Full}`)) {
      if (tasklist.length == 0) {
        tasklist.push(state_account.singletask)
        dispatch(add_task_success(tasklist))
      }
    } else {
      Swal.fire({ confirmButtonColor: "#365d5a", title: "You don't have access", type: 'info' })
    }

  }
  async function deleteFile(e) {

    const s = singletaskdata.files.filter((item, index) => index !== e);
    singletaskdata.files = s
    console.log(s);
    dispatch(add_single_task(singletaskdata))
  }




  return (
    <>
      <div>
        <div className="task-heading">
          <p>Tasks</p>
        </div>
        <div className="create-task-box-section-row">
          <div className="add-new">
            <p>Create new task</p>
            <img onClick={() => addTask()} src="images/task manager/active.svg" alt="" />
          </div>
        </div>
      </div>


      {(state_task.tasklist || []).map((data, index) => (
        <> <div className="project-name">
          <p>Task name</p>
          <div className="name-section">
            <input
              type="text"
              placeholder="Write your Task name "
              className="project-form-control"
              name="taskname"
              onChange={(f) => handleChangetask(f)}
            />
          </div>
        </div>
          <div className="project-name">
            <p>Task description</p>
            <div className="name-section">
              <textarea
                type="text"
                placeholder="Write your Task description here "
                name="taskdescription"
                onChange={(f) => handleChangetask(f)}
              ></textarea>
            </div>
          </div>
          <div className="project-name">
            <p>Task due date</p>
            <div className="name-section">
              <input
                input type="date" data-date="" data-date-format="DD MMMM YYYY" name="taskdeadline"
                placeholder="Select your task deadline date"
                className="project-form-control"
                onChange={(f) => handleChangetask(f)}
              />
            </div>
          </div>
          <div>
            <div className="project-name">
              <p>Assign task to</p>
              <div className="name-section">
                <select id="employee"
                  className="project-form-control" name="manufacture_part_number" list="employee" onChange={(f) => dropdownselectEmployee(f)}>
                  <option value="null" >--Select Employee--</option>

                  {(state_account.users || []).map((data, index) => (
                    <option multiple={true} value={data.email} >{data.email}</option>


                  ))}
                </select>

                <div className="newadd">

                  <div className="project-upload-section">
                    <div className="image-upload-box1">
                      <button onClick={() => { employeeSelection(email) }} type="button" className="upload-own-image-button">
                        Invite</button>
                    </div>
                  </div>
                </div>
                {(email || []).map((data, index) => (
                  <div style={{ "marginBottom": "2px", "marginRight": "2px" }} className="badge badge-pill badge-primary">{data} <span style={{ "color": "#ffb0af" }} className="badge badge-light" />
                    <button type="button" className="badge badge-pill badge-primary" onClick={() => deleatEmployee(index)}>X</button>
                  </div>))}


              </div>
            </div>
            <div className="form-group preview">
              {singletaskdata.files.length > 0 &&
                singletaskdata.files.map((item, index) => {
                  return (
                    <div className="image-upload-box1" key={index}>
                      <div className="img-cercle">
                        {/* Check if the file is an image */}
                        {item.url.endsWith(".jpg") || item.url.endsWith(".jpeg") || item.url.endsWith(".png") || item.url.endsWith(".gif") ? (
                          <img src={item.url} alt="" className="main-img" />
                        ) : (
                          // Display file icon for non-image files
                          <div className="file-icon">
                            <i className="fas fa-file-alt"></i>
                          </div>

                        )}
                      </div>
                      <div className="file-name">
                        {/* Get the original file name */}
                        <strong>{decodeURIComponent(item.url.split("/").pop())}</strong>
                      </div>
                      <button type="button" className="upload-own-image-button" onClick={() => deleteFile(index)}>
                        Delete
                      </button>
                    </div>
                  );
                })}
            </div>
            <div className="project-upload-section">
              <div className="image-upload-box1">
                <input
                  type="file" multiple="multiple"
                  disabled={file.length === 1}
                  className="form-control cursor"
                  onChange={(e) => uploadFile(e)}
                />
                <button type="button" className="upload-own-image-button" >Upload
                  document</button>

                <div>
                </div>
              </div>
            </div>
            <div>
              <div>

              </div>
              <div className="project-submit-section border_bottom pb">
                <button onClick={() => createTask()} type="button" className="btn btn-success submit-own-image-button">submit
                </button>
              </div>
            </div>
          </div>
        </>))}



    </>


  );
}
export { Createtask };